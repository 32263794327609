import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { FuseModule } from '@fuse';
import { FuseConfigModule } from '@fuse/services/config';
import { appConfig } from 'app/core/config/app.config';
import { AppComponent } from 'app/app.component';
import { appRoutes } from 'app/app.routing';
import { MipsModule } from './core/mips/mips.module';
import { IconsModule } from './core/icons/icons.module';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { LoginModule } from './login/login.module';
import { LocalStorageService } from 'ngx-store';
import { TitleCasePipe } from '@angular/common';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { userReducer } from './core/store/currentUser/user.reducer';
import { loggedInUserReducer } from './core/store/loggedInUser/loggedInUser.reducer';
import { flagReminderMsgReducer } from './core/store/flagReminderMsg/flagReminderMsg.reducer';
import { timeDateRangeSelectionReducer } from './core/store/timeDateRangeSelection/timeDateRangeSelection.reducer';
import { expenseDateRangeSelectionReducer } from './core/store/expenseDateRangeSelection/expenseDateRangeSelection.reducer';
import { timeDicerDateRangeSelectionReducer } from './core/store/timeDicerDateRangeSelection/timeDicerDateRangeSelection.reducer';
import { clearStoreData, localStorageSyncReducer } from './core/store/store-meta.reducer';
import { timeEntryGridConfigReducer } from './core/store/timeEntryGridConfig/timeEntryGridConfig.reducer';
import { MipsRouteGuard } from './core/mips/common/utilities/mips.routeguard';
import { PagesRouteGuard } from './core/mips/common/utilities/pages.routeguard';
import { expenseEntryGridConfigReducer } from './core/store/expenseEntryGridConfig/expenseEntryGridConfig.reducer';
import { HashLocationStrategy, LocationStrategy } from '@angular/common';
import { PagesModule } from './pages/pages.module';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
import { ProjectDetailsModule } from './core/mips/project-details/project-details.module';
import { SessionStorageService } from './core/mips/common/services/session-storage.service';
import { ImmigrationDetailsReducer } from './core/store/immigrationDetails/immigrationDetails.reducer';
import { projectDetailsPONumberReducer } from './core/store/projectDetailsPONumber/projectDetailsPONumber.reducer';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { projectDetailsInvoiceDetailsReducer } from './core/store/projectDetailsInvoiceDetails/projectDetailsInvoiceDetails.reducer';
import { FinanceReducer } from './core/store/isFinanceStore/finance.reducer';

@NgModule({
    declarations: [AppComponent],
    imports: [
        BrowserModule,
        HttpClientModule,
        BrowserAnimationsModule,
        RouterModule.forRoot(appRoutes, { useHash: true }),
        StoreModule.forRoot(
            {
                currentUser: userReducer,
                loggedInUser: loggedInUserReducer,
                flagReminderMsg: flagReminderMsgReducer,
                timeDateRange: timeDateRangeSelectionReducer,
                expenseDateRange: expenseDateRangeSelectionReducer,
                timeDicerDateRangeSelection: timeDicerDateRangeSelectionReducer,
                timeDateRangeSelection: timeDateRangeSelectionReducer,
                expenseDateRangeSelection: expenseDateRangeSelectionReducer,
                timeEntryGridConfig: timeEntryGridConfigReducer,
                expenseEntryGridConfig: expenseEntryGridConfigReducer,
                immigrationDetails: ImmigrationDetailsReducer,
                projectDetailsPONumber: projectDetailsPONumberReducer,
                projectDetailsInvoiceDetails: projectDetailsInvoiceDetailsReducer,
                finance: FinanceReducer
            },
            { metaReducers: [localStorageSyncReducer, clearStoreData] }
        ),
        StoreDevtoolsModule.instrument(),
        FuseModule,
        FuseConfigModule.forRoot(appConfig),
        MatSnackBarModule,
        LoginModule,
        PagesModule,
        MipsModule,
        IconsModule,
        ReactiveFormsModule,
        NgSelectModule,
        FormsModule,
        ProjectDetailsModule,
        NgMultiSelectDropDownModule.forRoot(),
        NgSelectModule
    ],
    providers: [
        MatSnackBarModule,
        LocalStorageService,
        SessionStorageService,
        TitleCasePipe,
        PagesRouteGuard,
        MipsRouteGuard,
        { provide: LocationStrategy, useClass: HashLocationStrategy },
    ],
    bootstrap: [AppComponent],
})
export class AppModule {}
