<!-- finance-expense.component.html -->
<div class="flex flex-col gap-2.5 h-full">
  <div class="flex flex-wrap tb:flex-nowrap tb:flex-row gap-4 w-full px-2 py-2 tb:items-center bg-neutral-100">
    <!-- Currency Filter -->
    <mat-form-field class="w-full tb:w-1/4">
      <mat-label>Currency</mat-label>
      <mat-select placeholder = "Select currency" [(ngModel)]="selectedCurrency" multiple >
        <mat-select-trigger *ngIf="selectedCurrency?.length">
          {{selectedCurrency.length}} currency(ies) selected
        </mat-select-trigger>
        <mat-option  style=" background-color: white;"*ngFor="let currency of currencies" [value]="currency">
          {{currency.currency_code}} ({{currency.currency_symbol}})
        </mat-option>
      </mat-select>
    </mat-form-field>


    <mat-form-field class="w-full tb:w-1/4">
      <mat-label>Consultant</mat-label>
      <mat-select placeholder = "Select consultants" [(ngModel)]="selectedConsultant" multiple>
        <mat-select-trigger *ngIf="selectedConsultant?.length">
          {{selectedConsultant.length}} consultant(s) selected
        </mat-select-trigger>
        <mat-option style=" background-color: white;"*ngFor="let consultant of consultantList" [value]="consultant">
          {{consultant.FirstName}} {{consultant.LastName}} ({{consultant.Employee}})
        </mat-option>
      </mat-select>
    </mat-form-field>

    <!-- Date Range -->
    <mat-form-field class="w-full tb:w-1/4">
      <mat-label>Date Range</mat-label>
      <mat-date-range-input [formGroup]="dateRange" [rangePicker]="picker">
        <input matStartDate formControlName="start" placeholder="Start date">
        <input matEndDate formControlName="end" placeholder="End date">
      </mat-date-range-input>
      <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
      <mat-date-range-picker #picker></mat-date-range-picker>
    </mat-form-field>

    <!-- Action Buttons -->
    <div class="flex gap-2 items-center">
      <button mat-stroked-button
              color="warn"
              (click)="clearFilters()"
              [disabled]="isLoading"
              class="h-10">
        Clear
      </button>

      <button mat-raised-button
              color="primary"
              (click)="applyFilters()"
              [disabled]="isLoading"
              class="h-10 bg-blue-600 text-white">
        <mat-spinner *ngIf="isLoading" diameter="20" class="mr-2 inline"></mat-spinner>
        Apply
      </button>
   
    </div>
  </div>

  <!-- Grid section remains the same -->
  <div class="flex flex-row entry-table h-full">
    <ag-grid-angular
      style="max-width: 2256px; width: 100%;"
      class="ag-theme-balham w-full"
      [gridOptions]="gridOptions"
      [rowData]="rowData"
      [animateRows]="true"
      [rowSelection]="'multiple'"
      [suppressRowClickSelection]="true"
      [defaultColDef]="defaultColDef"
      [suppressDragLeaveHidesColumns]="true"
      (gridReady)="onGridReady($event)"
      (cellValueChanged)="handleCellValueChange($event)">
    </ag-grid-angular>
  </div>

  <div class="flex justify-end p-0 bg-neutral-100">
    <button mat-raised-button
            color="warn"
            (click)="saveModifiedEntries()"
            [disabled]="!hasModifiedEntries || isLoading"
            [ngClass]="{
              'cursor-not-allowed opacity-50': !hasModifiedEntries || isLoading,
              'bg-gray-300 text-gray-500': !hasModifiedEntries,
              'bg-green-600 text-white': hasModifiedEntries && !isLoading
            }"
            class="h-10">
      <mat-spinner *ngIf="isLoading" diameter="20" class="mr-2 inline"></mat-spinner>
      Save Entries
    </button>
  </div>
</div>
