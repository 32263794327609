import { Injectable} from '@angular/core';
import { LocalStorageService } from '../common/services/local-storage.service';
import { commonFunctions } from '../common/classes/common.class';
import { CommonService } from '../common/services/common.service';
import { ProjectDetailsBlueprint } from './project-details-blueprint';
import { BehaviorSubject, catchError, firstValueFrom, Observable, throwError } from 'rxjs';
import { ConstantFormStates, Constants } from '../common/utilities/constants.config';
import { ConsultantDetailsService } from '../common/services/consultant-details.service';
import { ConsultantModel } from '../common/models/consultant.model';
import { ProjectDetailsModel } from '../common/models/project-details.model';
import { HttpClient, HttpParams } from '@angular/common/http';
import { URLPaths } from '../common/utilities/urlPaths.config';
import { ApiheaderService } from '../common/services/apiheader.service';
import { Store } from '@ngrx/store';
import { getCurrentUser } from 'app/core/store/currentUser/user.selectors';
import { SnackbarService } from '../common/services/snackbar.service';
import { ContactModel } from '../common/models/contact.model';
import { AddProjectTaskRequestParamModel } from '../common/models/project-task.model';
import { ThemeAllocationModel } from '../common/models/theme-allocation.model';
import { GridApi } from 'ag-grid-community';
import moment from 'moment';
import { GlobalConstants } from '../common/utilities/global-constants';
import { FormGroup } from '@angular/forms';
import { setprojectDetailsPONumber } from 'app/core/store/projectDetailsPONumber/projectDetailsPONumber.action';
import { MatDialog} from '@angular/material/dialog';
import { ThemeAllocationDailogComponent } from './project-details-dialog/theme-allocation-dailog/theme-allocation-dailog.component';
import { setprojectDetailsInvoiceDetails } from 'app/core/store/projectDetailsInvoiceDetails/projectDetailsInvoiceDetails.action';
import { getprojectDetailsInvoiceDetails } from 'app/core/store/projectDetailsInvoiceDetails/projectDetailsInvoiceDetails.selector';
import _ from 'lodash';
import { ProjectInvoiceDetailsModel } from '../common/models/project-invoice-details.model';
import { SessionStorageService } from '../common/services/session-storage.service';

@Injectable({
    providedIn: 'root'
})
export class ProjectDetailsService{
    projectDetailTableRowData: BehaviorSubject<any> = new BehaviorSubject<any>([]);
    projectSelectedBoolean: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
    addProjectOtherFormsDisabled: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
    viewProjectOtherFormsDisabled: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);
    // Project Code Behaviour
    projectCodeFieldValidation: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
    private selectedIndexSource = new BehaviorSubject<number>(0);

    private selectedProjectSubject = new BehaviorSubject<string>(''); // Default value
    private selectedClientSubject = new BehaviorSubject<string>(''); // Default value

    // Expose the observables
    selectedProject$: Observable<string> = this.selectedProjectSubject.asObservable();
    selectedClient$: Observable<string> = this.selectedClientSubject.asObservable();

    selectedIndex$ = this.selectedIndexSource.asObservable();

    generateDialog: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

    clientDetails: [];
    selectedClientCode: string;
    selectedProjectCode: string;
    selectedCountryCode: string;
    selectedCountryName: string;
    projectContactDetails: [];
    projectDetails: [];
    contactDetails: [];
    projectDetailTables: [];
    projectTypes = [];
    fieldToResponsePropertyNameMap: Map<string, string> = new Map<string, string>();
    valuePropInResponse: Map<string, string> = new Map<string, string>();
    selectedRootValue: any;
    fieldsToFilter: string[];
    dialogRef: any;
    rootFieldCode: string;
    enabledFields: Set<string> = new Set<string>();
    dropdownValues: Map<string, any> = new Map<string, any>();
    loginName: string;
    employeeName: string;
    originalRowData: {[key: string]: any} = {};
    selectedRows: any;
    agGrids: {[key: string]: GridApi} = {};
    editProjectDetailsFormMap: Map<string, any> = new Map<string, any>();
    invoiceDetails: any;
    taxRate: any;

    saveButtonEvents: {[key: string]: BehaviorSubject<boolean>} = {};
    deleteButtonEvents: {[key: string]: BehaviorSubject<boolean>} = {};
    viewButtonEvents: {[key: string]: BehaviorSubject<boolean>} = {};

    saveEnableCPR: boolean;
    saveEnablePT: boolean;
    saveEnableTA: boolean;
    saveEnablePD: boolean;

    viewEnableTA: boolean = false;

    deleteEnableCPR: boolean;
    deleteEnablePT: boolean;
    deleteEnableTA: boolean;
    deleteEnablePD: boolean;

    countryForInvoice: string;

    availableContactChosen = false;

    disableDetailsForEdit: boolean;
    disableDetailsForAdd: boolean;
    purchaseOrderList = []

    constructor(
        private _commonService: CommonService,
        private _consultantDetailsService: ConsultantDetailsService,
        private _http: HttpClient,
        private _store: Store,
        private _snackBarService: SnackbarService,
        private _dialog: MatDialog
    ) {
        this.fieldToResponsePropertyNameMap.set('PR', 'project_details');
        this.fieldToResponsePropertyNameMap.set('AVAIL-CONT', 'contact_details');
        this.valuePropInResponse.set('PR', 'project');
        this.valuePropInResponse.set('AVAIL-CONT', 'contact');
        this._store.select(getCurrentUser).subscribe(data => {
            this.loginName = data.LoginName;
            this.employeeName = data.Employee;
        })

        this.projectSelectedBoolean.subscribe((data) => {
            this.disableDetailsForEdit = data;
          });

        this.addProjectOtherFormsDisabled.subscribe((data) => {
            this.disableDetailsForAdd = data;
        })

          this.viewbuttonsEnable(Constants.THEME_ALLOCATION_FORM).subscribe((enabled: any) => {
            this.viewEnableTA = enabled;
          });

          this.onButtonEnable(Constants.CONSUTANT_PROJECT_RATES_FORM).subscribe((enabled: any) => {
            this.saveEnableCPR = enabled;
          });
          this.onButtonEnable(Constants.PROJECT_TASKS_FORM).subscribe((enabled: any) => {
            this.saveEnablePT = enabled;
          });
          this.onButtonEnable(Constants.THEME_ALLOCATION_FORM).subscribe((enabled: any) => {
            this.saveEnableTA = enabled;
          });
          this.onButtonEnable(Constants.PREPAYMENT_DETAILS_FORM).subscribe((enabled: any) => {
            this.saveEnablePD = enabled;
          });

          this.onDeleteButtonEnable(Constants.CONSUTANT_PROJECT_RATES_FORM).subscribe((enabled: any) => {
            this.deleteEnableCPR = enabled;
          });
          this.onDeleteButtonEnable(Constants.PROJECT_TASKS_FORM).subscribe((enabled: any) => {
            this.deleteEnablePT = enabled;
          });
          this.onDeleteButtonEnable(Constants.THEME_ALLOCATION_FORM).subscribe((enabled: any) => {
            this.deleteEnableTA = enabled;
          });
          this.onDeleteButtonEnable(Constants.PREPAYMENT_DETAILS_FORM).subscribe((enabled: any) => {
            this.deleteEnablePD = enabled;
          });
          this._store.select(getprojectDetailsInvoiceDetails).subscribe((data: any) => {
            this.invoiceDetails = data;
          })
    }

    async initializeProjectDetailsBlueprint() {
        const salesTaxDetailsOfLocations = await this.getSalesTaxDetails()
        for (const salesTaxDetails of salesTaxDetailsOfLocations) {
            const country = salesTaxDetails.country;
            const state = salesTaxDetails.state;
            const taxPercentage = salesTaxDetails.salesTax * 100;
            ProjectDetailsBlueprint.countryToCurrencyMap[country] = salesTaxDetails.currencyName;
            ProjectDetailsBlueprint.countryToTaxTypeMap[country] = salesTaxDetails.salesTaxName;
            ProjectDetailsBlueprint.setLocationToTaxPercentageMap({ country: country, state: state }, commonFunctions.round(taxPercentage));
        }
    }

    setSelectedProject(project: string): void {
        this.selectedProjectSubject.next(project);
    }

      // Method to update selected client
    setSelectedClient(client: string): void {
        this.selectedClientSubject.next(client);
    }

    getClientCode(client: string) {
        const clientCodeRegExp = new RegExp('([^-\s]+)$');
        return clientCodeRegExp.exec(client)[0].trim();
    }

    getProjectCode(project: string) {
        const projectCodeRegExp = new RegExp('([^-\s]+)$');
        return projectCodeRegExp.exec(project)[0].trim();
    }

    showSuccessOrFailureSnackbar(responseFromBackend) {
        if(responseFromBackend != undefined){
            if (responseFromBackend['Type']=="failure") {
                this._snackBarService.showSnackBar(
                    responseFromBackend['Message'],
                    'close',
                    'failure'
                );
            } else {
                this._snackBarService.showSnackBar(
                    responseFromBackend['Message'],
                    '',
                    'success'
                );
            }
        }

    }

    setSelectedIndex(index: number) {
        this.selectedIndexSource.next(index);
      }

    //Function to get icons (formLayout)
    getIcon(buttonName){
        switch (buttonName) {
            case Constants.SAVE_BUTTON_NAME:
              return 'save'
            case Constants.SUBMIT_BUTTON_NAME:
              return 'add';
            case Constants.CLEAR_BUTTON_NAME:
              return 'clear'
            case Constants.DELETE_BUTTON_NAME:
              return 'delete';
            case Constants.NEXT_BUTTON_NAME:
              return 'skip_next'
            case Constants.VIEW_BUTTON_NAME:
              return 'remove_red_eye'
            default:
              return '';
          }
    }

    //Function to check which button to disabled and when (formLayout) [false == enabled, true == disabled]
    submitHandler(buttonName, buttonType, formValidity, formData){
        if(buttonName == Constants.VIEW_BUTTON_NAME){  //View is always enabled
            return false;
        }
        if(buttonName == Constants.NEXT_BUTTON_NAME){ //Next only enabled when we click submit/save on first page
                                                      //So when we enable the tabs it should get enabled
            if(buttonType == Constants.PROJECT_DETAILS_EDIT_MODE && this.disableDetailsForEdit)
                return true;
            return false;
        }
        if(buttonName == Constants.CLEAR_BUTTON_NAME){ //Clear same as next (merge these conditions if needed)
            if(buttonType == Constants.PROJECT_DETAILS_EDIT_MODE && this.disableDetailsForEdit){
                return true;
            }
            return false;
        }

        //Submit must be enabled when form is validated.
        if(buttonName == Constants.SUBMIT_BUTTON_NAME){
            if(formValidity && formData.contactMode != 'No Selection'){ //ContactMode condition added so as to give contact priority as well
                return false;
              }
        }

        // SAVE and DELETE buttons are based on ag-grid except for the edit mode first page
        if(buttonName == Constants.DELETE_BUTTON_NAME){
            if(buttonType == Constants.PROJECT_DETAILS_EDIT_MODE && !this.disableDetailsForEdit) //Edit mode 1st page condition
                return false;
            //All others are based on the subscription updated in project-details-component.ts file
            if(buttonType == Constants.CONSUTANT_PROJECT_RATES_FORM && this.deleteEnableCPR)
              return false;
            if(buttonType == Constants.PROJECT_TASKS_FORM && this.deleteEnablePT)
              return false;
            if(buttonType == Constants.THEME_ALLOCATION_FORM && this.deleteEnableTA)
              return false;
            if(buttonType == Constants.PREPAYMENT_DETAILS_FORM && this.deleteEnablePD)
              return false;
        }
        if(buttonName == Constants.SAVE_BUTTON_NAME){
            if(buttonType == Constants.PROJECT_DETAILS_EDIT_MODE && !this.disableDetailsForEdit && formValidity)
              return false;

            if(buttonType == Constants.CONSUTANT_PROJECT_RATES_FORM && this.saveEnableCPR){
              return false;
            }
            if(buttonType == Constants.PROJECT_TASKS_FORM && this.saveEnablePT)
              return false;
            if(buttonType == Constants.THEME_ALLOCATION_FORM && this.saveEnableTA)
              return false;
            if(buttonType == Constants.PREPAYMENT_DETAILS_FORM && this.saveEnablePD)
              return false;
        }
        return true;
    }

    viewbuttonsEnable(buttonType: string){
        if(!this.viewButtonEvents[buttonType]){
            this.viewButtonEvents[buttonType] = new BehaviorSubject<boolean>(true);
        }
        return this.viewButtonEvents[buttonType].asObservable();
    }

    viewbuttonsDisable(buttonType: string){
        if(!this.viewButtonEvents[buttonType]){
            this.viewButtonEvents[buttonType] = new BehaviorSubject<boolean>(false);
        }
        this.viewButtonEvents[buttonType].next(false)
    }

    savebuttonsEnable(buttonType: string){
        if(!this.saveButtonEvents[buttonType]){
            this.saveButtonEvents[buttonType] = new BehaviorSubject<boolean>(true);
        }
        this.saveButtonEvents[buttonType].next(true)
    }

    onButtonEnable(buttonType: string){
        if(!this.saveButtonEvents[buttonType]){
            this.saveButtonEvents[buttonType] = new BehaviorSubject<boolean>(false);
        }
        return this.saveButtonEvents[buttonType].asObservable();
    }

    savebuttonsDisable(buttonType: string){
        if(!this.saveButtonEvents[buttonType]){
            this.saveButtonEvents[buttonType] = new BehaviorSubject<boolean>(false);
        }
        this.saveButtonEvents[buttonType].next(false)
    }

    deletebuttonsEnable(buttonType: string){
        if(!this.deleteButtonEvents[buttonType]){
            this.deleteButtonEvents[buttonType] = new BehaviorSubject<boolean>(true);
        }
        this.deleteButtonEvents[buttonType].next(true)
    }

    onDeleteButtonEnable(buttonType: string){
        if(!this.deleteButtonEvents[buttonType]){
            this.deleteButtonEvents[buttonType] = new BehaviorSubject<boolean>(false);
        }
        return this.deleteButtonEvents[buttonType].asObservable();
    }

    deletebuttonsDisable(buttonType: string){
        if(!this.deleteButtonEvents[buttonType]){
            this.deleteButtonEvents[buttonType] = new BehaviorSubject<boolean>(false);
        }
        this.deleteButtonEvents[buttonType].next(false)
    }

    //Checking if button is clear or not
    buttonSelector(button){
        if(button == 'clearProjectDetails')
            return true;
        return false;
    }

    //Getting the metadata to clear the fields and reset the fields
    clearProjectDetails(fieldDetails) {
        let fieldsToReset: string[] = [];
        for (let index in fieldDetails) {
            const candidateFieldCode = fieldDetails[index].fieldCode
            fieldsToReset.push(candidateFieldCode)
        }
        return fieldsToReset;
    }

    async setProjectContact(client) {

        let params = new HttpParams();
        params = params.append('client', client);
        let projectsAndContacts = await firstValueFrom(this._http.get<any>(URLPaths.URL_PROJECT_AND_CONTACT_DETAILS, {headers: ApiheaderService.getHeaders(), params: params}));

        SessionStorageService.setSessionStorageValueBasedOnKey('projects-contacts_' + client, JSON.stringify(projectsAndContacts));
    }

    //On add mode Submit, first it will check whether the new code is current or not, if it is new then we call submit or we just updat the details
    async callAddOrUpdate(formData){
        const currProjectCode = formData.projectCode.label;
        const projectCodeList = this.getProjectCodeshere(formData.client);
        let isPresent = (await (projectCodeList)).includes(currProjectCode)
        let formDataCopy = formData
        formDataCopy.projectCode = formData.projectCode.label;
        formData = formDataCopy
        if(isPresent){
            this.updateProject(formData);
        }
        else{
            this.addProject(formData);
        }
    }

    async addProject(formData): Promise<string> {
        let contactResponse: any;
        const projectDetails: ProjectDetailsModel = {
            client: this.getClientCode(formData.client),
            code: formData.projectCode,
            country: formData.country,
            description: formData.projectDescription,
            contact: formData.availableContacts,
            manager: formData.projectManager,
            oldCode: formData.oldCode,
            loginName: this.loginName,
            isInternal: formData.internal,
            company: formData.billingCompany,
            paymentDays: parseInt(formData.paymentDays),
            projectType: formData.projectType,
            isSensitive: true, // ???????????????????????????????????
            oldProject: formData.projectCode
        };

        const projectDetailsRequestObj = {
            client: projectDetails.client,
            projectCode: projectDetails.code,
            description: projectDetails.description,
            contact: projectDetails.contact,
            manager: projectDetails.manager,
            discount: null,
            discountType: null,
            invoiceDesc: null,
            mileage: null,
            purchaseOrderNumber: null,
            rate: null,
            showHourBreakdown: null,
            vatRate: null,
            charge: false,
            oldCode: commonFunctions.booleanToInteger(projectDetails.oldCode),
            promptPaymentPeriodDuration: null,
            promptPaymentPeriodDiscount: null,
            promptPaymentPeriodType: null,
            loginName: projectDetails.loginName,
            internal: projectDetails.isInternal,
            noVat: null,
            clientVatNumber: null,
            companyId: await this._commonService.getCompanyId(projectDetails.company),
            invoiceFormat: null,
            invoiceContact: null,
            invoiceAddress: null,
            freeFormatInvoiceDescription: null,
            paymentDays: projectDetails.paymentDays,
            projectTypeId: await this._commonService.getProjectTypeId(projectDetails.projectType),
            sensitive: commonFunctions.booleanToInteger(projectDetails.isSensitive),
            cnpj: null,
            typeOfService: null,
            oldProject: projectDetails.oldProject
        };

        //*IMP to set the currency when we add consultant project rates
        this.selectedClientCode = projectDetails.client;
        this.selectedProjectCode = projectDetails.code;
        this.selectedCountryCode = projectDetails.country;

        // Adding payment days to the store as it is not updating during the setting of fields
        let projectFieldValues = this.invoiceDetails.projectFieldValues;
        projectFieldValues.set("PD", projectDetails.paymentDays);
        projectFieldValues.set("CONT-NAME", formData.contact);
        projectFieldValues.set("ADD", formData.address);
        projectFieldValues.set("TELE", formData.telephone);
        projectFieldValues.set("MIL-RA", formData.mileage);
        projectFieldValues.set("CN", formData.country);
        projectFieldValues.set("ST", formData.state);
        // projectFieldValues.set("CL", formData.client);
        // projectFieldValues.set("PR-CODE", projectDetails.code);
        // projectFieldValues.set("CN", projectDetails.country);
        // projectFieldValues.set("AVAIL-CONT", projectDetails.contact);
        // projectFieldValues.set("PR-DESC", projectDetails.description);
        // projectFieldValues.set("PR-MNG", projectDetails.manager);
        // projectFieldValues.set("OC", projectDetails.oldCode);
        // projectFieldValues.set("INT", projectDetails.isInternal);
        // projectFieldValues.set("BC", projectDetails.company);
        // projectFieldValues.set("PR-TYPE", projectDetails.client);

        this._store.dispatch(setprojectDetailsInvoiceDetails( { projectDetailsInvoiceDetails : {
            selectionFields : this.invoiceDetails.selectionFields,
            fieldsToEnable: this.invoiceDetails.fieldsToEnable,
            fieldsToDisable: this.invoiceDetails.fieldsToDisable,
            fieldsToSet: [],
            fieldsToReset: this.invoiceDetails.fieldsToReset,
            fieldDetails: this.invoiceDetails.fieldDetails,
            fieldValues: this.invoiceDetails.fieldValues,
            projectFieldValues: projectFieldValues
        }
        }))

        if (formData.contactMode === 'ADD-NEW') {
            projectDetailsRequestObj["contact"] = formData.contact;
            contactResponse = await this.addContact(formData);
            return new Promise((resolve, reject) => {
                this._http.post(URLPaths.URL_PROJECT, { 'save_project_details': projectDetailsRequestObj }, { headers: ApiheaderService.getHeaders() })
                    .pipe(
                        catchError(async (response) => {
                            this.showSuccessOrFailureSnackbar(response.error);
                            reject('failure');
                        })
                    )
                    .subscribe((response) => {
                        if (response['Type'] == 'success') {
                            this.setAddProjectOtherFormsDisabled(false);
                            this.showSuccessOrFailureSnackbar(response);
                            this.setProjectContact(formData.client); // set's client's contact also
                            this.getProjectDetailsTables(projectDetailsRequestObj.client, projectDetailsRequestObj.projectCode).then(data => {
                                this.projectDetailTables = data;
                                this.setProjectDetailRows(data);
                            });
                            resolve('success');
                        } else {
                            this.showSuccessOrFailureSnackbar(response);
                            resolve('failure');
                        }
                    });
            });
        } else {
            projectDetailsRequestObj["contact"] = formData.contact;
            await this.updateContact(formData);
            return new Promise((resolve, reject) => {
                this._http.post(URLPaths.URL_PROJECT, { 'save_project_details': projectDetailsRequestObj }, { headers: ApiheaderService.getHeaders() })
                    .pipe(
                        catchError(async (response) => {
                            this.showSuccessOrFailureSnackbar(response.error);
                            reject('failure');
                        })
                    )
                    .subscribe((response) => {
                        if (response['Type'] == 'success') {
                            this.setAddProjectOtherFormsDisabled(false);
                            this.showSuccessOrFailureSnackbar(response);
                            this.setProjectContact(formData.client); // set's client's contact also
                            this.getProjectDetailsTables(projectDetailsRequestObj.client, projectDetailsRequestObj.projectCode).then(data => {
                                this.projectDetailTables = data;
                                this.setProjectDetailRows(data);
                            });
                            resolve('success');
                        } else {
                            this.showSuccessOrFailureSnackbar(response);
                            resolve('failure');
                        }
                    });
            });
        }

    }

    async updateProjectInvoice(formData) : Promise<string> {
        const invoiceFormDetails: ProjectInvoiceDetailsModel= {
            discount: parseInt(formData.projectDiscount),
            discountType: "Percentage",    // ????????????????????
            invoiceDescription: formData.invoiceDescription,
            mileageRate: parseInt(formData.mileageRate),
            purchaseOrderNo : formData.purchaseOrderNoViewMode,
            projectHourlyRate: parseInt(formData.projectHourlyRate),
            showHourlyBreakdown: formData.showHourlyBreakdown,
            vatRate: formData.salesTax ? parseInt(formData.salesTax) : 0,
            charge: formData.charge,
            promptPaymentPeriodDuration: parseInt(formData.promptPaymentPeriod),
            promptPaymentDiscount: parseInt(formData.promptPaymentDiscount),
            promptPaymentPeriodType: formData.promptPaymentPeriodType,
            noVat: commonFunctions.Stringtoboolean(formData.noVat),
            clientVatNo: formData.clientVatNo,
            invoiceFormat: formData.invoiceFormat,
            invoiceContact: formData.invoiceContact,
            invoiceAddress: formData.invoiceContactAddress,
            freeFormatInvoiceDescription: formData.freeFormatDescription,
            cnpj: formData.cnpj,
            typeOfService: formData.typeOfService,
        };

        //Code is object or string based on if it is from add-mode or edit-mode
        let projectCode = this.invoiceDetails.projectFieldValues.get("PR-CODE");
        let code;
        if(typeof projectCode === 'object'){
            code = projectCode.label;
        }
        else{
            code = projectCode;
        }

        const projectDetailsRequestObj = {
            client: this.getClientCode(this.invoiceDetails.projectFieldValues.get("CL")),
            projectCode: code,
            // description: this.invoiceDetails.projectFieldValues.get("PR-DESC"),
            description: formData.projectDescription,
            contact: this.invoiceDetails.projectFieldValues.get("CONT-NAME"),
            manager: this.invoiceDetails.projectFieldValues.get("PR-MNG"),
            discount: invoiceFormDetails.discount,
            discountType: invoiceFormDetails.discountType,
            invoiceDesc: invoiceFormDetails.invoiceDescription,
            mileage: invoiceFormDetails.mileageRate,
            purchaseOrderNumber: invoiceFormDetails.purchaseOrderNo === undefined? '' : invoiceFormDetails.purchaseOrderNo,
            rate: invoiceFormDetails.projectHourlyRate,
            showHourBreakdown: commonFunctions.booleanToString(invoiceFormDetails.showHourlyBreakdown),
            vatRate: invoiceFormDetails.vatRate,
            charge: commonFunctions.booleanToString(invoiceFormDetails.charge),
            oldCode: commonFunctions.booleanToInteger(commonFunctions.Stringtoboolean(this.invoiceDetails.projectFieldValues.get("OC"))),
            promptPaymentPeriodDuration: invoiceFormDetails.promptPaymentPeriodDuration,
            promptPaymentPeriodDiscount: invoiceFormDetails.promptPaymentDiscount,
            promptPaymentPeriodType: await this._commonService.getPromptPaymentPeriodTypeId(invoiceFormDetails.promptPaymentPeriodType),
            loginName: this.loginName,
            internal: commonFunctions.booleanToInteger(commonFunctions.Stringtoboolean(this.invoiceDetails.projectFieldValues.get("INT"))),
            noVat:  invoiceFormDetails.noVat,
            clientVatNumber: invoiceFormDetails.clientVatNo,
            companyId: await this._commonService.getCompanyId(this.invoiceDetails.projectFieldValues.get("BC")),
            invoiceFormat: await this._commonService.getInvoiceFormatId(invoiceFormDetails.invoiceFormat),
            invoiceContact: invoiceFormDetails.invoiceContact,
            invoiceAddress: invoiceFormDetails.invoiceAddress,
            freeFormatInvoiceDescription: invoiceFormDetails.freeFormatInvoiceDescription,
            paymentDays: this.invoiceDetails.projectFieldValues.get("PD"),
            projectTypeId: await this._commonService.getProjectTypeId(this.invoiceDetails.projectFieldValues.get("PR-TYPE")),
            sensitive: commonFunctions.booleanToInteger(true),
            cnpj: invoiceFormDetails.cnpj,
            typeOfService: invoiceFormDetails.typeOfService,
            oldProject: code,
        };

        //setting fieldvalues in store so as to update when the user comes back to submit the 1st page form in add mode
        let fieldValues: Map<string, any> = new Map<string, any>([
            ['PR-DISC', invoiceFormDetails.discount],
            ['discountType', invoiceFormDetails.discountType],
            ['IN-DESC', invoiceFormDetails.invoiceDescription],
            ['MIL-RA', invoiceFormDetails.mileageRate],
            ['PO-NO-VW', invoiceFormDetails.purchaseOrderNo],
            ['PHR', invoiceFormDetails.projectHourlyRate],
            ['SHB', invoiceFormDetails.showHourlyBreakdown],
            ['TAX', invoiceFormDetails.vatRate],
            ['CHG', invoiceFormDetails.charge],
            ['PPP', invoiceFormDetails.promptPaymentPeriodDuration],
            ['PPD', invoiceFormDetails.promptPaymentDiscount],
            ['PPPT', invoiceFormDetails.promptPaymentPeriodType],
            ['NO-VAT', invoiceFormDetails.noVat],
            ['CL-VAT-NO', invoiceFormDetails.clientVatNo],
            ['IN-FOR', invoiceFormDetails.invoiceFormat],
            ['IN-CONT', invoiceFormDetails.invoiceContact],
            ['IN-CONT-ADD', invoiceFormDetails.invoiceAddress],
            ['FFD', invoiceFormDetails.freeFormatInvoiceDescription],
            ['CNPJ', invoiceFormDetails.cnpj],
            ['TOS', invoiceFormDetails.typeOfService],
        ]);

        this._store.dispatch(setprojectDetailsInvoiceDetails( { projectDetailsInvoiceDetails : {
            selectionFields : this.invoiceDetails.selectionFields,
            fieldsToEnable: this.invoiceDetails.fieldsToEnable,
            fieldsToDisable: this.invoiceDetails.fieldsToDisable,
            fieldsToSet: [],
            fieldsToReset: this.invoiceDetails.fieldsToReset,
            fieldDetails: this.invoiceDetails.fieldDetails,
            fieldValues: fieldValues,
            projectFieldValues: this.invoiceDetails.projectFieldValues
        }
        }))

        return new Promise((resolve, reject) => {
            this._http.put(URLPaths.URL_PROJECT, { 'update_project_details': projectDetailsRequestObj }, { headers: ApiheaderService.getHeaders() })
                .pipe(
                    catchError((response) => {
                        this.showSuccessOrFailureSnackbar(response.error);
                        return throwError(() => new Error('failure')); // Use throwError for proper error handling in observables
                    })
                )
                .subscribe({
                    next: (response) => {
                        if (response['Type'] == 'success') {
                            this.setAddProjectOtherFormsDisabled(false);
                            this.setProjectContact(this.invoiceDetails.projectFieldValues.get("CL")); // updating client info in local storage after updating
                            resolve('success');
                        } else {
                            resolve('failure');
                        }
                        this.showSuccessOrFailureSnackbar(response);
                    },

                    error: (error) => {
                        reject('failure');
                    }
                });
        });
    }

    async updateProject(formData) : Promise<string>  {
        const projectDetails: any = {
            client: this.getClientCode(formData.client),
            code: formData.projectCode,
            country: formData.country,
            description: formData.projectDescription,
            contact: formData.availableContacts,
            manager: formData.projectManager,
            oldCode: formData.oldCode,
            loginName: this.loginName,
            isInternal: formData.internal,
            company: formData.billingCompany,
            paymentDays: parseInt(formData.paymentDays),
            projectType: formData.projectType,
            isSensitive: true, // ???????????????????????????????????
            oldProject: ''
        };

        this.selectedCountryCode = projectDetails.country;

        const projectDetailsRequestObj = {
            client: projectDetails.client,
            projectCode: projectDetails.code,
            description: projectDetails.description,
            contact: projectDetails.contact,
            manager: projectDetails.manager,
            discount: parseInt(this.invoiceDetails.fieldValues.get("PR-DISC")),
            discountType: "Percentage",
            invoiceDesc: this.invoiceDetails.fieldValues.get("IN-DESC"),
            mileage: parseInt(this.invoiceDetails.fieldValues.get("MIL-RA")),
            purchaseOrderNumber: this.invoiceDetails.fieldValues.get("PO-NO-VW")===undefined ? '': this.invoiceDetails.fieldValues.get("PO-NO-VW"),
            rate: parseInt(this.invoiceDetails.fieldValues.get("PHR")),
            showHourBreakdown: commonFunctions.booleanToString(this.invoiceDetails.fieldValues.get("SHB")),
            vatRate: this.invoiceDetails.fieldValues.get("TAX") ? parseInt(this.invoiceDetails.fieldValues.get("TAX")) :0,
            charge: commonFunctions.booleanToString(this.invoiceDetails.fieldValues.get("CHG")),
            oldCode: commonFunctions.booleanToInteger(commonFunctions.Stringtoboolean(projectDetails.oldCode)),
            promptPaymentPeriodDuration: parseInt(this.invoiceDetails.fieldValues.get("PPP")),
            promptPaymentPeriodDiscount: parseInt(this.invoiceDetails.fieldValues.get("PPD")),
            promptPaymentPeriodType: await this._commonService.getPromptPaymentPeriodTypeId(this.invoiceDetails.fieldValues.get("PPPT")),
            loginName: projectDetails.loginName,
            internal: commonFunctions.booleanToInteger(commonFunctions.Stringtoboolean(projectDetails.isInternal)),
            noVat: commonFunctions.Stringtoboolean(this.invoiceDetails.fieldValues.get("NO-VAT")),
            clientVatNumber: this.invoiceDetails.fieldValues.get("CL-VAT-NO"),
            companyId: await this._commonService.getCompanyId(projectDetails.company),
            invoiceFormat: await this._commonService.getInvoiceFormatId(this.invoiceDetails.fieldValues.get("IN-FOR")),
            invoiceContact: this.invoiceDetails.fieldValues.get("IN-CONT"),
            invoiceAddress: this.invoiceDetails.fieldValues.get("IN-CONT-ADD"),
            freeFormatInvoiceDescription: this.invoiceDetails.fieldValues.get("FFD"),
            paymentDays: parseInt(projectDetails.paymentDays),
            projectTypeId: await this._commonService.getProjectTypeId(projectDetails.projectType),
            sensitive: commonFunctions.booleanToInteger(projectDetails.isSensitive),
            cnpj: this.invoiceDetails.fieldValues.get("CNPJ"),
            typeOfService: this.invoiceDetails.fieldValues.get("TOS"),
            oldProject: projectDetails.code
        };

        //Same explaination as addproject
        let projectFieldValues = this.invoiceDetails.projectFieldValues;
        projectFieldValues.set("PD", projectDetails.paymentDays);

        this._store.dispatch(setprojectDetailsInvoiceDetails( { projectDetailsInvoiceDetails : {
            selectionFields : this.invoiceDetails.selectionFields,
            fieldsToEnable: this.invoiceDetails.fieldsToEnable,
            fieldsToDisable: this.invoiceDetails.fieldsToDisable,
            fieldsToSet: this.invoiceDetails.feildsToSet,
            fieldsToReset: this.invoiceDetails.fieldsToReset,
            fieldDetails: this.invoiceDetails.fieldDetails,
            fieldValues: this.invoiceDetails.fieldValues,
            projectFieldValues: projectFieldValues
        }
        }))

        if (formData.contactMode === 'ADD-NEW') {
            projectDetailsRequestObj["contact"] = formData.contact;
            await this.addContact(formData);
            return new Promise((resolve, reject) => {
                this._http.put(URLPaths.URL_PROJECT, { 'update_project_details': projectDetailsRequestObj }, { headers: ApiheaderService.getHeaders() })
                    .pipe(
                        catchError((response) => {
                            this.showSuccessOrFailureSnackbar(response.error);
                            return throwError(() => new Error('failure')); // Use throwError for proper error handling in observables
                        })
                    )
                    .subscribe({
                        next: (response) => {
                            if (response['Type'] == 'success') {
                                this.setAddProjectOtherFormsDisabled(false);
                                this.setProjectContact(formData.client); // updating client info in local storage after updating
                                resolve('success');
                            } else {
                                resolve('failure');
                            }
                            this.showSuccessOrFailureSnackbar(response);
                        },
                        error: (error) => {
                            reject('failure');
                        }
                    });
            });
        } else {
            projectDetailsRequestObj["contact"] = formData.contact;
            await this.updateContact(formData);
            return new Promise((resolve, reject) => {
                this._http.put(URLPaths.URL_PROJECT, { 'update_project_details': projectDetailsRequestObj }, { headers: ApiheaderService.getHeaders() })
                    .pipe(
                        catchError((response) => {
                            this.showSuccessOrFailureSnackbar(response.error);
                            return throwError(() => new Error('failure')); // Use throwError for proper error handling in observables
                        })
                    )
                    .subscribe({
                        next: (response) => {
                            if (response['Type'] == 'success') {
                                this.setAddProjectOtherFormsDisabled(false);
                                this.setProjectContact(formData.client); // updating client info in local storage after updating
                                resolve('success');
                            } else {
                                resolve('failure');
                            }
                            this.showSuccessOrFailureSnackbar(response);
                        },
                        error: (error) => {
                            reject('failure');
                        }
                    });
            });
        }

    }


    async addContact(formData) {
        const contactDetails: ContactModel = {
            contact: formData.contact,
            client: this.getClientCode(formData.client),
            address: formData.address,
            telephone: formData.telephone,
            mileage: parseInt(formData.mileageRate),
            state: formData.state,
            country: formData.country,
        };
        const contactDetailsRequestObj = {
            contact: contactDetails.contact,
            client: contactDetails.client,
            address: contactDetails.address,
            telephone: contactDetails.telephone,
            mileage: contactDetails.mileage,
            stateId: await this._commonService.getStateId(contactDetails.country, contactDetails.state),
            countryId: await this._commonService.getCountryId(contactDetails.country),
        };

        this._http.post(URLPaths.URL_CONTACT_DETAILS, { 'save_contact_details': contactDetailsRequestObj }, { headers: ApiheaderService.getHeaders() })
            .pipe(
                catchError(async (response) => this.showSuccessOrFailureSnackbar(response.error))
            )
            .subscribe((response) => {
                this.showSuccessOrFailureSnackbar(response);
                return response;
            });
    }

    async updateContact(formData) {
        const contactDetails: ContactModel = {
            contact: formData.contact,
            client: this.getClientCode(formData.client),
            address: formData.address,
            telephone: formData.telephone,
            mileage: parseInt(formData.mileageRate),
            state: formData.state,
            country: formData.country,
        };
        const contactDetailsRequestObj = {
            contact: contactDetails.contact,
            client: contactDetails.client,
            address: contactDetails.address,
            telephone: contactDetails.telephone,
            mileage: contactDetails.mileage,
            stateId: await this._commonService.getStateId(contactDetails.country, contactDetails.state),
            countryId: await this._commonService.getCountryId(contactDetails.country),
        };

        this._http.put(URLPaths.URL_CONTACT_DETAILS, { 'update_contact_details': contactDetailsRequestObj }, { headers: ApiheaderService.getHeaders() })
            .pipe(
                catchError(async (response) => this.showSuccessOrFailureSnackbar(response.error))
            )
            .subscribe((response) => {
                // this.showSuccessOrFailureSnackbar(response);
                return response;
            });
    }

    async updateConsultantProjectRates(): Promise<string> {
        let requestbody = [];

        let editedData = [];
        this.agGrids['consultantProjectRates'].forEachNode(node => {
            if(node.data.isModified){
                editedData.push(node.data);
            }
        });
        for (let index in editedData){
            let monthRowKey = `${editedData[index].Consultant}_${editedData[index].Month}_Month`
            let consultantProjectRate: any = {
                Client: this.selectedClientCode,
                Project: this.selectedProjectCode,
                employee: editedData[index].Consultant,
                OrigConsultant: editedData[index].Consultant,
                rate: parseInt(editedData[index].Rate),
                period_Month: editedData[index].Month,
                period_Key: await this._commonService.getPeriodMonthId(editedData[index].Month),
                origMonth: this.originalRowData[monthRowKey] ? this.originalRowData[monthRowKey] : editedData[index].Month,
                Leverage_Percent: parseInt(editedData[index].Leverage),
                editedBy: this.employeeName
            }
            if(consultantProjectRate.rate < 0){
                let message= {
                    "Type": "failure",
                    "Message": "Rate must be greater than or equal to zero!",
                    "Title": "Consultant Project Rate Updation."
                }

                this.showSuccessOrFailureSnackbar(message);
                return new Promise((resolve, reject) => {
                    resolve('Bad Request');
                })
            }
            requestbody.push(consultantProjectRate);
        }
        return new Promise((resolve, reject) => {
            this._http.put(URLPaths.URL_CONSULTANT_PROJECT_RATES, { 'update_consultant_project_rate': requestbody }, { headers: ApiheaderService.getHeaders() })
                .pipe(
                    catchError(async (response) => {
                        this.showSuccessOrFailureSnackbar(response.error);
                        resolve('failure');
                    })
                )
                .subscribe((response) => {
                    this.getProjectDetailsTables(requestbody[0].Client, requestbody[0].Project).then(data => {
                        this.projectDetailTables = data;
                        this.originalRowData.length = 0;
                        this.setProjectDetailRows(data);
                    });
                    this.showSuccessOrFailureSnackbar(response);
                    if (response['Type'] === 'success') {
                        resolve('success');
                    } else {
                        resolve('failure');
                    }
                });
        });
    }

    async deleteConsultantProjectRates() {
        // to be tested once frontend is completed
        let selectedRows = this.agGrids['consultantProjectRates'].getSelectedRows();
        let requestbody = [];
        for (let index in selectedRows){
            let consultantProjectRate: any = {
                client: this.selectedClientCode,
                project: this.selectedProjectCode,
                consultant: selectedRows[index].Consultant,
                rate: parseInt(selectedRows[index].Rate),
                periodMonth: selectedRows[index].Month,
            }
            requestbody.push(consultantProjectRate);
        }

        return new Promise((resolve, reject) => {
            this._http.delete(URLPaths.URL_CONSULTANT_PROJECT_RATES, { body: {'delete_consultant_project_rate': requestbody }, headers: ApiheaderService.getHeaders() })
                .pipe(
                    catchError(async (response) => {
                        this.showSuccessOrFailureSnackbar(response.error);
                        resolve('failure');
                    })
                )
                .subscribe((response) => {
                    this.getProjectDetailsTables(requestbody[0].client, requestbody[0].project).then(data => {
                        this.projectDetailTables = data;
                        this.setProjectDetailRows(data);
                    });
                    this.showSuccessOrFailureSnackbar(response);
                    if (response['Type'] === 'success') {
                        resolve('success');
                    } else {
                        resolve('failure');
                    }
                });
        });
    }

    async addConsultantProjectRates(formData): Promise<any> {
        // to be tested once frontend is completed
        const consultantProjectRate: any = {
            client: this.selectedClientCode,
            project: this.selectedProjectCode,
            employee: formData.consultant,
            hourlyRate: parseInt(formData.hourlyRate),
            entrydate: formData.month,
            leveragePercentage: parseInt(formData.leverage),
            currencyCode: await this._commonService.getCurrencyCode(this.selectedCountryCode),
        }

        let consultantProjectRateRequestObj = {
            client: consultantProjectRate.client,
            project: consultantProjectRate.project,
            employee: consultantProjectRate.employee,
            rate: consultantProjectRate.hourlyRate,
            periodMonth: consultantProjectRate.entrydate,
            periodKey: await this._commonService.getPeriodMonthId(consultantProjectRate.entrydate),
            leveragePercent: consultantProjectRate.leveragePercentage,
            currencyCode: consultantProjectRate.currencyCode,
            editedBy: this.employeeName
        }

        if(consultantProjectRateRequestObj.rate < 0){
            let message= {
                "Type": "failure",
                "Message": "Rate must be greater than or equal to zero!",
                "Title": "Consultant Project Rate Updation."
            }

            this.showSuccessOrFailureSnackbar(message);
            return new Promise((resolve, reject) => {
                reject('Bad Request');
            })
        }

        return new Promise((resolve, reject) => {
            this._http.post(URLPaths.URL_CONSULTANT_PROJECT_RATES, { 'save_consultant_project_rate': consultantProjectRateRequestObj }, { headers: ApiheaderService.getHeaders() })
                .pipe(
                    catchError(async (response) => {
                        this.showSuccessOrFailureSnackbar(response.error);
                        reject('failure');
                    })
                )
                .subscribe((response) => {
                    this.getProjectDetailsTables(consultantProjectRate.client, consultantProjectRate.project).then(data => {
                        this.projectDetailTables = data;
                        this.setProjectDetailRows(data);
                    });
                    this.showSuccessOrFailureSnackbar(response);
                    if (response['Type'] === 'success') {
                        resolve('success');
                    } else {
                        resolve('failure');
                    }
                });
        });
    }

    async deleteProjectTask() {
        let selectedRows = this.agGrids['projectTasks'].getSelectedRows();
        // to be tested once frontend is completed
        selectedRows = selectedRows.filter(value => value.Default != "YES")
        let requestbody = [];

        for (let index in selectedRows){
            let projectTask: any = {
                client: this.selectedClientCode,
                project: this.selectedProjectCode,
                loginname: this.loginName,
                task_id: selectedRows[index].Task_id
            }
            requestbody.push(projectTask);
        }

        return new Promise((resolve, reject) => {
            this._http.delete(URLPaths.URL_PROJECT_TASKS, { body: {'delete_project_task': requestbody }, headers: ApiheaderService.getHeaders() })
                .pipe(
                    catchError(async (response) => {
                        this.showSuccessOrFailureSnackbar(response.error);
                        resolve('failure');
                    })
                )
                .subscribe((response) => {
                    this.getProjectDetailsTables(requestbody[0].client, requestbody[0].project).then(data => {
                        this.projectDetailTables = data;
                        this.setProjectDetailRows(data);
                    });
                    this.showSuccessOrFailureSnackbar(response);
                    if (response['Type'] === 'success') {
                        resolve('success');
                    } else {
                        resolve('failure');
                    }
                });
        });
    }

    async updateProjectTask(): Promise<any> {
        // to be tested once frontend is completed
        let requestbody = [];

        let editedData = [];
        this.agGrids['projectTasks'].forEachNode(node => {
            if(node.data.isModified){
                editedData.push(node.data);
            }
        });

        for (let index in editedData){
            let projectTask: any = {
                client: this.selectedClientCode,
                project: this.selectedProjectCode,
                taskName: editedData[index].Task_Reference,
                taskDescription: editedData[index].Description,
                fixedCostValue: parseInt(editedData[index].Fixed_Cost_Value),
                fixedCostStart: moment(editedData[index].Fixed_Cost_Start).format('YYYY-MM-DD'),
                fixedCostEnd: moment(editedData[index].Fixed_Cost_End).format('YYYY-MM-DD'),
                manager: editedData[index].Manager,
                isDefault: commonFunctions.Stringtoboolean(editedData[index].Default),
                isActive: commonFunctions.Stringtoboolean(editedData[index].Active),
                loginName: this.loginName,
                roleName: '',    // remove it later when the api removes this parameter
                task_id: editedData[index].Task_id
            }
            if (projectTask.fixedCostStart > projectTask.fixedCostEnd) {

                let message= {
                    "Type": "failure",
                    "Message": "Start date cannot be after end date!",
                    "Title": "Project Task Updation."
                }

                this.showSuccessOrFailureSnackbar(message);
                return new Promise((resolve, reject) => {
                    resolve('Bad Request');
                })
            }
            requestbody.push(projectTask);
        }

        return new Promise((resolve, reject) => {
            this._http.post(URLPaths.URL_PROJECT_TASKS, { 'save_project_task': requestbody }, { headers: ApiheaderService.getHeaders() })
                .pipe(
                    catchError(async (response) => {
                        this.showSuccessOrFailureSnackbar(response.error);
                        resolve('failure');
                    })
                )
                .subscribe((response) => {
                    this.getProjectDetailsTables(requestbody[0].client, requestbody[0].project).then(data => {
                        this.projectDetailTables = data;
                        this.originalRowData.length = 0;
                        this.setProjectDetailRows(data);
                    });
                    this.showSuccessOrFailureSnackbar(response);
                    if (response['Type'] === 'success') {
                        resolve('success');
                    } else {
                        resolve('failure');
                    }
                });
        });
    }

    async addProjectTask(formData): Promise<any> {
        // to be tested once frontend is completed
        let requestbody = [];
        const projectTask: AddProjectTaskRequestParamModel = {
            Client: this.selectedClientCode,
            Project: this.selectedProjectCode,
            Task_name: formData.taskRef,
            Task_description: formData.projectTaskDescription,
            Fixed_cost_value: parseInt(formData.fixedCostValue),
            Fixed_cost_start: formData.fixedCostStart,
            Fixed_cost_end: formData.fixedCostEnd,
            Manager: formData.projectTaskManager,
            IsDefault: formData.isDefault,
            Active: formData.active,
            LoginName: this.loginName,
        }

        const projectTaskRequestObj = {
            client: projectTask.Client,
            project: projectTask.Project,
            taskName: projectTask.Task_name,
            taskDescription: projectTask.Task_description,
            fixedCostValue: projectTask.Fixed_cost_value,
            fixedCostStart: projectTask.Fixed_cost_start,
            fixedCostEnd: projectTask.Fixed_cost_end,
            manager: projectTask.Manager,
            isDefault: projectTask.IsDefault,
            isActive: projectTask.Active,
            loginName: projectTask.LoginName,
            roleName: '',    // remove it later when the api removes this parameter
            task_id: 0
        }

        if (projectTaskRequestObj.fixedCostStart > projectTaskRequestObj.fixedCostEnd) {

            let message= {
                "Type": "failure",
                "Message": "Start date cannot be after end date!",
                "Title": "Project Task Updation."
            }

            this.showSuccessOrFailureSnackbar(message);
            return new Promise((resolve, reject) => {
                resolve('Bad Request');
            })
        }

        requestbody.push(projectTaskRequestObj);

        return new Promise((resolve, reject) => {
            this._http.post(URLPaths.URL_PROJECT_TASKS, { 'save_project_task': requestbody }, { headers: ApiheaderService.getHeaders() })
                .pipe(
                    catchError(async (response) => {
                        this.showSuccessOrFailureSnackbar(response.error);
                        resolve('failure');
                    })
                )
                .subscribe((response) => {
                    this.getProjectDetailsTables(projectTaskRequestObj.client, projectTaskRequestObj.project).then(data => {
                        this.projectDetailTables = data;
                        this.setProjectDetailRows(data);
                    });
                    this.showSuccessOrFailureSnackbar(response);
                    if (response['Type'] === 'success') {
                        resolve('success');
                    } else {
                        resolve('failure');
                    }
                });
        });
    }

    async updateThemeAllocation(): Promise<any> {
        let requestbody = [];

        let editedData = [];
        this.agGrids['themeAllocation'].forEachNode(node => {
            if(node.data.isModified){
                editedData.push(node.data);
            }
        });
        for (let index in editedData){
            let themeRowKey = `${editedData[index].Theme}_${editedData[index].Allocation_Percent}_Theme`
            const themeAllocation: any = {
                client: this.selectedClientCode,
                project: this.selectedProjectCode,
                allocationPercent: parseInt(editedData[index].Allocation_Percent),
                theme: await this._commonService.getProjectThemeCode(editedData[index].Theme),
                originalTheme: this.originalRowData[themeRowKey] ? this.originalRowData[themeRowKey] : editedData[index].Theme
            }
            requestbody.push(themeAllocation);

        }

        return new Promise((resolve, reject) => {
            this._http.put(URLPaths.URL_PROJECT_THEME_ALLOCATION, { 'update_project_theme_allocation': requestbody }, { headers: ApiheaderService.getHeaders() })
                .pipe(
                    catchError(async (response) => {
                        this.showSuccessOrFailureSnackbar(response.error);
                        resolve('failure');
                    })
                )
                .subscribe((response) => {
                    this.getProjectDetailsTables(requestbody[0].client, requestbody[0].project).then(data => {
                        this.projectDetailTables = data;
                        this.originalRowData.length = 0;
                        this.setProjectDetailRows(data);
                    });
                    this.showSuccessOrFailureSnackbar(response);
                    if (response['Type'] === 'success') {
                        resolve('success');
                    } else {
                        resolve('failure');
                    }
                });
        });
    }

    async deleteThemeAllocation() {
        // to be tested once frontend is completed
        let selectedRows = this.agGrids['themeAllocation'].getSelectedRows();
        let requestbody = [];
        for (let index in selectedRows){
            let consultantProjectRate: any = {
                Client: this.selectedClientCode,
                Project: this.selectedProjectCode,
                Theme: selectedRows[index].Theme,
            }
            requestbody.push(consultantProjectRate);
        }

        return new Promise((resolve, reject) => {
            this._http.delete(URLPaths.URL_PROJECT_THEME_ALLOCATION, { body: {'delete_project_theme_allocation': requestbody }, headers: ApiheaderService.getHeaders() })
                .pipe(
                    catchError(async (response) => {
                        this.showSuccessOrFailureSnackbar(response.error);
                        resolve('failure');
                    })
                )
                .subscribe((response) => {
                    this.getProjectDetailsTables(requestbody[0].Client, requestbody[0].Project).then(data => {
                        this.projectDetailTables = data;
                        this.setProjectDetailRows(data);
                    });
                    this.showSuccessOrFailureSnackbar(response);
                    if (response['Type'] === 'success') {
                        resolve('success');
                    } else {
                        resolve('failure');
                    }
                });
        });
    }

    async addThemeAllocation(formData): Promise<any> {
        // to be tested once frontend is completed
        const themeAllocation: ThemeAllocationModel = {
            client: this.selectedClientCode,
            project: this.selectedProjectCode,
            allocationPercentage: parseInt(formData.allocation),
            theme: formData.theme,
        }

        const themeAllocationRequestObj = {
            client: themeAllocation.client,
            project: themeAllocation.project,
            allocationPercent: themeAllocation.allocationPercentage,
            theme: await this._commonService.getProjectThemeCode(themeAllocation.theme),
        }

        return new Promise((resolve, reject) => {
            this._http.post(URLPaths.URL_PROJECT_THEME_ALLOCATION, { 'save_project_theme_allocation': themeAllocationRequestObj }, { headers: ApiheaderService.getHeaders() })
                .pipe(
                    catchError(async (response) => {
                        this.showSuccessOrFailureSnackbar(response.error);
                        reject('failure');
                    })
                )
                .subscribe((response) => {
                    this.getProjectDetailsTables(themeAllocation.client, themeAllocation.project).then(data => {
                        this.projectDetailTables = data;
                        this.setProjectDetailRows(data);
                    });
                    this.showSuccessOrFailureSnackbar(response);
                    if (response['Type'] === 'success') {
                        resolve('success');
                    } else {
                        resolve('failure');
                    }
                });
        });
    }

    //Opens the dialogbox when we click view button (Called from formLayout)
    viewThemeAllocation(rowData, columnDefs, gridOptions, rowSelection, projectDetailsContext, gridType, mode, rowDataPD, columnDefsPD,formlayoutContext){
        // (this.rowData, this.columnDefs, this.gridOptions, this.rowSelection, this.projectDetailsContext, this.gridType, this)
        const dialogRef = this._dialog.open(ThemeAllocationDailogComponent, {
            maxWidth: 'clamp(340px, 85vw, 800px)',
            width: '100%',
            height: '65%',
            autoFocus: false,
            hasBackdrop: true,
            data:{
                themeAllocationRowData: rowData,
                themeAllocationColumnDefs: columnDefs,
                gridOptions: gridOptions,
                rowSelection: rowSelection,
                projectDetailsContext: projectDetailsContext,
                formlayoutContext: formlayoutContext,
                gridType: gridType,
                mode: mode,
                rowDataPD: rowData,
                columnDefsPD: columnDefs
            }
          });

          dialogRef.afterClosed().subscribe(result => {
            // No action needed after closing the dialog
          });
    }

    async updatePrepaymentDetails() {
        // to be tested once frontend is completed
        let editedData = [];

        this.agGrids['prepaymentDetails'].forEachNode(node => {
            if(node.data.isModified){
                editedData.push(node.data);
            }
        });

        let prepaymentDetails: any = {
            ID: editedData[0].ID,
            client: this.selectedClientCode,
            project: this.selectedProjectCode,
            prepaymentAmt: parseInt(editedData[0].Prepayment_Amount),
            referenceNo: editedData[0].Reference_Number,
            prepaymentDate: moment(editedData[0].Prepayment_Date).format('YYYY-MM-DD'),
            description: editedData[0].Description,
        }
        this.getProjectDetailsTables(prepaymentDetails.client, prepaymentDetails.project).then(data => {
            this.projectDetailTables = data;
            this.originalRowData.length = 0;
            this.setProjectDetailRows(data);
        })
        return new Promise((resolve, reject) => {
            this._http.put(URLPaths.URL_PREPAYMENT_DETAILS, { 'update_prepayment_details': prepaymentDetails }, { headers: ApiheaderService.getHeaders() })
                .pipe(
                    catchError(async (response) => {
                        this.showSuccessOrFailureSnackbar(response.error);
                        reject('failure');
                    })
                )
                .subscribe((response) => {
                    this.getProjectDetailsTables(prepaymentDetails.client, prepaymentDetails.project).then(data => {
                        this.projectDetailTables = data;
                        this.originalRowData.length = 0;
                        this.setProjectDetailRows(data);
                    });
                    this.showSuccessOrFailureSnackbar(response);
                    if (response['Type'] === 'success') {
                        resolve('success');
                    } else {
                        resolve('failure');
                    }
                });
        });
    }


    async addPrepaymentDetails(formData): Promise<any> {
        const prepaymentDetails: any = {
            client: this.selectedClientCode,
            project: this.selectedProjectCode,
            purchaseOrderNo: formData.prepaymentPurchaseOrderNo === undefined ? '' : formData.prepaymentPurchaseOrderNo,
            prepaymentAmt: parseInt(formData.prepaymentAmt),
            referenceNo: formData.referenceNo,
            prepaymentDate: moment(formData.prepaymentDate).format('YYYY-MM-DD'),
            description: formData.prepaymentDescription,
            currentUser: this.loginName
        }

        return new Promise((resolve, reject) => {
            this._http.post(URLPaths.URL_PREPAYMENT_DETAILS, { 'save_prepayment_details': prepaymentDetails }, { headers: ApiheaderService.getHeaders() })
                .pipe(
                    catchError(async (response) => {
                        this.showSuccessOrFailureSnackbar(response.error);
                        reject('failure');
                    })
                )
                .subscribe((response) => {
                    this.showSuccessOrFailureSnackbar(response);
                    this.getProjectDetailsTables(prepaymentDetails.client, prepaymentDetails.project).then(data => {
                        this.projectDetailTables = data;
                        this.setProjectDetailRows(data);
                    });
                    if (response['Type'] === 'success') {
                        resolve('success');
                    } else {
                        resolve('failure');
                    }
                });
        });
    }

    // getClients() {
    //     let clientDropdownValues = []
    //     this.clientDetails = JSON.parse(LocalStorageService.getLocalStorageValueBasedOnKeys('clients_projectDetails'));
    //     this.clientDetails.forEach((clients) => {
    //         clientDropdownValues.push(clients["ClientDescription"]);
    //     })
    //     clientDropdownValues = commonFunctions.removeDuplicatesAndSort(clientDropdownValues);
    //     return clientDropdownValues;

    // }

    async getClients() {
        let clientDropdownValues = [];
        this.clientDetails = await this._commonService.getClientswithInetrnalCode('projectDetails');
        const sortedClients = this.SortUsingInternal(this.clientDetails,"Client");
        clientDropdownValues = sortedClients.map(client => `${client.ClientName} - ${client.Client}`);

        return clientDropdownValues;
    }

    async updateInternalFieldState(client: string) {
        const sortedClients = this.SortUsingInternal(this.clientDetails, "Client");
        const filteredClient = sortedClients.find(c => c.Client === this.getClientCode(client));
        const internalValue = filteredClient ? filteredClient.Internal : false;

        return [internalValue];
    }

    async getProjectsContacts(client: string) {
        await this._commonService.getFilteredProjectsAndContacts(client);
        return JSON.parse(SessionStorageService.getSessionStorageValueBasedOnKeys('projects-contacts_' + client));
    }

    async getProjectDetailsTables(clientCode: string, projectCode: string) {
        return await this._commonService.ProjectDetailTables(clientCode, projectCode);
    }

    setProjectDetailRows(projectDetailTables) {
        this.projectDetailTableRowData.next(projectDetailTables);
    }

    setProjectSelectedBoolean(value) {
        this.projectSelectedBoolean.next(value);
    }

    setAddProjectOtherFormsDisabled(value) {
        this.addProjectOtherFormsDisabled.next(value);
    }

    setViewProjectOtherFormsDisabled(value) {
        this.viewProjectOtherFormsDisabled.next(value);
    }

    SortUsingInternal(array: any[], type: string): any[] {
        const sortedArray = commonFunctions.removeDuplicatesAndSort(array);
        const sortByInternal = (firstValue: any, secondValue: any) => {
            const firstInternal = type === "Project" ? firstValue.internal : firstValue.Internal;
            const secondInternal = type === "Project" ? secondValue.internal : secondValue.Internal;

            if (firstInternal === false && secondInternal !== false) return -1;
            if (firstInternal !== false && secondInternal === false) return 1;
            return 0;
        };
        sortedArray.sort(sortByInternal);

        return sortedArray;
    }


    async getProjects(client: string) {
        this.selectedClientCode = this.getClientCode(client);
        let projectDropdownValues = [];

        this.projectContactDetails = await this.getProjectsContacts(client);
        this.projectDetails = this.projectContactDetails[client]["project_details"];

        projectDropdownValues = this.projectDetails.map(project => project["project"]);

        const sortedProjects = this.SortUsingInternal(this.projectDetails,"Project");

        projectDropdownValues = sortedProjects.map(project => project.project);

        return projectDropdownValues;
    }

    async getProjectCodeshere(client: string) {
        let projectNameAndCode = this.getProjects(client);
        let projectCodes = (await (projectNameAndCode)).map(item => {
            const parts = item.split(' - ');
            return parts[1];
        });

        return projectCodes;
    }

    //To fill project codes for ADD MODE (Object with disabled field to disable in frontend)
    async getProjectCodes(client: string) {
        let projectNameAndCode = this.getProjects(client);

        let projectCodes = (await projectNameAndCode).map(item => {
            const parts = item.split(' - ');
            return {label: parts[1], disabled: true}; // Extract and return the project code
            // return parts[1];
        });

        return projectCodes;
    }

    async getContacts(client: string) {
        let contactDropdownValues = [];
        this.projectContactDetails = await this.getProjectsContacts(client);
        this.contactDetails = this.projectContactDetails[client]["contact_details"];

        this.contactDetails.forEach((contact) => {
            contactDropdownValues.push(contact["contact"]);
        })
        return commonFunctions.removeDuplicatesAndSort(contactDropdownValues);
    }

    async getProjectTypes() {
        let projectTypeDropdownValues = [];
        await this._commonService.getProjectTypes();
        this.projectTypes = JSON.parse(LocalStorageService.getLocalStorageValueBasedOnKeys('project_types'));
        this.projectTypes.forEach((projectType) => {
            projectTypeDropdownValues.push(projectType["projectType"]);
        })
        return projectTypeDropdownValues;
    }

    async getCompanies() {
        await this._commonService.getAllCompanies('projectDetails');
        const companyDetails = JSON.parse(LocalStorageService.getLocalStorageValueBasedOnKeys('companies_projectDetails'));
        let companies: string[] = [];

        for (const companyDetail of companyDetails) companies.push(companyDetail['Company_Name']);

        return companies;
    }

    async getCountries() {
        await this._commonService.getCountryDetails();
        let countryDetails = JSON.parse(LocalStorageService.getLocalStorageValueBasedOnKeys('country_details'));

        let countries: string[] = [];

        for(const country of countryDetails) countries.push(country['countryDescription']);
        return countries;
    }

    async getStates() {
        await this._commonService.getCountryDetails();
        let countryDetails = JSON.parse(LocalStorageService.getLocalStorageValueBasedOnKeys('country_details'));
        let states: string[] = [];

        for(const country of countryDetails) {
            if (country['countryDescription'] === GlobalConstants.countriesWithDisabledNoVatField[0]) {
                let stateDetails = country['states'];
                for (let state of stateDetails) states.push(state['stateName'])
                break;
            }
        }
        return states;
    }

    async getInvoiceFormats() {
        await this._commonService.getInvoiceFormats();
        const invoiceFormatsResponse = JSON.parse(LocalStorageService.getLocalStorageValueBasedOnKeys('invoice_formats'));
        let invoiceFormats: string[] = [];

        for (const invoiceFormat of invoiceFormatsResponse) {
            invoiceFormats.push(invoiceFormat['formatDescription']);
        }

        return invoiceFormats;
    }

    setProjectCodeValidation(form: FormGroup<any>) {
        form.get('PR-CODE')?.valueChanges.subscribe(value => {
            if (value.length > 4) {
              form.get('PR-CODE')?.setValue(value.slice(0, 4), { emitEvent: false });
            }
          });
    }

    getThemeList(){
        let themeDesc: string[] = []
        this._commonService.getProjectThemes().then((themes: any) => {
            for (let theme of themes) {
                themeDesc.push(theme.themeDesc)
            }
        })
        return themeDesc;
      }

    async getPromptPaymentPeriodTypes() {
        await this._commonService.getPromptPaymentPeriodTypes();
        const periodTypesResponse = JSON.parse(LocalStorageService.getLocalStorageValueBasedOnKeys('prompt_payment_period_types'))
        let periodTypes: string[] = [];

        for (const invoiceFormat of periodTypesResponse) {
            periodTypes.push(invoiceFormat['periodTypeDescription']);
        }

        return periodTypes;
    }

    getEmployees() {
        let employeeNames: string[] = []
        this._consultantDetailsService.getConsultantDetails().subscribe((employeeDetails: ConsultantModel[]) => {
            for (let employee of employeeDetails) {
                employeeNames.push(employee.Employee)
            }
        })
        return employeeNames;
    }

    async getSalesTaxDetails() {
        await this._commonService.getSalesTaxDetails();
        const salesTaxOfLocations = JSON.parse(LocalStorageService.getLocalStorageValueBasedOnKeys('sales_tax_details'));
        return salesTaxOfLocations
    }

    async getTypesOfService() {
        await this._commonService.getTypesOfService();
        const typesOfServiceResponse = JSON.parse(LocalStorageService.getLocalStorageValueBasedOnKeys('types_of_service'))
        let serviceTypes: string[] = [];

        for (const typeOfService of typesOfServiceResponse) {
            serviceTypes.push(typeOfService['type_of_service_desc'])
        }

        return serviceTypes;
    }

    async getPurchaseOrderDetails(client: string, project: string) {
        const selectedProjectCode = this.getProjectCode(project);
        const selectedClientCode = this.getClientCode(client);
        await this._commonService.getPurchaseOrderDetails(selectedClientCode, selectedProjectCode);
        const purchaseOrderDetailsResponse = JSON.parse(SessionStorageService.getSessionStorageValueBasedOnKeys('purchase_order_details_' + this.selectedClientCode + '_' + selectedProjectCode));

        let purchaseOrderDescriptions: string[] = [];
        let latestPurchaseOrderNo = 0;
        let latestPurchaseOrder = '';
        for (const purchaseOrderDesc of purchaseOrderDetailsResponse) {
            purchaseOrderDescriptions.push(purchaseOrderDesc['PurchaseOrderLongDescription']);
            let currentPurchaseOrderNo = commonFunctions.extractNumericPart(purchaseOrderDesc['PurchaseOrderNumber']);
            if ( currentPurchaseOrderNo > latestPurchaseOrderNo )
                latestPurchaseOrderNo = currentPurchaseOrderNo;
                latestPurchaseOrder = purchaseOrderDesc['PurchaseOrderLongDescription'];
        }

        this.purchaseOrderList = purchaseOrderDescriptions

        return { fieldValues: purchaseOrderDescriptions, defaultValue: latestPurchaseOrder };
    }

    resetContactforAdd(fieldDetails, form) {
        let fieldsToReset: string[] = [];
        fieldsToReset.push('CONT-MODE');
        this.resetFieldsFunction(fieldsToReset, form, fieldDetails)
    }

    resetPurchaseOrder(fieldCode, fieldDetails, fieldsToReset, form) { // Not required anymore - Sangeetha
        if(fieldCode === 'PR') {
            for (const fieldDetail of fieldDetails) {
                if (fieldDetail.fieldCode === "PO-NO-VW") {
                  fieldDetail.defaultValue = null;
                  break;
                }
            }
            fieldsToReset.push('AVAIL-CONT');
            fieldsToReset.push('CN');
            fieldsToReset.push('ST');
            fieldsToReset.push('TOS');
            fieldsToReset.push('CNPJ');
            fieldsToReset.push('PR')
            fieldsToReset.push('CL')

            this.resetFieldsFunction(fieldsToReset, form, fieldDetails)
        }
    }

    resetFieldsFunction(fieldsToReset, form, fieldDetails) {
        for(const fieldDetail of fieldDetails) {
            if(fieldsToReset.includes(fieldDetail.fieldCode) && fieldDetail.fieldCode !== 'CL' && fieldDetail.fieldCode !== 'PR') {
                form.controls[fieldDetail.fieldCode].disable();
            }
        }
    }

    resetFieldsFunctionClear(fieldsToReset, form, fieldDetails) {
        for(const fieldDetail of fieldDetails) {
            if(fieldsToReset.includes(fieldDetail.fieldCode) && fieldDetail.fieldCode !== 'CL') {
                form.controls[fieldDetail.fieldCode].disable();
            }
        }
    }

    async getMonths() {
        await this._commonService.getPeriodMonths();
        const months = JSON.parse(LocalStorageService.getLocalStorageValueBasedOnKeys('period_months'));

        let yearMonthStrings: string[] = [];

        for (const month of months) {
            yearMonthStrings.push(month['periodMonth'].toString());
        }

        return yearMonthStrings;
    }

    async fillRootDropdownValues(commonProps: any, fieldDetail: any, fieldValues: Map<string, any>) {
        const fieldValuesFunction = fieldDetail.fieldValuesFunction
        const functionParams = fieldDetail.fieldValuesFunctionParams

        let actualParams = []
        for (const fieldCode of functionParams) {
            actualParams.push(fieldValues.get(fieldCode))
        }
        commonProps.fieldValues = await commonFunctions.callFunction.bind(this)(fieldValuesFunction, actualParams);
        this.enabledFields.add(commonProps.fieldCode)

        return commonProps;
    }

    //Whenever you add or update the blueprint ensure necessary changes have been made here
    async initialMetaDataConstructorForViewMode(fieldDetails, fieldValues: Map<string, any>) {

        let fieldData = []
        for (let index in fieldDetails) {
            let commonProps = {
                "fieldCode": fieldDetails[index].fieldCode,
                "fieldName": fieldDetails[index].fieldName,
                "fieldValuesFunction": fieldDetails[index].fieldValuesFunction,
                "fieldValuesFunctionParams": fieldDetails[index].fieldValuesFunctionParams,
                "fieldValues": fieldDetails[index].fieldValues,
                "fieldType": fieldDetails[index].fieldType,
                "inputType": fieldDetails[index].inputType,
                "fieldDisplayName": fieldDetails[index].fieldDisplayName,
                'fillFieldNameFunction': typeof fieldDetails[index].fillFieldNameFunction === 'function' ? fieldDetails[index].fillFieldNameFunction.bind(ProjectDetailsBlueprint) : fieldDetails[index].fillFieldNameFunction,
                'functionToFillOtherFields': fieldDetails[index].functionToFillOtherFields,
                'fillOtherFieldsFunctionParams': fieldDetails[index].fillOtherFieldsFunctionParams,
                'fillFieldNameFunctionParams': fieldDetails[index].fillFieldNameFunctionParams,
                "placeholderValue": (typeof fieldDetails[index].fieldPlaceholder === 'object' ? eval(fieldDetails[index].fieldPlaceholder) : fieldDetails[index].fieldPlaceholder),
                "defaultValue": (typeof fieldDetails[index].fieldDefaultValue === 'object' ? eval(fieldDetails[index].fieldDefaultValue) : fieldDetails[index].fieldDefaultValue),
                "enabledFor": fieldDetails[index].enabledFor,
                'disabledFor': fieldDetails[index].disabledFor,
                'parentFields': fieldDetails[index].parentFields,
                'required': fieldDetails[index].required,
            };

            if (commonProps.enabledFor === ConstantFormStates.enabledAlways && commonProps.fieldType === Constants.DROPDOWN_FIELD_NAME)
                commonProps = await this.fillRootDropdownValues(commonProps, fieldDetails[index], fieldValues);
            fieldData.push(commonProps);
        }
        return fieldData;
    }

    async initialMetaDataConstructorForAddMode(fieldDetails, fieldValues: Map<string, any>) {
        let fieldData = []
        for (let index in fieldDetails) {
            let commonProps = {
                "fieldCode": fieldDetails[index].fieldCode,
                "fieldName": fieldDetails[index].fieldName,
                "fieldValuesFunction": fieldDetails[index].fieldValuesFunction,
                "fieldValuesFunctionParams": fieldDetails[index].fieldValuesFunctionParams,
                "fieldValues": fieldDetails[index].fieldValues,
                "fieldType": fieldDetails[index].fieldType,
                "fieldKey": fieldDetails[index].fieldType === Constants.RADIO_FIELD_NAME ? fieldDetails[index].fieldKey : [],
                "buttonClickHandler": fieldDetails[index].fieldType === Constants.BUTTON_FIELD_NAME ? fieldDetails[index].buttonClickHandler : "",
                "isValidationRequired": fieldDetails[index].fieldType === Constants.BUTTON_FIELD_NAME ? fieldDetails[index].isValidationRequired : "",
                "inputType": fieldDetails[index].inputType,
                "fieldDisplayName": fieldDetails[index].fieldDisplayName,
                'fillFieldNameFunction': typeof fieldDetails[index].fillFieldNameFunction === 'function' ? fieldDetails[index].fillFieldNameFunction.bind(ProjectDetailsBlueprint) : fieldDetails[index].fillFieldNameFunction,
                'functionToFillOtherFields': fieldDetails[index].functionToFillOtherFields,
                'fillOtherFieldsFunctionParams': fieldDetails[index].fillOtherFieldsFunctionParams,
                'fillFieldNameFunctionParams': fieldDetails[index].fillFieldNameFunctionParams,
                "placeholderValue": (typeof fieldDetails[index].fieldPlaceholder === 'object' ? eval(fieldDetails[index].fieldPlaceholder) : fieldDetails[index].fieldPlaceholder),
                "defaultValue": (typeof fieldDetails[index].fieldDefaultValue === 'object' ? eval(fieldDetails[index].fieldDefaultValue) : fieldDetails[index].fieldDefaultValue),
                "enabledFor": fieldDetails[index].enabledFor,
                'disabledFor': fieldDetails[index].disabledFor,
                'parentFields': fieldDetails[index].parentFields,
                'required': fieldDetails[index].required,
            };

            if (commonProps.enabledFor === ConstantFormStates.enabledAlways && commonProps.fieldType === Constants.DROPDOWN_FIELD_NAME)
                commonProps = await this.fillRootDropdownValues(commonProps, fieldDetails[index], fieldValues);

            fieldData.push(commonProps);
        }
        return fieldData;
    }

    async initialMetaDataConstructorForEditMode(fieldDetails, fieldValues: Map<string, any>) {

        let fieldData = []
        for (let index in fieldDetails) {
            let commonProps = {
                "fieldCode": fieldDetails[index].fieldCode,
                "fieldName": fieldDetails[index].fieldName,
                "fieldValuesFunction": fieldDetails[index].fieldValuesFunction,
                "fieldValuesFunctionParams": fieldDetails[index].fieldValuesFunctionParams,
                "fieldValues": fieldDetails[index].fieldValues,
                "fieldType": fieldDetails[index].fieldType,
                "fieldKey": fieldDetails[index].fieldType === Constants.RADIO_FIELD_NAME ? fieldDetails[index].fieldKey : [],
                "buttonClickHandler": fieldDetails[index].fieldType === Constants.BUTTON_FIELD_NAME ? fieldDetails[index].buttonClickHandler : "",
                "isValidationRequired": fieldDetails[index].fieldType === Constants.BUTTON_FIELD_NAME ? fieldDetails[index].isValidationRequired : "",
                "inputType": fieldDetails[index].inputType,
                "fieldDisplayName": fieldDetails[index].fieldDisplayName,
                'fillFieldNameFunction': typeof fieldDetails[index].fillFieldNameFunction === 'function' ? fieldDetails[index].fillFieldNameFunction.bind(ProjectDetailsBlueprint) : fieldDetails[index].fillFieldNameFunction,
                'functionToFillOtherFields': fieldDetails[index].functionToFillOtherFields,
                'fillOtherFieldsFunctionParams': fieldDetails[index].fillOtherFieldsFunctionParams,
                'fillFieldNameFunctionParams': fieldDetails[index].fillFieldNameFunctionParams,
                "placeholderValue": (typeof fieldDetails[index].fieldPlaceholder === 'object' ? eval(fieldDetails[index].fieldPlaceholder) : fieldDetails[index].fieldPlaceholder),
                "defaultValue": (typeof fieldDetails[index].fieldDefaultValue === 'object' ? eval(fieldDetails[index].fieldDefaultValue) : fieldDetails[index].fieldDefaultValue),
                "editable": fieldDetails[index].isEditable,
                "enabledFor": fieldDetails[index].enabledFor,
                'disabledFor': fieldDetails[index].disabledFor,
                'parentFields': fieldDetails[index].parentFields,
                'required': fieldDetails[index].required,
                'invoiceEnable': fieldDetails[index].invoiceEnable
            };

            if (commonProps.enabledFor === ConstantFormStates.enabledAlways && commonProps.fieldType === Constants.DROPDOWN_FIELD_NAME)
                commonProps = await this.fillRootDropdownValues(commonProps, fieldDetails[index], fieldValues);

            fieldData.push(commonProps);
        }
        return fieldData;
    }

    async initialMetaDataConstructor(fieldDetails, mode: string, fieldValues: Map<string, any>) {
        this.initializeProjectDetailsBlueprint()
        switch (mode) {
            case Constants.FORM_VIEW_MODE:
                return this.initialMetaDataConstructorForViewMode(fieldDetails, fieldValues);
            case Constants.FORM_ADD_MODE:
                return this.initialMetaDataConstructorForAddMode(fieldDetails, fieldValues);
            case Constants.FORM_EDIT_MODE:
                return this.initialMetaDataConstructorForEditMode(fieldDetails, fieldValues);
        }
    }

    getFilterValues(conditions, fieldCode: string) {

        let filterValues: unknown[] | ConstantFormStates = []
        if (conditions[fieldCode].hasOwnProperty('functionToGetFilterValues')) {
            const params = conditions[fieldCode].params
            const filterFunction = conditions[fieldCode].functionToGetFilterValues
            let actualParams = []
            for (const field of params) {
                actualParams.push(this.dropdownValues.get(field))
            }
            filterValues = filterFunction(...actualParams)
        } else if (conditions[fieldCode].hasOwnProperty('filterValues')) {
            filterValues = conditions[fieldCode].filterValues
        } else filterValues = conditions[fieldCode];

        return filterValues;
    }

    isStringInteger(value: string): boolean {
        // Use parseInt to try converting the string to an integer
        const parsed = parseInt(value, 10);

        // Check if parsed value is a number and the original string was a valid integer string representation
        return !isNaN(parsed) && parsed.toString() === value;
    }

    async fillTypeOfService(fieldsToSet: any[], fieldDetails: any[], invoiceFieldDetails: any[]) {
        let billingCompany, country;
        for(const fields of fieldsToSet) {
            if(fields[0] === 'BC') {
               billingCompany = fields[1];
            }
        }
        for (const field of fieldsToSet) {
            if (field[0] === 'TOS' && billingCompany === 'Thorogood Brazil Ltda') {
                const services = await this.getTypesOfService();
                for(let fieldDetail of invoiceFieldDetails) {
                    if(fieldDetail.fieldCode === 'TOS') {
                        try {
                            field[1] = services;
                            fieldDetail.fieldValues = services

                       } catch (error) {
                           console.error("Error getting types of service: ", error);
                       }
                    }
                }
            }
        }

        for (const field of fieldsToSet) {
            if (field[0] === 'TOS'){
                const services = await this.getTypesOfService();
                if (this.isStringInteger(field[1])) {
                    field[1] = services[parseInt(field[1]) - 1]
                }
            }
        }
        return [fieldsToSet, fieldDetails, invoiceFieldDetails]
    }

    disableState(form, mode) {
        if(mode ===  Constants.FORM_EDIT_MODE) {
            let countryStatus = form.controls["CN"].status;
            if(countryStatus === "DISABLED") form.controls["ST"].disable();
       }
    }

    getSalesTaxDefaultValue(country: string, state: string) {
        return ProjectDetailsBlueprint.getLocationToTaxPercentageMap({ country: country, state: state })
    }

    fillProjectDetails(client: string, project: string, fieldDetails) {
        let fieldsToSet: any[] = []
        const clientDetails = JSON.parse(SessionStorageService.getSessionStorageValueBasedOnKeys(ProjectDetailsBlueprint.projectContactDetailsLocalStorageNamePrefix + client))
        let propertyNameInResponse = this.fieldToResponsePropertyNameMap.get('PR')

        if (clientDetails[client].hasOwnProperty(propertyNameInResponse)) {
            const selectedFieldDetails = clientDetails[client][propertyNameInResponse];
            for (let fieldDetailsForSelectedValue of selectedFieldDetails) {
                if (fieldDetailsForSelectedValue[this.valuePropInResponse.get('PR')] === project) {
                    let namesOfFieldsToBeSet: Set<string> = new Set<string>()

                    for (const fieldName of Object.keys(fieldDetailsForSelectedValue)) {
                        namesOfFieldsToBeSet.add(fieldName)
                    }

                    for (let index in fieldDetails) {
                        const fieldName = fieldDetails[index].fieldName
                        const fieldCode = fieldDetails[index].fieldCode
                        if (namesOfFieldsToBeSet.has(fieldName)) {
                            let fieldValueForSelectedSelection = fieldDetailsForSelectedValue[fieldName];

                            if (typeof fieldValueForSelectedSelection === 'boolean') fieldValueForSelectedSelection = commonFunctions.booleanToString(fieldValueForSelectedSelection);
                            // fill fields
                            fieldsToSet.push([fieldCode, fieldValueForSelectedSelection]);
                        }
                    }
                    break;
                }
            }
        }
        return fieldsToSet;
    }

    fillContactDetails(client: string, contact: string, fieldDetails) {
        let fieldsToSet: any[] = []
        const clientDetails = JSON.parse(SessionStorageService.getSessionStorageValueBasedOnKeys(ProjectDetailsBlueprint.projectContactDetailsLocalStorageNamePrefix + client))
        let propertyNameInResponse = this.fieldToResponsePropertyNameMap.get('AVAIL-CONT')
        let contactCountry: string, contactState: string;

        if (clientDetails[client].hasOwnProperty(propertyNameInResponse)) {
            const selectedFieldDetails = clientDetails[client][propertyNameInResponse]

            for (let fieldDetailsForSelectedValue of selectedFieldDetails) {

                if (fieldDetailsForSelectedValue[this.valuePropInResponse.get('AVAIL-CONT')] === contact) {
                    let namesOfFieldsToBeSet: Set<string> = new Set<string>()

                    for (const fieldName of Object.keys(fieldDetailsForSelectedValue)) {
                        namesOfFieldsToBeSet.add(fieldName)
                    }

                    for (let index in fieldDetails) {
                        const fieldName = fieldDetails[index].fieldName
                        const fieldCode = fieldDetails[index].fieldCode
                        if (namesOfFieldsToBeSet.has(fieldName)) {
                            let fieldValueForSelectedSelection = fieldDetailsForSelectedValue[fieldName];

                            if (typeof fieldValueForSelectedSelection === 'boolean') fieldValueForSelectedSelection = commonFunctions.booleanToString(fieldValueForSelectedSelection);
                            fieldsToSet.push([fieldCode, fieldValueForSelectedSelection]);

                            if (fieldCode === 'CN') contactCountry = fieldValueForSelectedSelection
                            if (fieldCode === 'ST') contactState = fieldValueForSelectedSelection
                        }
                    }
                    break;
                }
            }
        }
        // Extracting values of 'CONT-NAME' and 'ADD'
        let contactName: string | undefined;
        let address: string | undefined;
        let country: string | undefined;

        for (let field of fieldsToSet) {
            if (field[0] === 'CONT-NAME') {
                contactName = field[1];
            }
            if (field[0] === 'ADD') {
                address = field[1];
            }
            if (field[0] === 'CN') {
                country = field[1];
            }
        }

        this.taxRate = this.getSalesTaxDefaultValue(contactCountry, contactState)

        let invoiceFieldsToSet = [...this.invoiceDetails.fieldsToSet];

        invoiceFieldsToSet = [...this.invoiceDetails.fieldsToSet];

        if (invoiceFieldsToSet.length != 0) {
            for (let index in invoiceFieldsToSet) {
                if (invoiceFieldsToSet[index] && invoiceFieldsToSet[index][0] === "TAX") {
                    invoiceFieldsToSet = invoiceFieldsToSet.filter(subArray => subArray[0] !== 'TAX');
                }
            }
        }

        invoiceFieldsToSet.push(['TAX', this.getSalesTaxDefaultValue(contactCountry, contactState)]);
        this._store.dispatch(setprojectDetailsInvoiceDetails( { projectDetailsInvoiceDetails : {
            selectionFields : this.invoiceDetails.selectionFields,
            fieldsToEnable: this.invoiceDetails.fieldsToEnable,
            fieldsToDisable: this.invoiceDetails.fieldsToDisable,
            fieldsToSet: invoiceFieldsToSet,
            fieldsToReset: this.invoiceDetails.fieldsToReset,
            fieldDetails: this.invoiceDetails.fieldDetails,
            fieldValues: this.invoiceDetails.fieldValues,
            projectFieldValues: this.invoiceDetails.projectFieldValues
        }
        }))

        fieldsToSet.push(['TAX', this.getSalesTaxDefaultValue(contactCountry, contactState)]);
        fieldsToSet.push(['IN-CONT', contactName]);
        fieldsToSet.push(['IN-CONT-ADD', address]);

        if(country !== 'United States of America') this.availableContactChosen = true;

        this.countryForInvoice = country;

        return fieldsToSet;
    }

    fillPrepaymentPO(poNumber: any) {
        // poNumber = poNumber.split('-')[0];
        this._store.dispatch(setprojectDetailsPONumber({ projectDetailsPONumber: poNumber }))

    }

    setPOFormValue(form, poNumber: any) {
        if(form != undefined){
            form.patchValue({ 'PO-NO-PPD': poNumber })
        }
    }

    fillFields(fieldDetails, selectedFieldCode: string, fieldValues: Map<string, any>) {
        for (let index in fieldDetails) {
            const fieldCode = fieldDetails[index].fieldCode
            if (fieldCode === selectedFieldCode) {
                const functionToFillOtherFields = fieldDetails[index].functionToFillOtherFields
                const functionParams = fieldDetails[index].fillOtherFieldsFunctionParams

                let actualParams = []
                for (const field of functionParams) {
                    actualParams.push(fieldValues.get(field))
                }
                if (functionToFillOtherFields !== '') {
                    return commonFunctions.callFunction.bind(this)(functionToFillOtherFields, [...actualParams, fieldDetails])
                }
            }
        }
    }

    async enableFieldsAndFillDropdownOptions(candidateFieldCode: string, fieldsToEnable: string[], fieldsToReset: string[], fieldsToDisable: string[], fieldValues: Map<string, any>, fieldDetail, fieldDetails, props, mode: string) {
        // enable field
        this.enabledFields.add(candidateFieldCode)
        fieldsToEnable.push(candidateFieldCode)

        const functionToFillFieldValues = fieldDetail.fieldValuesFunction
        const functionParams = fieldDetail.fieldValuesFunctionParams

        // fills other dropdown values (eg., when you select Client, Contacts get filled)
        if (functionToFillFieldValues !== '') {
            fieldsToReset.push(candidateFieldCode);    // reset the dropdown value (eg., when Client is selected, reset Contact dropdown)

            let actualParams = []
            for (const fieldCode of functionParams) {
                actualParams.push(fieldValues.get(fieldCode));
            }

            fieldDetail.fieldValues = await commonFunctions.callFunction.bind(this)(fieldDetail.fieldValuesFunction, actualParams);
            if(mode == 'edit' && candidateFieldCode == 'PO-NO-VW'){
                this.fillPrepaymentPO(fieldDetail.fieldValues.defaultValue);
            }
        }
    }

    disableAndResetFields(candidateFieldCode: string, fieldsToDisable: string[], fieldsToReset: string[], fieldDetails, fieldDetail, props, mode: string, fieldValues: Map<string, any>) {
        fieldsToDisable.push(candidateFieldCode)
        fieldsToReset.push(candidateFieldCode)
        this.enabledFields.delete(candidateFieldCode)
        let formMetadata = this.onDropdownClear(fieldDetails, props, candidateFieldCode, mode, fieldValues)
        fieldDetails = formMetadata.fieldDetails
        for (let fieldCode of formMetadata.fieldsToReset) fieldsToReset.push(fieldCode)
        for (let fieldCode of formMetadata.fieldsToDisable) fieldsToDisable.push(fieldCode)
        if (fieldDetail.fieldValuesFunction !== '') {
            fieldDetail.fieldValues = '';
            fieldDetail.defaultValue = ''
        }
    }

    async checkValidityAndEnableField(selectedFieldCode: string, candidateFieldCode: string, selectedValue, conditionsToEnable, fieldValues: Map<string, any>, fieldsToEnable, fieldsToDisable, fieldDetail, fieldsToReset, fieldDetails, props, mode: string) {
        let fieldHasDependencyToSelectedField: boolean = false;
        for (let fieldCode of Object.keys(conditionsToEnable)) {
            if (fieldCode === selectedFieldCode) {
                const filterValues = this.getFilterValues(conditionsToEnable, fieldCode);
                if (filterValues === ConstantFormStates.enabledAlways || filterValues.includes(selectedValue)) {
                    fieldHasDependencyToSelectedField = true;
                } else {
                    this.disableAndResetFields(candidateFieldCode, fieldsToDisable, fieldsToReset, fieldDetails, fieldDetail, props, mode, fieldValues);
                }
                break;
            }
        }

        if (fieldHasDependencyToSelectedField || fieldDetail.enabledFor === ConstantFormStates.enabledAlways) {
            let childEnabled: boolean = true;
            for (let fieldCode of Object.keys(conditionsToEnable)) {
                if (fieldCode !== selectedFieldCode) {
                    if (!fieldValues.get(fieldCode)) {
                        childEnabled = false;
                        break;
                    }
                    const filterValues = this.getFilterValues(conditionsToEnable, fieldCode)
                    const parentHasValidValue: boolean = filterValues.includes(fieldValues.get(fieldCode))
                    if (!parentHasValidValue) {
                        childEnabled = false;
                        break;
                    }
                }
            }
            fieldsToReset = []
            if (childEnabled) {
                await this.enableFieldsAndFillDropdownOptions(candidateFieldCode, fieldsToEnable, fieldsToReset, fieldsToDisable, fieldValues, fieldDetail, fieldDetails, props, mode)
            }
        }
    }

    depthFirstSearchOnFields(selectionFields, fieldCode, fieldsToReset: string[], fieldsToDisable: string[], fieldValues: Map<string, any>) {
        if (!selectionFields[fieldCode]) return;

        for (let child of selectionFields[fieldCode]) {
            fieldsToReset.push(child);
            fieldValues.delete(child);
            fieldsToDisable.push(child);
            this.depthFirstSearchOnFields(selectionFields, child, fieldsToReset, fieldsToDisable, fieldValues);
        }
    }

    checkSelection(selectedFieldCode, mode){
        return selectedFieldCode === 'CL' && mode === "add"
    }

    clearContactDetailsAfterSelectingNewClient(selectedFieldCode, fieldsToReset, fieldsToDisable){
        if(selectedFieldCode === 'CL'){
            fieldsToReset.push("CONT-MODE")
            fieldsToReset.push("CONT-NAME")
            fieldsToReset.push("AVAIL-CONT")
            fieldsToReset.push("ADD")
            fieldsToReset.push("TELE")
            fieldsToReset.push("RET-MIL")
            fieldsToReset.push("CN")
            fieldsToReset.push("ST")

            fieldsToDisable.push("AVAIL-CONT")
        }
        return {fieldsToReset, fieldsToDisable};
    }

    //Function to set values in store
    async setInStore(invoiceSelectionFields, invoiceFieldsToEnable, invoiceFieldsToDisable, invoiceFieldsToSet, invoiceFieldsToReset, invoiceFieldDetailsUpdate){
        this.projectDetailTables = await this.getProjectDetailsTables(this.selectedClientCode, this.selectedProjectCode)
                    this.setProjectDetailRows(this.projectDetailTables);
                    this.setProjectSelectedBoolean(false);
                    this._store.dispatch(setprojectDetailsInvoiceDetails( { projectDetailsInvoiceDetails : {
                            selectionFields : invoiceSelectionFields,
                            fieldsToEnable: invoiceFieldsToEnable,
                            fieldsToDisable: invoiceFieldsToDisable,
                            fieldsToSet: invoiceFieldsToSet,
                            fieldsToReset: invoiceFieldsToReset,
                            fieldDetails: invoiceFieldDetailsUpdate,
                            fieldValues: this.invoiceDetails.fieldValues,
                            projectFieldValues: this.invoiceDetails.projectFieldValues
                        }
                    }))
    }

    //Cascading for invoice when we select a company
    async selectionWhenBillingCompnany(invoiceFieldsToEnable, fieldValues, invoiceFieldsToReset, invoiceFieldsToDisable, invoiceSelectionFields, invoiceFieldsToSet, invoiceFieldDetails){
        // invoiceFieldsToEnable = this.invoiceDetails.fieldsToEnable;
        let fields: string[] = ["CNPJ", "TOS"]
        if(fieldValues.get('BC') === "Thorogood Brazil Ltda"){
            invoiceFieldsToEnable = invoiceFieldsToEnable.concat(fields)
        }
        else{
            invoiceFieldsToReset = [...new Set([...invoiceFieldsToReset, ...fields])];
            // invoiceFieldsToReset = invoiceFieldsToReset.concat(fields);
            invoiceFieldsToDisable = [...new Set([...invoiceFieldsToDisable, ...fields])];
            // invoiceFieldsToDisable = invoiceFieldsToDisable.concat(fields);
            invoiceFieldsToEnable = invoiceFieldsToEnable.filter(
                field => !fields.includes(field)
            );
        }

        let invoiceFieldDetailsUpdate: any;
        invoiceFieldDetailsUpdate = _.cloneDeep(invoiceFieldDetails);

        for(let fieldDetail of invoiceFieldDetailsUpdate) {
            if(fieldDetail.fieldCode === 'TOS') {
                try {
                    const services = await this.getTypesOfService();
                    fieldDetail.fieldValues = services

               } catch (error) {
                   console.error("Error getting types of service: ", error);
               }
            }
        }

        // await this.setInStore(invoiceSelectionFields, invoiceFieldsToEnable, invoiceFieldsToDisable, invoiceFieldsToSet, invoiceFieldsToReset, invoiceFieldDetailsUpdate)
        this.projectDetailTables = await this.getProjectDetailsTables(this.selectedClientCode, this.selectedProjectCode)
                    this.setProjectDetailRows(this.projectDetailTables);
                    this.setProjectSelectedBoolean(false);
                    this._store.dispatch(setprojectDetailsInvoiceDetails( { projectDetailsInvoiceDetails : {
                            selectionFields : invoiceSelectionFields,
                            fieldsToEnable: invoiceFieldsToEnable,
                            fieldsToDisable: invoiceFieldsToDisable,
                            fieldsToSet: invoiceFieldsToSet,
                            fieldsToReset: invoiceFieldsToReset,
                            fieldDetails: invoiceFieldDetails,
                            fieldValues: this.invoiceDetails.fieldValues,
                            projectFieldValues: fieldValues
                        }
                    }))
    }

    async selectionWhenState(fieldValues, invoiceFieldsToSet){
        let contactCountry = fieldValues.get('CN');
        let contactState = fieldValues.get('ST');

        if (invoiceFieldsToSet.length != 0) {
            for (let index in invoiceFieldsToSet) {
                if (invoiceFieldsToSet[index] && invoiceFieldsToSet[index][0] === "TAX") {
                    invoiceFieldsToSet = invoiceFieldsToSet.filter(subArray => subArray[0] !== 'TAX');
                }
            }
        }
        invoiceFieldsToSet.push(['TAX', this.getSalesTaxDefaultValue(contactCountry, contactState)])

        this.projectDetailTables = await this.getProjectDetailsTables(this.selectedClientCode, this.selectedProjectCode)
                    this.setProjectDetailRows(this.projectDetailTables);
                    this.setProjectSelectedBoolean(false);
                    this._store.dispatch(setprojectDetailsInvoiceDetails( { projectDetailsInvoiceDetails : {
                            selectionFields : this.invoiceDetails.selectionFields,
                            fieldsToEnable: this.invoiceDetails.fieldsToEnable,
                            fieldsToDisable: this.invoiceDetails.fieldsToDisable,
                            fieldsToSet: invoiceFieldsToSet,
                            fieldsToReset: this.invoiceDetails.fieldsToReset,
                            fieldDetails: this.invoiceDetails.fieldDetails,
                            fieldValues: this.invoiceDetails.fieldValues,
                            projectFieldValues: this.invoiceDetails.projectFieldValues
                        }
                    }))
    }

    //Cascading for invoice when we select a country
    async selectionWhenCountry(invoiceFieldsToDisable, invoiceFieldsToReset, fieldValues, invoiceFieldsToEnable, invoiceSelectionFields, invoiceFieldsToSet, invoiceFieldDetails){
        let fields: string[] = ["NO-VAT", "CL-VAT-NO"]
        let contactCountry: string, contactState: string;
        contactCountry = fieldValues.get('CN');
        contactState = null;
        invoiceFieldsToDisable = this.invoiceDetails.fieldsToDisable;
        invoiceFieldsToReset = this.invoiceDetails.fieldsToReset;
        if (fieldValues.get('CN') === "United States of America") {
            invoiceFieldsToReset = invoiceFieldsToReset.concat(fields)
            invoiceFieldsToDisable = invoiceFieldsToDisable.concat(fields);
        }
        else {
            invoiceFieldsToDisable = invoiceFieldsToDisable.filter(
                field => !fields.includes(field)
            );

            invoiceFieldsToEnable = [...new Set([...invoiceFieldsToEnable, ...fields])];
            invoiceFieldsToReset = [...new Set([...invoiceFieldsToReset, ...fields])];
            if (invoiceFieldsToSet.length != 0) {
                for (let index in invoiceFieldsToSet) {
                    if (invoiceFieldsToSet[index] && invoiceFieldsToSet[index][0] === "TAX") {
                        invoiceFieldsToSet = invoiceFieldsToSet.filter(subArray => subArray[0] !== 'TAX');
                    }
                }
            }
            invoiceFieldsToSet.push(['TAX', this.getSalesTaxDefaultValue(contactCountry, null)])
        }

        // await this.setInStore(invoiceSelectionFields, invoiceFieldsToEnable, invoiceFieldsToDisable, invoiceFieldsToSet, invoiceFieldsToReset, invoiceFieldDetails)

        this.projectDetailTables = await this.getProjectDetailsTables(this.selectedClientCode, this.selectedProjectCode)
                    this.setProjectDetailRows(this.projectDetailTables);
                    this.setProjectSelectedBoolean(false);
                    this._store.dispatch(setprojectDetailsInvoiceDetails( { projectDetailsInvoiceDetails : {
                            selectionFields : invoiceSelectionFields,
                            fieldsToEnable: invoiceFieldsToEnable,
                            fieldsToDisable: invoiceFieldsToDisable,
                            fieldsToSet: invoiceFieldsToSet,
                            fieldsToReset: invoiceFieldsToReset,
                            fieldDetails: invoiceFieldDetails,
                            fieldValues: this.invoiceDetails.fieldValues,
                            projectFieldValues: fieldValues
                        }
                    }))
    }

    async enableContactFields(fieldsToEnable, fieldDetails){
        fieldsToEnable.push("CONT-NAME");
        fieldsToEnable.push("ADD");
        fieldsToEnable.push("TELE");
        for (let fieldDetail of fieldDetails) {
            if (fieldDetail.fieldCode === 'CN') {
                try {
                    fieldDetail.fieldValues = await this.getCountries();
                } catch (error) {
                    console.error("Error getting types of service: ", error);
                }
            }
            if (fieldDetail.fieldCode === 'ST') {
                try {
                    fieldDetail.fieldValues = await this.getStates();
                } catch (error) {
                    console.error("Error getting types of service: ", error);
                }
            }
        }
        fieldsToEnable.push("CN");
        if(this.selectedCountryName === "United States of America"){
            fieldsToEnable.push("ST")
        }
    }

    //Cascading for contact and invoice when we select an available contact
    async selectionWhenAvailContact(selectedValue, invoiceFieldsToSet, invoiceFieldsToDisable, fieldValues, invoiceFieldsToReset, invoiceFieldsToEnable, invoiceSelectionFields, invoiceFieldDetailsUpdate, mode, fieldsToDisable){
        let fields = ["NO-VAT", "CL-VAT-NO"]
        let contactDetails = JSON.parse(SessionStorageService.getSessionStorageValueBasedOnKeys('projects-contacts_' + fieldValues.get("CL")))[fieldValues.get("CL")]["contact_details"]
        let selectedContactDetail = (contactDetails.find(contact => contact.contact === selectedValue))
        let cn = selectedContactDetail.country
        this.selectedCountryName = cn

        let inCont = selectedContactDetail.contact
        let inContAdd = selectedContactDetail.address
        invoiceFieldsToSet = [...this.invoiceDetails.fieldsToSet];

        if (invoiceFieldsToSet.length != 0) {
            for (let index in invoiceFieldsToSet) {
                if (invoiceFieldsToSet[index] && invoiceFieldsToSet[index][0] === "IN-CONT") {
                    invoiceFieldsToSet = invoiceFieldsToSet.filter(subArray => subArray[0] !== 'IN-CONT');
                }
                if (invoiceFieldsToSet[index] && invoiceFieldsToSet[index][0] === "IN-CONT-ADD") {
                    invoiceFieldsToSet = invoiceFieldsToSet.filter(subArray => subArray[0] !== 'IN-CONT-ADD');
                }
            }
        }

        invoiceFieldsToSet.push(["IN-CONT", inCont])
        invoiceFieldsToSet.push(["IN-CONT-ADD", inContAdd])

        invoiceFieldsToDisable = this.invoiceDetails.fieldsToDisable;
        invoiceFieldsToReset = this.invoiceDetails.fieldsToReset;
        if (cn === "United States of America") {
            invoiceFieldsToReset = invoiceFieldsToReset.concat(fields)
            invoiceFieldsToDisable = invoiceFieldsToDisable.concat(fields);
        }
        else {
            invoiceFieldsToDisable = invoiceFieldsToDisable.filter(
                field => !fields.includes(field)
            );
            fields = ["NO-VAT"]
            invoiceFieldsToEnable = [...new Set([...invoiceFieldsToEnable, ...fields])];
            invoiceFieldsToReset = [...new Set([...invoiceFieldsToReset, ...fields])];
            fieldsToDisable.push("ST")
        }
        await this.setInStore(invoiceSelectionFields, invoiceFieldsToEnable, invoiceFieldsToDisable, invoiceFieldsToSet, invoiceFieldsToReset, invoiceFieldDetailsUpdate)
    }

    async onDropdownSelection(fieldDetails, props, selectedFieldCode: string, selectedValue: any, mode: string, fieldValues: Map<string, any>, invoiceFieldDetails) {
        this.dropdownValues.set(selectedFieldCode, selectedValue)
        if (selectedFieldCode === props.rootField) {
            this.selectedRootValue = selectedValue
        }
        let fieldDetailsCopy = fieldDetails

        if (mode === Constants.FORM_ADD_MODE) {
            let fieldsToSet = [];
            let fieldsToReset: string[] = [];
            let fieldsToDisable: string[] = [];
            let fieldsToEnable: string[] = [];

            let invoiceFieldsToEnable = [];
            let invoiceFieldsToSet = [];
            let invoiceFieldsToReset = [];
            let invoiceFieldsToDisable = [];

            let invoiceSelectionFields = ProjectDetailsBlueprint._projectDetailsEditModeBlueprint.props.invoiceSelectionFields;

            {
                for (let index in fieldDetailsCopy) {
                    const candidateFieldCode = fieldDetailsCopy[index].fieldCode;
                    let conditionsToEnable = fieldDetailsCopy[index].enabledFor;
                    let conditionsToDisable = fieldDetailsCopy[index].disabledFor;
                    if (conditionsToEnable) {
                        await this.checkValidityAndEnableField(selectedFieldCode, candidateFieldCode, selectedValue, conditionsToEnable, fieldValues, fieldsToEnable, fieldsToDisable, fieldDetails[index], fieldsToReset, fieldDetails, props, mode);
                    }

                    if (conditionsToDisable) {
                        for (let fieldCode of Object.keys(conditionsToDisable)) {
                            if (fieldCode === selectedFieldCode) {
                                const filterValues = this.getFilterValues(conditionsToDisable, fieldCode);
                                if (filterValues.includes(selectedValue)) {
                                    this.disableAndResetFields(candidateFieldCode, fieldsToDisable, fieldsToReset, fieldDetails, fieldDetails[index], props, mode, fieldValues);
                                }
                                break;
                            }
                        }
                    }
                }
            }
            fieldsToSet = this.fillFields(fieldDetails, selectedFieldCode, fieldValues) || [];
            const contactMetadata = this.clearContactDetailsAfterSelectingNewClient(selectedFieldCode, fieldsToReset, fieldsToDisable);
            fieldsToReset = contactMetadata.fieldsToReset;
            fieldsToDisable = contactMetadata.fieldsToDisable;

            if(selectedFieldCode === 'BC'){
                await this.selectionWhenBillingCompnany(invoiceFieldsToEnable, fieldValues, invoiceFieldsToReset, invoiceFieldsToDisable, invoiceSelectionFields, invoiceFieldsToSet, invoiceFieldDetails)
            }

            else if(selectedFieldCode === 'CN'){
                await this.selectionWhenCountry(invoiceFieldsToDisable, invoiceFieldsToReset, fieldValues, invoiceFieldsToEnable, invoiceSelectionFields, invoiceFieldsToSet, invoiceFieldDetails)
            }

            else if(selectedFieldCode === 'ST'){
                await this.selectionWhenState(fieldValues, invoiceFieldsToSet);
            }

            // this block set's No-Vat field when we choose available contact and their country is not USA
            if(this.availableContactChosen === true) {
                fieldsToEnable.push("NO-VAT");
                this.availableContactChosen = false;
            }

            if(selectedFieldCode === "AVAIL-CONT"){
                let fields = ["NO-VAT"]
                fieldsToEnable = fieldsToEnable.filter(
                    field => !fields.includes(field)
                  );
                await this.selectionWhenAvailContact(selectedValue, invoiceFieldsToSet, invoiceFieldsToDisable, fieldValues, invoiceFieldsToReset, invoiceFieldsToEnable, invoiceSelectionFields, invoiceFieldDetails, mode, fieldsToDisable)
                await this.enableContactFields(fieldsToEnable, fieldDetails)
            }
            return { fieldDetails, fieldsToSet, fieldsToReset, fieldsToEnable, fieldsToDisable }
        }

        // not used anymore
        else if (mode === Constants.FORM_VIEW_MODE ) {
            let fieldsToEnable = [];
            let fieldsToSet = [];
            let fieldsToReset = [];
            let fieldsToDisable = [];
            // reset and disable the dropdown subtree
            this.depthFirstSearchOnFields(props.selectionFields, selectedFieldCode, fieldsToReset, fieldsToDisable, fieldValues);

            // filling and enabling the dependent dropdowns (for example., ['PR' and 'AVAIL-CONT'])
            if (props.selectionFields.hasOwnProperty(selectedFieldCode)) {
                for (let child of props.selectionFields[selectedFieldCode]) {
                    const fieldDetail = fieldDetails.find((fieldDetail) =>
                        fieldDetail.fieldCode === child
                    )
                    let actualParams = []
                    for (const fieldCode of fieldDetail.fieldValuesFunctionParams) {
                        actualParams.push(fieldValues.get(fieldCode))
                    }

                    fieldsToEnable.push(child);
                    let fieldValuesFunctionResult = await commonFunctions.callFunction.bind(this)(fieldDetail.fieldValuesFunction, actualParams);

                    if (Array.isArray(fieldValuesFunctionResult)) {
                        fieldDetail.fieldValues = fieldValuesFunctionResult;
                    }
                    else {
                        fieldDetail.fieldValues = fieldValuesFunctionResult.fieldValues;
                        fieldDetail.defaultValue = fieldValuesFunctionResult.defaultValue;
                    }
                }
            }

            for (let index in fieldDetails) {
                const fieldDetail = fieldDetails[index];
                const parentFields = fieldDetail.parentFields;

                if (fieldDetail.fieldType !== Constants.DROPDOWN_FIELD_NAME) {
                    for (const parent of parentFields) {
                        if (fieldsToReset.includes(parent)) {
                            fieldsToReset.push(fieldDetail.fieldCode);
                        }
                    }
                }
            }
            if (selectedFieldCode === props.rootField) {
                // when a root field is selected
                this.fieldsToFilter = props.selectionFields[selectedFieldCode];
                this.selectedRootValue = selectedValue;
                this.rootFieldCode = selectedFieldCode;
            } else if(!(selectedFieldCode === 'PO-NO-VW')){
                // non root dropdown is selected
                const clientDetails = JSON.parse(SessionStorageService.getSessionStorageValueBasedOnKeys(ProjectDetailsBlueprint.projectContactDetailsLocalStorageNamePrefix + this.selectedRootValue))
                const selectedFieldDetails = clientDetails[this.selectedRootValue][this.fieldToResponsePropertyNameMap.get(selectedFieldCode)]
                for (let fieldCode of this.fieldsToFilter) {
                    if (fieldCode !== selectedFieldCode) {
                        const cascadingStateMetadata = this.onDropdownClear(fieldDetails, props, fieldCode, mode, fieldValues);
                        for (let fieldCode of cascadingStateMetadata.fieldsToReset) {
                            fieldsToReset.push(fieldCode)
                        }
                    }
                }
                fieldsToReset = commonFunctions.removeDuplicatesAndSort(fieldsToReset)
                fieldsToReset.splice(fieldsToReset.indexOf(selectedFieldCode), 1)
                // filling non dropdown fields based on Project or Contact selection


                for (let fieldDetailsForSelectedValue of selectedFieldDetails) {

                    if (fieldDetailsForSelectedValue[this.valuePropInResponse.get(selectedFieldCode)] === selectedValue) {
                        this.selectedProjectCode = fieldDetailsForSelectedValue.projectCode

                        let namesOfFieldsToBeSet: Set<string> = new Set<string>()

                        for (const fieldName of Object.keys(fieldDetailsForSelectedValue)) {
                            namesOfFieldsToBeSet.add(fieldName)
                        }
                        // namesOfFieldsToBeSet.delete('purchaseOrderNo');    // we are filling this dropdown using the getPONo proc instead of from the Project table.

                        for (let index in fieldDetails) {
                            const fieldName = fieldDetails[index].fieldName
                            const fieldCode = fieldDetails[index].fieldCode
                            if (namesOfFieldsToBeSet.has(fieldName)) {
                                let fieldValueForSelectedSelection = fieldDetailsForSelectedValue[fieldName]

                                if (typeof fieldValueForSelectedSelection === 'boolean') fieldValueForSelectedSelection = commonFunctions.booleanToString(fieldValueForSelectedSelection)
                                fieldsToSet.push([fieldCode, fieldValueForSelectedSelection])
                                if (fieldsToReset.indexOf(fieldCode) !== -1)
                                    fieldsToReset.splice(fieldsToReset.indexOf(fieldCode), 1)
                            }


                        }
                    }
                }
                if (this.selectedProjectCode) {
                    this.projectDetailTables = await this.getProjectDetailsTables(this.selectedClientCode, this.selectedProjectCode)
                    this.setProjectDetailRows(this.projectDetailTables);
                    this.setProjectSelectedBoolean(false);
                }
            }

            return { fieldDetails, fieldsToEnable, fieldsToSet, fieldsToReset };
        }

        else if(mode === Constants.FORM_EDIT_MODE) {

            let selectionFields = props.selectionFields;
            let fieldsToEnable = [];
            let fieldsToSet = [];
            let fieldsToReset = [];
            let fieldsToDisable = [];

            let invoiceFieldsToEnable = [];
            let invoiceFieldsToSet = [];
            let invoiceFieldsToReset = [];
            let invoiceFieldsToDisable = [];

            let invoiceSelectionFields = ProjectDetailsBlueprint._projectDetailsEditModeBlueprint.props.invoiceSelectionFields;

            // reset and disable the dropdown subtree
            this.depthFirstSearchOnFields(selectionFields, selectedFieldCode, fieldsToReset, fieldsToDisable, fieldValues);

            fieldsToDisable = [];

            // filling and enabling the dependent dropdowns (for example., ['PR' and 'AVAIL-CONT'])
            if (selectionFields.hasOwnProperty(selectedFieldCode)) {
                for (let child of selectionFields[selectedFieldCode]) {
                    const fieldDetail = fieldDetails.find((fieldDetail) =>
                        fieldDetail.fieldCode === child
                    )
                    let actualParams = []
                    for (const fieldCode of fieldDetail.fieldValuesFunctionParams) {
                        actualParams.push(fieldValues.get(fieldCode))
                    }
                    fieldsToEnable.push(child);

                    let fieldValuesFunctionResult = await commonFunctions.callFunction.bind(this)(fieldDetail.fieldValuesFunction, actualParams);
                    if (Array.isArray(fieldValuesFunctionResult)) {
                        fieldDetail.fieldValues = fieldValuesFunctionResult;
                    }
                    else {
                        fieldDetail.fieldValues = fieldValuesFunctionResult.fieldValues;
                        fieldDetail.defaultValue = fieldValuesFunctionResult.defaultValue;
                    }
                }
            }
            for (let index in fieldDetails) {
                const fieldDetail = fieldDetails[index];
                const parentFields = fieldDetail.parentFields;

                if (fieldDetail.fieldType !== Constants.DROPDOWN_FIELD_NAME) {
                    for (const parent of parentFields) {
                        if (fieldsToReset.includes(parent)) {
                            fieldsToReset.push(fieldDetail.fieldCode);
                        }
                    }
                }
            }

            let invoiceFieldDetailsUpdate: any;
            invoiceFieldDetailsUpdate = _.cloneDeep(invoiceFieldDetails);
            for (let field of invoiceFieldDetails) {
                const fieldDetail = invoiceFieldDetails.find((fieldDetail) =>
                    fieldDetail.fieldCode === field.fieldCode
                )
                const index = invoiceFieldDetails.findIndex((fieldDetail) =>
                    fieldDetail.fieldCode === field.fieldCode
                )
                const fieldDetailCopy = {...fieldDetail};
                if (field.fieldValuesFunction != '') {
                    let actualParams = []
                    for (const fieldCode of fieldDetailCopy.fieldValuesFunctionParams) {
                        actualParams.push(fieldValues.get(fieldCode))
                    }
                    let fieldValuesFunctionResult = await commonFunctions.callFunction.bind(this)(fieldDetailCopy.fieldValuesFunction, actualParams);
                    if (Array.isArray(fieldValuesFunctionResult)) {
                        fieldDetailCopy.fieldValues = fieldValuesFunctionResult;
                    }
                    else {
                        fieldDetailCopy.fieldValues = fieldValuesFunctionResult.fieldValues;
                        fieldDetailCopy.defaultValue = fieldValuesFunctionResult.defaultValue;
                    }
                    invoiceFieldDetailsUpdate[index] = fieldDetailCopy;
                }
            }

            for (let index in invoiceFieldDetails) {
                const fieldDetail = invoiceFieldDetails[index];
                const parentFields = fieldDetail.parentFields;

                if (fieldDetail.fieldType !== Constants.DROPDOWN_FIELD_NAME) {
                    for (const parent of parentFields) {
                        if (invoiceFieldsToReset.includes(parent)) {
                            invoiceFieldsToReset.push(fieldDetail.fieldCode);
                        }
                    }
                }
            }

            if (selectedFieldCode === props.rootField) {
                // when a root field is selected
                this.fieldsToFilter = selectionFields[selectedFieldCode];
                this.selectedRootValue = selectedValue;
                this.rootFieldCode = selectedFieldCode;
                this.setProjectSelectedBoolean(true);
                this.setSelectedClient(selectedValue);
            }
            else if(selectedFieldCode === 'PR'){
                this.setSelectedProject(selectedValue)
                // non root dropdown is selectedconsole
                const clientDetails = JSON.parse(SessionStorageService.getSessionStorageValueBasedOnKeys(ProjectDetailsBlueprint.projectContactDetailsLocalStorageNamePrefix + this.selectedRootValue))
                const selectedFieldDetails = clientDetails[this.selectedRootValue][this.fieldToResponsePropertyNameMap.get(selectedFieldCode)]
                for (let field of fieldDetails) {
                    if (!commonFunctions.isNullOrUndefined(field.invoiceEnable)) {
                        Object.entries(field.invoiceEnable).forEach(async ([key, value]) => {
                            if (key == "ENABLED_ALWAYS"){

                                invoiceFieldsToEnable = invoiceFieldsToEnable.concat(value)
                            }
                            else if (field.fieldCode == "BC") {
                                for (let fieldDetailsForSelectedValue of selectedFieldDetails) {
                                    if (fieldDetailsForSelectedValue[this.valuePropInResponse.get(selectedFieldCode)] === selectedValue) {
                                        if(fieldDetailsForSelectedValue.billingCompany == key){
                                            invoiceFieldsToEnable = invoiceFieldsToEnable.concat(value)
                                        }
                                        else{
                                            invoiceFieldsToEnable = invoiceFieldsToEnable.filter(field => !(value as string[]).includes(field));
                                            invoiceFieldsToDisable = invoiceFieldsToDisable.concat(value);
                                            invoiceFieldsToReset = invoiceFieldsToReset.concat(value);
                                        }
                                    }
                                }
                            }
                            else if(field.fieldCode == "CN"){
                                for (let fieldDetailsForSelectedValue of selectedFieldDetails) {
                                    if (fieldDetailsForSelectedValue[this.valuePropInResponse.get(selectedFieldCode)] === selectedValue) {
                                        if(fieldDetailsForSelectedValue.country !== key){
                                            invoiceFieldsToEnable = invoiceFieldsToEnable.concat(value)
                                        }
                                        else{
                                            invoiceFieldsToEnable = invoiceFieldsToEnable.filter(field => !(value as string[]).includes(field));
                                            invoiceFieldsToDisable = invoiceFieldsToDisable.concat(value);
                                            invoiceFieldsToDisable.push("CL-VAT-NO");
                                            invoiceFieldsToReset.push("CL-VAT-NO");
                                            invoiceFieldsToReset = invoiceFieldsToReset.concat(value);
                                        }
                                    }
                                }
                            }
                        });
                    }
                }
                for (let fieldCode of this.fieldsToFilter) {

                    if (fieldCode !== selectedFieldCode) {
                        const cascadingStateMetadata = this.onDropdownClear(fieldDetails, props, fieldCode, mode, fieldValues);

                        for (let fieldCode of cascadingStateMetadata.fieldsToReset) {
                            fieldsToReset.push(fieldCode)
                        }
                    }
                }
                fieldsToReset = commonFunctions.removeDuplicatesAndSort(fieldsToReset)
                fieldsToReset.splice(fieldsToReset.indexOf(selectedFieldCode), 1)

                // filling non dropdown fields based on Project or Contact selection
                for (let fieldDetailsForSelectedValue of selectedFieldDetails) {
                    if (fieldDetailsForSelectedValue[this.valuePropInResponse.get(selectedFieldCode)] === selectedValue) {
                        this.selectedProjectCode = fieldDetailsForSelectedValue.projectCode
                        this.selectedCountryCode = fieldDetailsForSelectedValue.country

                        let namesOfFieldsToBeSet: Set<string> = new Set<string>()

                        for (const fieldName of Object.keys(fieldDetailsForSelectedValue)) {
                            namesOfFieldsToBeSet.add(fieldName)
                        }
                        for (let index in fieldDetails) {
                            const fieldName = fieldDetails[index].fieldName
                            const fieldCode = fieldDetails[index].fieldCode

                            if (namesOfFieldsToBeSet.has(fieldName)) {
                                let fieldValueForSelectedSelection = fieldDetailsForSelectedValue[fieldName];

                                if(fieldCode === 'NO-VAT') {
                                    if(typeof fieldValueForSelectedSelection === "string" || fieldValueForSelectedSelection === undefined) {
                                        fieldValueForSelectedSelection = commonFunctions.Stringtoboolean(fieldValueForSelectedSelection);
                                    }
                                }
                                fieldsToSet.push([fieldCode, fieldValueForSelectedSelection])

                                if (fieldsToReset.indexOf(fieldCode) !== -1)
                                    fieldsToReset.splice(fieldsToReset.indexOf(fieldCode), 1)
                            }
                        }
                    }
                }

                // fieldsToSet for Invoice
                for (let fieldDetailsForSelectedValue of selectedFieldDetails) {
                    if (fieldDetailsForSelectedValue[this.valuePropInResponse.get(selectedFieldCode)] === selectedValue) {
                        this.selectedProjectCode = fieldDetailsForSelectedValue.projectCode
                        this.selectedCountryCode = fieldDetailsForSelectedValue.country

                        let namesOfFieldsToBeSet: Set<string> = new Set<string>()

                        for (const fieldName of Object.keys(fieldDetailsForSelectedValue.invoiceDetails)) {
                            if(fieldName === 'purchaseOrderNo')
                                namesOfFieldsToBeSet.add("purchaseOrderNoViewMode")
                            else
                                namesOfFieldsToBeSet.add(fieldName)
                        }
                        for (let index in invoiceFieldDetails) {
                            const fieldName = invoiceFieldDetails[index].fieldName
                            const fieldCode = invoiceFieldDetails[index].fieldCode
                            if (namesOfFieldsToBeSet.has(fieldName)) {
                                let fieldValueForSelectedSelection;
                                if(fieldCode === 'PO-NO-VW'){
                                    fieldValueForSelectedSelection = fieldDetailsForSelectedValue.invoiceDetails['purchaseOrderNo']
                                }
                                else{
                                    fieldValueForSelectedSelection = fieldDetailsForSelectedValue.invoiceDetails[fieldName];
                                }
                                if(fieldCode === 'NO-VAT' || fieldCode === 'CHG') {
                                    if(typeof fieldValueForSelectedSelection === "string" || fieldValueForSelectedSelection === undefined) {
                                        fieldValueForSelectedSelection = commonFunctions.Stringtoboolean(fieldValueForSelectedSelection);
                                    }
                                    if(fieldCode === "NO-VAT" && fieldValueForSelectedSelection)
                                        invoiceFieldsToEnable = invoiceFieldsToEnable.concat(["CL-VAT-NO"])
                                }
                                if(fieldCode === 'SHB'){
                                    if(typeof fieldValueForSelectedSelection === "string" || fieldValueForSelectedSelection === undefined) {
                                        fieldValueForSelectedSelection = commonFunctions.Stringtoboolean(fieldValueForSelectedSelection);
                                    }
                                }
                                if(fieldCode === "TAX"){
                                    fieldValueForSelectedSelection = fieldValueForSelectedSelection*100
                                }
                                if(fieldCode === "PO-NO-VW"){
                                    if(fieldValueForSelectedSelection != '' && fieldValueForSelectedSelection != null)
                                        this.fillPrepaymentPO(fieldValueForSelectedSelection)
                                    fieldValueForSelectedSelection = this.purchaseOrderList.find(po => po.startsWith(fieldValueForSelectedSelection + ' -'));
                                }
                                invoiceFieldsToSet.push([fieldCode, fieldValueForSelectedSelection])

                                if (invoiceFieldsToReset.indexOf(fieldCode) !== -1)
                                    invoiceFieldsToReset.splice(invoiceFieldsToReset.indexOf(fieldCode), 1)
                            }
                        }
                    }
                }
                const updatedFields = await this.fillTypeOfService(invoiceFieldsToSet, fieldDetails, invoiceFieldDetailsUpdate);
                invoiceFieldsToSet = updatedFields[0];
                invoiceFieldDetailsUpdate = updatedFields[2];
                if (this.selectedProjectCode) {
                    const fieldsToRemove = ['PPP', 'PPD', 'PPPT', 'MIL-RA'];
                    invoiceFieldsToEnable = invoiceFieldsToEnable.filter(field => !fieldsToRemove.includes(field));

                    await this.setInStore(invoiceSelectionFields, invoiceFieldsToEnable, invoiceFieldsToDisable, invoiceFieldsToSet, invoiceFieldsToReset, invoiceFieldDetailsUpdate)

                }

                this.enableContactFields(fieldsToEnable, fieldDetails);
            }

            else if(selectedFieldCode === 'BC'){
                await this.selectionWhenBillingCompnany(invoiceFieldsToEnable, fieldValues, invoiceFieldsToReset, invoiceFieldsToDisable, invoiceSelectionFields, invoiceFieldsToSet, invoiceFieldDetailsUpdate)
            }

            else if(selectedFieldCode === 'CN'){
                await this.selectionWhenCountry(invoiceFieldsToDisable, invoiceFieldsToReset, fieldValues, invoiceFieldsToEnable, invoiceSelectionFields, invoiceFieldsToSet, invoiceFieldDetailsUpdate)
            }

            else if(selectedFieldCode === 'ST'){
                await this.selectionWhenState(fieldValues, invoiceFieldsToSet);
            }

            //LIKE PO NO
            else if(selectedFieldCode === "AVAIL-CONT"){
                await this.selectionWhenAvailContact(selectedValue, invoiceFieldsToSet, invoiceFieldsToDisable, fieldValues, invoiceFieldsToReset, invoiceFieldsToEnable, invoiceSelectionFields, invoiceFieldDetailsUpdate, mode, fieldsToDisable)
                await this.enableContactFields(fieldsToEnable, fieldDetails);
            }

            for (let index in fieldDetailsCopy) {
                const candidateFieldCode = fieldDetailsCopy[index].fieldCode;
                let conditionsToEnable = fieldDetailsCopy[index].enabledFor;
                let conditionsToDisable = fieldDetailsCopy[index].disabledFor;

                if (conditionsToEnable) {
                    for (const [key, value] of Object.entries(conditionsToEnable)) {

                       let filterValues = this.getFilterValues(conditionsToEnable, key);
                       for(const pair of fieldsToSet){
                            if(pair[0] == key){
                                if(filterValues.includes(pair[1])){
                                    fieldsToEnable.push(candidateFieldCode);
                                }
                            }
                        }
                    }
                    await this.checkValidityAndEnableField(selectedFieldCode, candidateFieldCode, selectedValue, conditionsToEnable, fieldValues, fieldsToEnable, fieldsToDisable, fieldDetails[index], fieldsToReset, fieldDetails, props, mode);

                }

                if (conditionsToDisable) {
                    for (let fieldCode of Object.keys(conditionsToDisable)) {
                        if (fieldCode === selectedFieldCode) {
                            const filterValues = this.getFilterValues(conditionsToDisable, fieldCode);

                            if (filterValues.includes(selectedValue)) {
                                this.disableAndResetFields(candidateFieldCode, fieldsToDisable, fieldsToReset, fieldDetails, fieldDetails[index], props, mode, fieldValues);
                            }
                            break;
                        }
                    }
                }
            }

            fieldsToSet = this.fillFields(fieldDetails, selectedFieldCode, fieldValues) || [];

            const updatedFields = await this.fillTypeOfService(fieldsToSet, fieldDetails, invoiceFieldDetails);
            fieldsToSet = updatedFields[0];
            fieldDetails = updatedFields[1];
            return { fieldDetails, fieldsToEnable, fieldsToSet, fieldsToReset, fieldsToDisable };
        }
    }

    onDropdownClear(fieldDetails, props, clearedFieldCode: string, mode: string, fieldValues: Map<string, any>) {
        this.dropdownValues.delete(clearedFieldCode)
        if (mode === Constants.FORM_ADD_MODE || mode === Constants.FORM_EDIT_MODE) {
            let fieldsToReset = []
            let fieldsToDisable = []
            let fieldDetailsCopy = fieldDetails

            for (let index in fieldDetailsCopy) {
                const candidateFieldCode = fieldDetailsCopy[index].fieldCode
                let conditionsToEnable = fieldDetailsCopy[index].enabledFor
                let parentFields = fieldDetailsCopy[index].parentFields

                if (conditionsToEnable) {
                    for (let fieldCode of Object.keys(conditionsToEnable)) {
                        if (fieldCode === clearedFieldCode) {
                            this.disableAndResetFields(candidateFieldCode, fieldsToDisable, fieldsToReset, fieldDetails, fieldDetails[index], props, mode, fieldValues);
                        }
                    }
                }

                for (let fieldCode of parentFields) {
                    if (fieldCode === clearedFieldCode) {
                        // reset field
                        let formMetadata = this.onDropdownClear(fieldDetails, props, candidateFieldCode, mode, fieldValues);
                        fieldDetails = formMetadata.fieldDetails;
                        if (fieldDetailsCopy[index].fieldValuesFunction !== '') {
                            fieldDetails[index].fieldValues = '';
                        }
                        fieldsToReset.push(candidateFieldCode);
                        for (const field of formMetadata.fieldsToDisable) fieldsToDisable.push(field);
                        for (const field of formMetadata.fieldsToReset) fieldsToReset.push(field);
                    }
                }
            }
            return { fieldDetails, fieldsToReset, fieldsToDisable };
        }
        this.setProjectSelectedBoolean(true);
        let fieldsToReset = []
        let fieldsToDisable = []
        let fieldsToClear = ['PO-NO-VW']

        this.setViewProjectOtherFormsDisabled(true);
        this.depthFirstSearchOnFields(props.selectionFields, clearedFieldCode, fieldsToReset, fieldsToDisable, fieldValues);
        fieldsToReset.push(clearedFieldCode);
        for (let index in fieldDetails) {
            const fieldDetail = fieldDetails[index];
            const parentFields = fieldDetail.parentFields;
            if (fieldDetail.fieldType !== Constants.DROPDOWN_FIELD_NAME) {
                for (const parent of parentFields) {
                    if (fieldsToReset.includes(parent)) {
                        fieldsToReset.push(fieldDetail.fieldCode);
                    }
                }
            }
        }
        return { fieldDetails, fieldsToDisable, fieldsToReset, fieldsToClear  };
    }
}
