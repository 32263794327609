import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class SessionStorageService {

  constructor() { }

  //Set session Storage Value
  public static setSessionStorageValueBasedOnKey(
    keyName: string,
    value: string,
    expirationTimeInHours: number = 2
  ): void {
    try {
      // Set the main data
      sessionStorage.setItem(keyName, value);

      // Set the expiration time in a separate key
      const expirationTimestamp =
        new Date().getTime() + expirationTimeInHours * 60 * 60 * 1000; // Expiration in milliseconds
      sessionStorage.setItem(`${keyName}_expiration`, expirationTimestamp.toString());
    } catch (error) {
      console.error('Error setting session storage value:', error);
    }
  }



  //Get session Storage Value
  public static getSessionStorageValueBasedOnKeys(keyName: string): string | null {
    try {
      const expirationTimestamp = sessionStorage.getItem(`${keyName}_expiration`);
      const currentTimestamp = new Date().getTime();

      // Check if expiration exists and if the item has expired
      if (expirationTimestamp && currentTimestamp > parseInt(expirationTimestamp, 10)) {
        // Item has expired; remove both value and expiration
        sessionStorage.removeItem(keyName);
        sessionStorage.removeItem(`${keyName}_expiration`);
        return null;
      }

      // Return the value if not expired
      return sessionStorage.getItem(keyName);
    } catch (error) {
      console.error('Error getting session storage value:', error);
    }
    return null;
  }



  //Remove Session Storage Value
  public static removeSessionStorageValueBasedOnKeys(keyName: string): void {
    try {
      sessionStorage.removeItem(keyName);
      sessionStorage.removeItem(`${keyName}_expiration`);
    } catch (error) {
      console.error('Error removing session storage value:', error);
    }
  }
}
