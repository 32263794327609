import { NgModule, forwardRef } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MipsRoutingModule } from './mips.routing.module';
import { BaselayoutComponent } from './baselayout/baselayout.component';
import { FuseLoadingBarModule } from "../../../@fuse/components/loading-bar/loading-bar.module";
import { FuseNavigationModule } from "../../../@fuse/components/navigation/navigation.module";
import { FuseFullscreenModule } from "../../../@fuse/components/fullscreen/fullscreen.module";
import { MatIconModule } from '@angular/material/icon';
import { SharedModule } from 'app/shared/shared.module';
import { NavItemsModule } from '../nav-items/nav-items.module';
import { FlagModule } from './actuals/flag/flag.module';
import { MatToolbarModule } from '@angular/material/toolbar';
import { PipesModule } from './common/pipes/pipes.module';
import { FormsModule, NG_VALUE_ACCESSOR, ReactiveFormsModule } from '@angular/forms';
import { MatNativeDateModule } from '@angular/material/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatInputModule } from '@angular/material/input';
import { MatDialogModule } from '@angular/material/dialog';
import { AgGridModule } from 'ag-grid-angular';
import { MatCardModule } from '@angular/material/card';
import { TimeDicerModule } from './actuals/time-dicer/time-dicer.module';
import { ReadOnlyModeComponent } from './actuals/read-only-mode/read-only-mode.component';
import { MipsRouteGuard } from './common/utilities/mips.routeguard';
import { TimeEntryModule } from './actuals/time-entry/time-entry.module';
import { ExpenseEntryModule } from './actuals/expense-entry/expense-entry.module';
import { ImpersonateComponent } from './actuals/impersonate/impersonate.component';
import { EntryModule } from './actuals/entry/entry.module';
import { ReportEmbedModule } from './report-embed/report-embed.module';
import { ProjectDetailsModule } from './project-details/project-details.module';
import { FormModule } from './form-baselayout/form.module';
import { AddProjectPageModule } from './add-project-page/add-project-page.module';
import { GridDatePickerComponent } from './common/custom-components/ag-grid-datepicker-component';
// import { AddClientComponent } from './add-client/add-client.component';
// import { AddClientModule } from './add-client/add-client.module';
import { DirectivesModule } from './common/directives/directives.module';
import { AddClientComponent } from './add-client/add-client.component';
import { AddClientModule } from './add-client/add-client.module';
import { TimeEntryHeaderModule } from './planning/time-entry/time-entry-header/time-entry-header.module';
import { TimeEntryForPlanningModule } from './planning/time-entry/time-entry-for-planning.module';
import { FinanceExpenseComponent } from './finance-expense/finance-expense.component';
import { FinanceExpenseModule } from './finance-expense/finance-expense.module';


@NgModule({
    declarations: [
        BaselayoutComponent,
        ReadOnlyModeComponent,
        ImpersonateComponent,
        GridDatePickerComponent
    ],
    imports: [
        MipsRoutingModule,
        CommonModule,
        FuseLoadingBarModule,
        FuseNavigationModule,
        FuseFullscreenModule,
        MatIconModule,
        SharedModule,
        NavItemsModule,
        FlagModule,
        MatToolbarModule,
        ReactiveFormsModule,
        MatNativeDateModule,
        MatFormFieldModule,
        MatDatepickerModule,
        MatSlideToggleModule,
        MatDialogModule,
        MatInputModule,
        AgGridModule,
        MatCardModule,
        PipesModule,
        TimeDicerModule,
        EntryModule,
        TimeEntryModule,
        ExpenseEntryModule,
        DirectivesModule,
        //ReportEmbedModule,
        ProjectDetailsModule,
        AddProjectPageModule,
        // FinanceExpenseModule,
        FormsModule,
        AddClientModule,
        TimeEntryHeaderModule,
        TimeEntryForPlanningModule
    ],
    providers: [
        MatDatepickerModule,
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => 'MyInputField'),
            multi: true,
          },
        PipesModule,
        MipsRouteGuard
    ],
    exports: [ ]
})
export class MipsModule { }
