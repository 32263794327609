import {DateUtility} from '../utilities/date.utility';

export class ExpenseEntryModel {
    ExpenseID: number = null;
    TDate: string =  DateUtility.formatStringfromDate(DateUtility.createDateType());
    Invoice_date: string =  DateUtility.formatStringfromDate(DateUtility.createDateType());
    Invoice_id: number = 0;
    Employee: string = '';
    Client: string = '';
    ClientName: string = '';
    Project: string = '';
    Project_Description: string = '';
    Period_Month: number = 0;
    Description: string = '';
    Task_id: number = 0;
    Details:  number = null;
    Task_Name: string = '';
    Task_Description: string ='';
    Project_Sales_Tax: number = 0;
    AmountSpent: number = 0;
    Amount_Native: number;
    Amount_Constant: number = 0;
    PaymentMethod: string = '';
    ExpenseType: string = '';
    Currency_From: string = '';
    Currency_symbol: string = '';
    Currency_symbol_short: string = '';
    Currency_To: string = '';
    Currency_symbol_short_to: string = '';
    Version_id: number = null;
    Company_id: number = null;
    Mileage: number = 0;
    MileageRate: number = 0;
    State_id: number = null;
    State_Name: string = '';
    State_Code: string = '';
    LoginName: string = '';
    status = 'clean';
    Mode: string = null;
    isVatApplicable : boolean = false;
    Tax_Amount: number = null;



}
