import { HttpClient, HttpParams } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { ApiheaderService } from "./apiheader.service";
import { URLPaths } from "../utilities/urlPaths.config";
import { firstValueFrom } from "rxjs";
import { commonFunctions } from "../classes/common.class";
import { LocalStorageService } from "./local-storage.service";
import { SessionStorageService } from "./session-storage.service";

@Injectable({
    providedIn: 'root'
})
export class CommonService {

    constructor(private _http: HttpClient) { }

    async getAllClients(page) {
        let clients: any = [];
        clients = JSON.parse(LocalStorageService.getLocalStorageValueBasedOnKeys('clients_' + page))
        if (commonFunctions.isNullOrUndefined(clients)) {
            let params = new HttpParams();
            params = params.append('page', page);
            clients = await firstValueFrom(this._http.get<any>(URLPaths.URL_CLIENT_DETAILS, { headers: ApiheaderService.getHeaders(), params: params }))
            LocalStorageService.setLocalStorageValueBasedOnKey('clients_' + page, JSON.stringify(clients));
            return clients;
        }
        else {
            return clients;
        }
    }

    async getClientswithInetrnalCode(page) {
        let clients: any = [];
        clients = JSON.parse(LocalStorageService.getLocalStorageValueBasedOnKeys('clients_' + page))
        if (commonFunctions.isNullOrUndefined(clients)) {
            let params = new HttpParams();
            params = params.append('page', page);
            clients = await firstValueFrom(this._http.get<any>(URLPaths.URL_GET_CLENTS_FOR_ADD_CLIENT, { headers: ApiheaderService.getHeaders(), params: params }))
            LocalStorageService.setLocalStorageValueBasedOnKey('clients_' + page, JSON.stringify(clients));

            return clients;
        }

        else {
            return clients;
        }
    }

    async getAllCompanies(page) {
        let companies: any = [];
        companies = JSON.parse(LocalStorageService.getLocalStorageValueBasedOnKeys('companies_' + page))
        if (commonFunctions.isNullOrUndefined(companies)) {
            let params = new HttpParams();
            params = params.append('page', page);
            companies = await firstValueFrom(this._http.get<any>(URLPaths.URL_COMPANY_DETAILS, { headers: ApiheaderService.getHeaders(), params: params }))
            LocalStorageService.setLocalStorageValueBasedOnKey('companies_' + page, JSON.stringify(companies));
            return companies;
        }
        else {
            return companies;
        }
    }

    async ProjectDetailTables(clientCode: string, projectCode: string) {
        let projectDetailTables: any = [];
            let params = new HttpParams();
            params = params.append('client', clientCode).append('project', projectCode);
            projectDetailTables = await firstValueFrom(this._http.get<any>(URLPaths.URL_PROJECT_DETAIL_TABLES, {headers: ApiheaderService.getHeaders(), params: params}))
            return projectDetailTables;

    }

    // async getAllProjects() {
    //     let projects: any = [];
    //     let returnResult: any;
    //     projects = JSON.parse(LocalStorageService.getLocalStorageValueBasedOnKeys('projects'))
    //     if (commonFunctions.isNullOrUndefined(projects)) {
    //         // projects = await firstValueFrom(this._http.get<any>(URLPaths.URL_PROJECT_DETAILS, { headers: ApiheaderService.getHeaders() }))
    //         returnResult = await firstValueFrom(this._http.get<any>(URLPaths.URL_PROJECT_AND_CONTACT_DETAILS, {headers: ApiheaderService.getHeaders()}))
    //         LocalStorageService.setLocalStorageValueBasedOnKey('projects', JSON.stringify(projects));
    //         return projects;
    //     } else {
    //         return projects
    //     }
    //   }

    //   async getAllContacts() {
    //     let contacts: any = [];
    //     contacts = JSON.parse(LocalStorageService.getLocalStorageValueBasedOnKeys('contacts'))
    //     if (commonFunctions.isNullOrUndefined(contacts)) {
    //         contacts = await firstValueFrom(this._http.get<any>(URLPaths.URL_CONTACT_DETAILS, { headers: ApiheaderService.getHeaders() }))
    //         LocalStorageService.setLocalStorageValueBasedOnKey('contacts', JSON.stringify(contacts));
    //         return contacts;
    //     } else {
    //         return contacts
    //     }
    //   }

    async getFilteredProjectsAndContacts(client: string) {
        let projectsAndContacts = [];
        projectsAndContacts = JSON.parse(SessionStorageService.getSessionStorageValueBasedOnKeys('projects-contacts_' + client))
        if(commonFunctions.isNullOrUndefined(projectsAndContacts)){
            let params = new HttpParams();
            params = params.append('client', client);
            projectsAndContacts = await firstValueFrom(this._http.get<any>(URLPaths.URL_PROJECT_AND_CONTACT_DETAILS, {headers: ApiheaderService.getHeaders(), params: params}));
            SessionStorageService.setSessionStorageValueBasedOnKey('projects-contacts_' + client, JSON.stringify(projectsAndContacts));
            return projectsAndContacts;
        }
        else {
            return projectsAndContacts;
        }
    }

    async getSalesTaxDetails() {
        let salesTaxDetails: any = [];
        salesTaxDetails = JSON.parse(LocalStorageService.getLocalStorageValueBasedOnKeys('sales_tax_details'))
        if (commonFunctions.isNullOrUndefined(salesTaxDetails)) {
            salesTaxDetails = await firstValueFrom(this._http.get<any>(URLPaths.URL_SALES_TAX_DETAILS, { headers: ApiheaderService.getHeaders() }))
            LocalStorageService.setLocalStorageValueBasedOnKey('sales_tax_details', JSON.stringify(salesTaxDetails));
            return salesTaxDetails;
        } else {
            return salesTaxDetails
        }
    }

    async getProjectTypes() {
        let projectTypes: any[] = JSON.parse(LocalStorageService.getLocalStorageValueBasedOnKeys('project_types'))
        if (commonFunctions.isNullOrUndefined(projectTypes)) {
            projectTypes = await firstValueFrom(this._http.get<any>(URLPaths.URL_PROJECT_TYPES, { headers: ApiheaderService.getHeaders() }))
            LocalStorageService.setLocalStorageValueBasedOnKey('project_types', JSON.stringify(projectTypes));
        }
        return projectTypes
    }

    async getInvoiceFormats() {
        let invoiceFormats: any[] = JSON.parse(LocalStorageService.getLocalStorageValueBasedOnKeys('invoice_formats'))
        if (commonFunctions.isNullOrUndefined(invoiceFormats)) {
            invoiceFormats = await firstValueFrom(this._http.get<any>(URLPaths.URL_INVOICE_FORMATS, { headers: ApiheaderService.getHeaders() }))
            LocalStorageService.setLocalStorageValueBasedOnKey('invoice_formats', JSON.stringify(invoiceFormats));
        }
        return invoiceFormats
    }

    async getPromptPaymentPeriodTypes() {
        let periodTypes: any[] = JSON.parse(LocalStorageService.getLocalStorageValueBasedOnKeys('prompt_payment_period_types'))
        if (commonFunctions.isNullOrUndefined(periodTypes)) {
            periodTypes = await firstValueFrom(this._http.get<any>(URLPaths.URL_PROMPT_PAYMENT_PERIOD_TYPES, { headers: ApiheaderService.getHeaders() }))
            LocalStorageService.setLocalStorageValueBasedOnKey('prompt_payment_period_types', JSON.stringify(periodTypes));
        }
        return periodTypes
    }

    async getTypesOfService() {
        let typesOfService: string[] = JSON.parse(LocalStorageService.getLocalStorageValueBasedOnKeys('types_of_service'))
        if (commonFunctions.isNullOrUndefined(typesOfService)) {
            typesOfService = await firstValueFrom(this._http.get<any>(URLPaths.URL_TYPES_OF_SERVICE, { headers: ApiheaderService.getHeaders() }))
            LocalStorageService.setLocalStorageValueBasedOnKey('types_of_service', JSON.stringify(typesOfService));
        }
        return typesOfService
    }

    async getPurchaseOrderDetails(client: string, project: string) {
        let purchaseOrderDetails: string[] = JSON.parse(SessionStorageService.getSessionStorageValueBasedOnKeys('purchase_order_details_' + client + '_' + project));

        if (commonFunctions.isNullOrUndefined(purchaseOrderDetails)) {
            let params = new HttpParams();
            params = params.append('client', client);
            params = params.append('project', project);
            purchaseOrderDetails = await firstValueFrom(this._http.get<any>(URLPaths.URL_PURCHASE_ORDER_NOS, { headers: ApiheaderService.getHeaders(), params: params }))

            SessionStorageService.setSessionStorageValueBasedOnKey('purchase_order_details_' + client + '_' + project, JSON.stringify(purchaseOrderDetails));
        }
        return purchaseOrderDetails;
    }

    async getPeriodMonths() {
        let periodMonths: any[] = JSON.parse(LocalStorageService.getLocalStorageValueBasedOnKeys('period_months'));

        if (commonFunctions.isNullOrUndefined(periodMonths)) {
            periodMonths = await firstValueFrom(this._http.get<any>(URLPaths.URL_PERIOD_MONTHS, { headers: ApiheaderService.getHeaders(), }))

            LocalStorageService.setLocalStorageValueBasedOnKey('period_months', JSON.stringify(periodMonths));
        }
        return periodMonths;
    }

     async getProjectThemes() {
        let projectThemes: any[] = JSON.parse(LocalStorageService.getLocalStorageValueBasedOnKeys('project_themes'));

        if (commonFunctions.isNullOrUndefined(projectThemes)) {
            projectThemes = await firstValueFrom(this._http.get<any>(URLPaths.URL_PROJECT_THEMES, { headers: ApiheaderService.getHeaders(), }));

            LocalStorageService.setLocalStorageValueBasedOnKey('project_themes', JSON.stringify(projectThemes));
        }
        return projectThemes;
    }

    async getCountryDetails() {
        let countryDetails: any[] = JSON.parse(LocalStorageService.getLocalStorageValueBasedOnKeys('country_details'));

        if (commonFunctions.isNullOrUndefined(countryDetails)) {
            countryDetails = await firstValueFrom(this._http.get<any>(URLPaths.URL_COUNTRY_DETAILS, { headers: ApiheaderService.getHeaders(), }));

            LocalStorageService.setLocalStorageValueBasedOnKey('country_details', JSON.stringify(countryDetails));
        }
        return countryDetails;
    }

    async getPromptPaymentPeriodTypeId(promptPaymentPeriodType: string): Promise<number> {
        if (commonFunctions.isNullOrUndefined(LocalStorageService.getLocalStorageValueBasedOnKeys('prompt_payment_period_types'))) await this.getPromptPaymentPeriodTypes();
        const promptPaymentPeriodTypes = JSON.parse(LocalStorageService.getLocalStorageValueBasedOnKeys('prompt_payment_period_types'));
        for (const promptPaymentPeriodTypeObj of promptPaymentPeriodTypes) {
            if (promptPaymentPeriodTypeObj.periodTypeDescription === promptPaymentPeriodType) return promptPaymentPeriodTypeObj.periodTypeId;
        }
        return -1;     // for easier debugging
    }

    async getCompanyId(company: string): Promise<number> {
        if (commonFunctions.isNullOrUndefined(LocalStorageService.getLocalStorageValueBasedOnKeys('companies_projectDetails'))) await this.getAllCompanies('projectDetails');
        const companies = JSON.parse(LocalStorageService.getLocalStorageValueBasedOnKeys('companies_projectDetails'));
        for (const companyObj of companies) {
            if (companyObj.Company_Name === company) return companyObj.Company_id;
        }
        return -1;
    }

    async getProjectTypeId(projectType: string): Promise<number> {
        if (commonFunctions.isNullOrUndefined(LocalStorageService.getLocalStorageValueBasedOnKeys('project_types'))) await this.getProjectTypes();
        const projectTypes = JSON.parse(LocalStorageService.getLocalStorageValueBasedOnKeys('project_types'));
        for (const projectTypeObj of projectTypes) {
            if (projectTypeObj.projectType === projectType) return projectTypeObj.projectTypeId;
        }
        return -1;
    }

    async getInvoiceFormatId(invoiceFormat: string): Promise<number> {
        if (commonFunctions.isNullOrUndefined(LocalStorageService.getLocalStorageValueBasedOnKeys('invoice_formats'))) await this.getInvoiceFormats();
        const invoiceFormats = JSON.parse(LocalStorageService.getLocalStorageValueBasedOnKeys('invoice_formats'));
        for (const invoiceFormatObj of invoiceFormats) {
            if (invoiceFormatObj.formatDescription === invoiceFormat) return invoiceFormatObj.formatTypeId;
        }
        return -1;
    }

    async getPeriodMonthId(periodMonth: string): Promise<number> {
        if (commonFunctions.isNullOrUndefined(LocalStorageService.getLocalStorageValueBasedOnKeys('period_months'))) await this.getPeriodMonths();
        const periodMonths = JSON.parse(LocalStorageService.getLocalStorageValueBasedOnKeys('period_months'));
        for (const periodMonthObj of periodMonths) {
            if (periodMonthObj.periodMonth == periodMonth) return periodMonthObj.periodKey;
        }
        return -1;
    }

    async getProjectThemeCode(theme: string): Promise<string> {
        if (commonFunctions.isNullOrUndefined(LocalStorageService.getLocalStorageValueBasedOnKeys('project_themes'))) await this.getProjectThemes();
        const projectThemes = JSON.parse(LocalStorageService.getLocalStorageValueBasedOnKeys('project_themes'));
        for (const projectThemeObj of projectThemes) {
            if (projectThemeObj.themeDesc === theme) return projectThemeObj.themeCode;
        }
        return '';
    }

    async getCurrencyCode(country: string): Promise<string> {
        if (commonFunctions.isNullOrUndefined(LocalStorageService.getLocalStorageValueBasedOnKeys('country_details'))) await this.getCountryDetails();
        const countryDetails: any[] = JSON.parse(LocalStorageService.getLocalStorageValueBasedOnKeys('country_details'));
        const requiredCountry = countryDetails.filter((countryObj) => countryObj.countryDescription === country)[0];
        return requiredCountry.currency.currency_code;
    }

    async getStateId(country: string, state: string): Promise<number> {
        if (commonFunctions.isNullOrUndefined(LocalStorageService.getLocalStorageValueBasedOnKeys('country_details'))) await this.getCountryDetails();
        const countryDetails: any[] = JSON.parse(LocalStorageService.getLocalStorageValueBasedOnKeys('country_details'));
        const requiredCountry = countryDetails.filter((countryObj) => countryObj.countryDescription === country)[0];
        const states: any[] = requiredCountry.states;
        let stateId: number = null;
        states.filter((stateObj) => {
            if (stateObj.stateName === state) {
                stateId = stateObj.stateId;
            }
        });
        if(stateId == null){
            stateId = 0;
        }
        return stateId;
    }

    async getCountryId(country: string): Promise<number> {
        if (commonFunctions.isNullOrUndefined(LocalStorageService.getLocalStorageValueBasedOnKeys('country_details'))) await this.getCountryDetails();
        const countryDetails: any[] = JSON.parse(LocalStorageService.getLocalStorageValueBasedOnKeys('country_details'));
        const requiredCountry = countryDetails.filter((countryObj) => countryObj.countryDescription === country)[0];
        return requiredCountry.countryId;
    }
}
