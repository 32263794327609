import { Injectable } from '@angular/core';
import { navItems } from './nav.items';
import { Store } from '@ngrx/store';

@Injectable({
  providedIn: 'root'
})
export class NavbarService {

  constructor(private store: Store) {
    // Initialize store immediately in constructor
    navItems.initializeStore(this.store);
  }

  // returns data on the navItems
  getNavItemsBlueprintData(){
    return navItems.navItemBlueprint;
  }
  // returns data for mobile nav bar
  getNavItemsMobileBlueprintData(){
    return navItems.navItemBlueprintMobile;
  }
}
