import { Component, OnInit } from '@angular/core';
import { GridApi, GridOptions, ColumnApi } from 'ag-grid-community';
import { DatePipe } from '@angular/common';
import { FinanceExpenseService } from './finance-expense.service';
import { Store } from '@ngrx/store';
import { getCurrentUser } from 'app/core/store/currentUser/user.selectors';
import { FormBuilder } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { DateUtility } from '../common/utilities/date.utility';
import { ConsultantModel } from '../common/models/consultant.model';
import { SnackbarService } from '../common/services/snackbar.service';
import { ConsultantDetailsService } from '../common/services/consultant-details.service';
import { CurrencyModel } from '../common/models/currency.model';
import { EntryDialogComponent } from '../actuals/entry/entry-dialog/entry-dialog.component';
import { Constants } from '../common/utilities/constants.config';
import { ExpenseEntryModel } from '../common/models/ExpenseEntry.model';
import { finalize } from 'rxjs';

@Component({
  selector: 'app-finance-expense',
  templateUrl: './finance-expense.component.html',
  styleUrls: ['./finance-expense.component.scss']
})
export class FinanceExpenseComponent implements OnInit {
  gridOptions: GridOptions = {};
  agGridAPI: GridApi;
  agGridColumnAPI: ColumnApi;
  defaultColDef: any;
  rowData: any = [];
  currentUser: ConsultantModel;
  currencies: any[] = [];
  selectedCurrency: CurrencyModel[] = [];
  consultantList: ConsultantModel[] = [];
  selectedConsultant: ConsultantModel[] = [];
  isLoading: boolean = false;
  modifiedEntries: ExpenseEntryModel[] = [];
  hasModifiedEntries: boolean = false;
  expenseFinanceEntryIsUnsavedChangesPresent: boolean;

  dateRange = this.formBuilder.group({
    start: [null as Date | null],
    end: [null as Date | null]
  });

  constructor(
    private datePipe: DatePipe,
    private financeExpenseService: FinanceExpenseService,
    private store: Store,
    private formBuilder: FormBuilder,
    private dialog: MatDialog,
    private consultantDetailsService: ConsultantDetailsService,
    private snackbarService: SnackbarService
  ) {
    this.store.select(getCurrentUser).subscribe((data) => {
      this.currentUser = data;
    });
    this.financeExpenseService.setExpenseFinanceEntryIsUnsavedChangesPresent(false);
    this.financeExpenseService.expenseFinanceEntryIsUnsavedChangesPresent.subscribe((data)=>{
      this.expenseFinanceEntryIsUnsavedChangesPresent = data;
  })
  }

  ngOnInit() {
    this.setupGridOptions();
    this.loadConsultants();
    this.initializeDateRange();
    this.loadCurrencies();
    this.financeExpenseService.setExpenseFinanceEntryIsUnsavedChangesPresent(false);
    this.financeExpenseService.expenseFinanceEntryIsUnsavedChangesPresent.subscribe((data)=>{
      this.expenseFinanceEntryIsUnsavedChangesPresent = data;
  })
  }
  loadCurrencies(){
    this.financeExpenseService.getCurrencies().subscribe(
      currencies => {
        this.currencies = currencies;
      },
      error => {
        this.snackbarService.showSnackBar('Error loading currencies', 'close', 'failure');
      }
    );

  }

  private setupGridOptions() {
    this.defaultColDef = {
      width: 100,
      filter: true,
      resizable: true,
      sortable: true
    };

    this.gridOptions = {
      columnDefs: [
        {
          headerName: 'Employee',
          field: 'Employee',
          width: 250
        },
        {
          headerName: 'Client',
          field: 'Client',
          valueGetter: (params) => {
            if (!params.data) return '';
            return `${params.data.Client || ''} ${params.data.ClientName ? '- ' + params.data.ClientName : ''}`.trim();
          },
          width: 250
        },
        {
          headerName: 'Project',
          field: 'Project',
          valueGetter: (params) => {
            if (!params.data) return '';
            return `${params.data.Project || ''} ${params.data.Project_Description ? '- ' + params.data.Project_Description : ''}`.trim();
          },
          width: 300
        },
        {
          headerName: 'Expense Type',
          field: 'ExpenseType',
          width: 270
        },
        {
          headerName: 'Description',
          field: 'Description',
          width: 350,
          editable: true
        },
        {
          headerName: 'Details',
          field: 'Details',
          width: 300,
          editable: true
        },
        {
          headerName: 'Amount',
          field: 'AmountSpent',
          type: 'numericColumn',
          editable: true,
          valueFormatter: (params) => {
            if (!params.data) return '';
            const value = params.value ? Number(params.value) : 0;
            return `${params.data.Currency_symbol_short || ''} ${value.toFixed(2)}`;
          },
          width: 200
        },
        {
          headerName: 'Tax Amount',
          field: 'Tax_Amount',
          // hide: true,
          enablePivot: true,
          enableRowGroup: true,
          enableValue: true,
          valueGetter: (params) => {
            if (!params.node.group) {
                // Return 0 if Tax_Amount is null, otherwise return the actual value
                return params.data.Tax_Amount === null ? 0 : parseFloat(params.data.Tax_Amount);
            }
            return null;
        },
        valueFormatter: (params) => {
            if (!params.node.group) {
                // Format the value with the currency symbol and 2 decimal places
                const value = params.value === null ? 0 : params.value;
                return params.node.data.Currency_symbol_short + ' ' + value.toFixed(2);
            }
            return null;
        },
        width: 180
      },
        {
          headerName: 'Cur',
          field: 'Currency_From',
          width: 130
        },
        {
          headerName: 'Payment Method',
          field: 'PaymentMethod',
          width: 190
        },
        {
          headerName: 'Date',
          field: 'TDate',
          valueFormatter: (params) => {
            if (!params.value) return '';
            return this.datePipe.transform(params.value, 'dd MMM yyyy');
          },
          width: 220,
          sort: 'asc'
        },
        {
          headerName: 'Invoice Date',
          field: 'Invoice_date',
          valueFormatter: (params) => {
            if (!params.value) return '';
            return this.datePipe.transform(params.value, 'dd MMM yyyy');
          },
          width: 220
        }
      ],
      getRowId: (params) => params.data.ExpenseID,
      animateRows: true,
      suppressDragLeaveHidesColumns: true,
      rowSelection: 'multiple',
      suppressRowClickSelection: true,
      getRowStyle: (params) => {

        // console.log('Row Style Params:', {
        //   data: params.data,
        //   status: params.data?.status,
        //   originalValues: params.data?.originalValues
        // });

        // Default white background
        let rowStyle = { background: 'white' };

        // More explicit status check
        if (params.data) {
          if (params.data.status === 'update') {
            rowStyle = { background: '#ffc107' };
            // console.log('Setting row to yellow due to update status');
          } else if (
            params.data.originalValues &&
            Object.keys(params.data.originalValues).length > 0
          ) {
            rowStyle = { background: '#ffc107' };
            // console.log('Setting row to yellow due to originalValues');
          }
        }

        return rowStyle;
      },
      onCellValueChanged: (params) => {
        // console.log('Cell Value Changed:', {
        //   oldValue: params.oldValue,
        //   newValue: params.newValue,
        //   field: params.column.getColId()
        // });

        this.handleCellValueChange(params);
      },
      onRowDoubleClicked: (params) => this.onRowDoubleClicked(params)
    };
  }

  onRowDoubleClicked(params: any): void {
    if (!params.node.group) {
      const dialogConfig = {
        maxWidth: 'clamp(340px, 92vw, 900px)',
        width: '100%',
        height: 'auto',
        disableClose: true,
        data: {
          ...params.data,
          entryPageType: Constants.NAV_EXPENSE_ENTRY,
          Mode: 'edit',
          startDate: DateUtility.formatDatefromDate(this.dateRange.value.start),
          endDate: DateUtility.formatDatefromDate(this.dateRange.value.end)
        }
      };

      const dialogRef = this.dialog.open(EntryDialogComponent, dialogConfig);

      dialogRef.afterClosed().subscribe(result => {
        if (result && typeof result !== 'boolean') {
          // Find the index of the row to update
          const rowIndex = this.rowData.findIndex(row => row.ExpenseID === params.data.ExpenseID);

          if (rowIndex !== -1) {
            // Update the row in rowData
            this.rowData[rowIndex] = {
              ...this.rowData[rowIndex],
              ...result,
              status: 'update'
            };

            // Update modified entries
            this.modifiedEntries = this.modifiedEntries.filter(
              entry => entry.ExpenseID !== this.rowData[rowIndex].ExpenseID
            );
            this.modifiedEntries.push(this.rowData[rowIndex]);
            this.hasModifiedEntries = true;
            this.financeExpenseService.setExpenseFinanceEntryIsUnsavedChangesPresent(true);

            // Update grid
            if (this.agGridAPI) {
              this.agGridAPI.setRowData(this.rowData);
            }
          }
        }
      });
    }
  }



  onGridReady(params: any) {
    this.agGridAPI = params.api;
    this.agGridColumnAPI = params.columnApi;

    if (this.agGridAPI) {
      this.agGridAPI.sizeColumnsToFit();
    }
  }

  initializeDateRange() {
    const today = new Date();
    const firstDay = new Date(today.getFullYear(), today.getMonth(), 1);
    const lastDay = new Date(today.getFullYear(), today.getMonth() + 1, 0);

    this.dateRange.patchValue({
      start: firstDay,
      end: lastDay
    });
  }

  loadConsultants() {
    this.consultantDetailsService.getConsultantDetails().subscribe(
      consultants => {
        this.consultantList = consultants;
      },
      error => {
        this.snackbarService.showSnackBar('Error loading consultants', 'close', 'failure');
      }
    );
  }

  clearFilters() {
    this.selectedCurrency = [];
    this.selectedConsultant = [];
    this.initializeDateRange();
    this.rowData = [];

    if (this.agGridAPI) {
      this.agGridAPI.setRowData([]);
    }

    this.snackbarService.showSnackBar('Filters cleared', 'close', 'success');
  }

  applyFilters() {
    if (!this.dateRange.value.start || !this.dateRange.value.end) {
      this.snackbarService.showSnackBar('Please select a date range', 'close', 'failure');
      return;
    }

    this.isLoading = true;
    // Ensure we have proper Date objects
    const startDate = new Date(this.dateRange.value.start);
    const endDate = new Date(this.dateRange.value.end);
    const filters = {
      startDate: DateUtility.formatDatefromDate(startDate),
      endDate: DateUtility.formatDatefromDate(endDate),
      ...(this.selectedConsultant?.length && { selectedConsultants: this.selectedConsultant }),
      ...(this.selectedCurrency?.length && { selectedCurrency: this.selectedCurrency })
    };

    this.financeExpenseService.getExpenseData(filters).subscribe({
      next: (data) => {
        // Explicitly set status to 'clean' for all loaded rows
        const cleanedData = data.map(entry => ({
          ...entry,
          status: 'clean'
        }));

        this.rowData = [...cleanedData];

        if (this.agGridAPI) {
          this.agGridAPI.setRowData(this.rowData);
          setTimeout(() => {
            this.agGridAPI.sizeColumnsToFit();
          });
        }

        // Reset modified entries and flag
        this.modifiedEntries = [];
        this.hasModifiedEntries = false;
        this.financeExpenseService.setExpenseFinanceEntryIsUnsavedChangesPresent(false);

        this.isLoading = false;
      },
      error: (error) => {
        // console.error('Error fetching expense data:', error);
        this.snackbarService.showSnackBar('Error loading expense data', 'close', 'failure');
        this.isLoading = false;
      }
    });
  }
  handleCellValueChange(event: any) {
    if (!event.data) return;

    // Directly modify the entry in rowData
    const rowIndex = this.rowData.findIndex(row => row.ExpenseID === event.data.ExpenseID);
    if (rowIndex !== -1) {
      // Update the specific row in rowData
      this.rowData[rowIndex] = {
        ...this.rowData[rowIndex],
        status: 'update'
      };

      // Update modified entries
      this.modifiedEntries = this.modifiedEntries.filter(
        entry => entry.ExpenseID !== event.data.ExpenseID
      );
      this.modifiedEntries.push(this.rowData[rowIndex]);

      // Update flag for save button
      this.hasModifiedEntries = this.modifiedEntries.length > 0;
      this.financeExpenseService.setExpenseFinanceEntryIsUnsavedChangesPresent(this.hasModifiedEntries);

      // Refresh cells to update row style
      if (this.agGridAPI) {
        this.agGridAPI.refreshCells({ force: true });
      }
    }
  }



  onSelectionChanged() {
    const selectedRows = this.agGridAPI.getSelectedRows();
    // console.log('Selected rows:', selectedRows);
  }
  saveModifiedEntries() {
    if (this.modifiedEntries.length === 0) {
      this.snackbarService.showSnackBar('No entries to save', 'close', 'info');
      return;
    }

    this.isLoading = true;

    // Prepare entries for bulk save
    const savePayload = this.modifiedEntries.map(entry => {
      const entryToSave = { ...entry };

      // Ensure ExpenseID is 0 for new entries
      entryToSave.ExpenseID = entryToSave.ExpenseID || 0;

      return entryToSave;
    });

    this.financeExpenseService.saveExpenseEntries(savePayload)
      .pipe(
        finalize(() => {
          this.isLoading = false;
        })
      )
      .subscribe({
        next: (response) => {
          if (response && response.result === 1) {
            this.snackbarService.showSnackBar(
              response.Message || 'Expense entries saved successfully',
              'close',
              'success'
            );

            // Reset modified entries and flag
            this.modifiedEntries = [];
            this.hasModifiedEntries = false;
            this.financeExpenseService.setExpenseFinanceEntryIsUnsavedChangesPresent(false);

            // Refresh grid data
            this.applyFilters();
          } else {
            this.snackbarService.showSnackBar(
              response?.Message || 'Error saving expense entries',
              'close',
              'failure'
            );
          }
        },
        error: (error) => {
          this.snackbarService.showSnackBar(
            'Error saving expense entries',
            'close',
            'failure'
          );
        }
      });
  }

  // exportToExcel() {
  //   if (this.agGridAPI) {
  //     this.agGridAPI.exportDataAsExcel({
  //       fileName: `Expense_Report_${this.datePipe.transform(new Date(), 'dd_MMM_yyyy')}`,
  //       processCellCallback: params => {
  //         if (params.column.getColId() === 'AmountSpent') {
  //           return `${params.value}`;
  //         }
  //         return params.value;
  //       }
  //     });
  //   }
  // }
}
