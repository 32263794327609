

// Define the initial state of the store

import { createReducer, on } from "@ngrx/store";
import { initialFinanceState } from "./finance.state";
import { clearFinanceData, setFinanceData } from "./finance.actions";

export const FinanceReducer = createReducer(
    initialFinanceState,
    on(setFinanceData, (state, { isFinance }) => ({ ...state, finance : isFinance })),
    on(clearFinanceData, state => ({ ...state, finance: null }))
);