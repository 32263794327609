import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from './login/login-page/authentication.service';
import { Store } from '@ngrx/store';
import { getFinance } from './core/store/isFinanceStore/finance.selector';
import { setFinanceData } from './core/store/isFinanceStore/finance.actions';

@Component({
    selector   : 'app-root',
    templateUrl: './app.component.html',
    styleUrls  : ['./app.component.scss']
})
export class AppComponent implements OnInit
{
    /**
     * Constructor
     */
    financeData: any;

    constructor(
        private _authenticationService: AuthenticationService,
        private _store: Store
    ) {
        // Only set up the store subscription in constructor
        this._store.select(getFinance).subscribe((data) => {
            this.financeData = data;
            // console.log('Finance data from store:', this.financeData);
        });
    }

    async ngOnInit(): Promise<void> {
        // console.log("dummy text 1");
        const user = JSON.parse(localStorage.getItem('loggedInUser'));
        // console.log("username : ", user.loggedInUser.username)

        // Move the data fetching to ngOnInit only
        await this.fetchFinanceData();
    }

    private async fetchFinanceData(): Promise<void> {
        this._authenticationService.isFinance(JSON.parse(localStorage.getItem('loggedInUser')).loggedInUser.username).subscribe({
            next: (finance_data) => {
                // console.log('Finance Data before in store:', finance_data);
                this._store.dispatch(setFinanceData({ isFinance: finance_data.isFinanceUser }));
            },
            error: (finance_error) => {
                console.error('Error fetching finance data:', finance_error);
            }
        });
    }


}
