import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpParams } from '@angular/common/http';
import { URLPaths } from 'app/core/mips/common/utilities/urlPaths.config';
import { UserModel } from 'app/core/mips/common/models/user.model';
import { LoginModel } from 'app/core/mips/common/models/login.model';
import { ApiheaderService } from 'app/core/mips/common/services/apiheader.service';


@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {

  /**
   * Constructor
   */
  constructor(
    private _http: HttpClient,
  ) { }

  login(username: string, password: string): Observable<string> {
    return this._http.post<string>(URLPaths.URL_LOGIN, { 'username': username, 'password': password });
  }
  login_optimized(username: string, password: string): Observable<LoginModel> {
    return this._http.post<LoginModel>(URLPaths.URL_LOGIN_OPTIMIZED, { 'username': username, 'password': password });
  }

  logout(): Observable<string> {
    return this._http.get<string>(URLPaths.URL_LOGOUT, { headers: ApiheaderService.getHeaders() });
  }

  getUser(): Observable<UserModel> {
    return this._http.get<UserModel>(URLPaths.URL_USER, { headers: ApiheaderService.getHeaders() });
  }

  validateToken(): Observable<any> {
    return this._http.get(URLPaths.URL_VALIDATE_TOKEN, { headers: ApiheaderService.getHeaders() })
  }

  isFinance(username: string): Observable<any> {
    const params = new HttpParams().set('username', username);
    return this._http.get(URLPaths.URL_IS_FINANCE, {
      headers: ApiheaderService.getHeaders(),
      params: params,
    });
  }

  // isFinance(payload: any): Observable<any> {
  //   return this._http.request('GET', URLPaths.URL_IS_FINANCE, {
  //     headers: ApiheaderService.getHeaders(),
  //     body: payload, // Sending the JSON payload in the body
  //   });
  // }

}
