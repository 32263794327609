<div class="flex flex-col" style="height: 100%">
  <!-- Dialog Box Header Text -->
  <div
    class="flex flex-wrap tb:flex-nowrap tb:flex-row justify-between items-center h-9"
  >
    <span
      mat-dialog-title
      class="dialog-title"
      [appDynamicStyles]="{
        'font-size': { zoomout: '16px', zoomin: '14.5px' }
      }"
      >{{ this.dialogData.entryPageType }} Entry
      {{ this.dialogData.entryPageType == "Time" ? "- Actuals" : "" }}</span
    >
    <span
      mat-dialog-title
      class="flex dialog-title"
      [appDynamicStyles]="{
        'font-size': { zoomout: '16px', zoomin: '14.5px' }
      }"
    >
      <mat-icon>room</mat-icon> {{ consultantWorkCountry }}
    </span>
  </div>

  <!-- Dialog Box Content - Time Entry -->
  <mat-dialog-content
    class="entry_dialog--dimensions dialog-content"
    [appDynamicStyles]="{ padding: { zoomout: '8px', zoomin: '4px' } }"
  >
    <div *ngIf="this.dialogData.entryPageType == 'Time'">
      <form [formGroup]="entryForm" #entryNgForm="ngForm">
        <div
          class="flex flex-col"
          [appDynamicStyles]="{ gap: { zoomout: '5px', zoomin: '0px' } }"
        >
          <div>
            <ng-select
              (change)="onChangeSelectedProject($event)"
              [(ngModel)]="selectedProject"
              [items]="projectList"
              [searchFn]="searchFunctionDropdown"
              [virtualScroll]="true"
              bindLabel="Display_String"
              formControlName="Project"
              groupBy="IsPlannedorFrequent"
              placeholder="On which Project ?"
              appendTo="body"
              dropdownPosition="bottom"
              [appDynamicStyles]="{
                'font-size': { zoomout: '14px', zoomin: '12px' }
              }"
            >
            </ng-select>
          </div>
          <div
            class="flex flex-wrap tb:flex-nowrap tb:flex-row"
            [appDynamicStyles]="{ gap: { zoomout: '12px', zoomin: '8px' } }"
          >
            <div class="w-full tb:w-2/4 pt-3">
              <div
                class="flex flex-col"
                [appDynamicStyles]="{ gap: { zoomout: '12px', zoomin: '8px' } }"
              >
                <div
                  class="relative w-full border-b border-gray-400"
                  style="height: 30px"
                >
                  <input
                    id="Task_Description"
                    matInput
                    [formControlName]="'Task_Description'"
                    type="text"
                    class="dialogInput w-full"
                    [appDynamicStyles]="{
                      'font-size': { zoomout: '14px', zoomin: '12px' }
                    }"
                    required
                  />
                  <span
                    class="floating-label absolute left-0 pointer-events-none"
                    [appDynamicStyles]="{
                      'font-size': { zoomout: '14px', zoomin: '12px' }
                    }"
                    >What were you working on ?</span
                  >
                </div>
                <div class="flex flex-col pt-3">
                  <label
                    [appDynamicStyles]="{
                      'font-size': { zoomout: '14px', zoomin: '12px' }
                    }"
                    >How many hours ?</label
                  >
                  <div
                    class="flex flex-wrap lg:flex-nowrap items-center lg:flex-row gap-3"
                  >
                    <div class="pb-1 pt-1 relative max-w-full">
                      <input
                        matInput
                        id="Hours"
                        [formControlName]="'Hours'"
                        max="24"
                        min="0"
                        step="0.5"
                        type="number"
                        class="dialogInput disabled:opacity-50 border-b border-gray-400"
                        [appDynamicStyles]="{
                          'font-size': { zoomout: '14px', zoomin: '12px' }
                        }"
                        style="width: 47px !important"
                      />
                    </div>
                    <div class="flex flex-row w-full">
                      <mat-radio-group
                        class="flex flex-row w-full justify-between"
                      >
                        <mat-radio-button
                          (change)="setInputFieldStatus('Hour')"
                          [checked]
                          style="color: rgba(0, 0, 0, 0.85)"
                        >
                          <span
                            [appDynamicStyles]="{
                              'font-size': { zoomout: '14px', zoomin: '12px' }
                            }"
                            >Hours</span
                          >
                        </mat-radio-button>
                        <mat-radio-button
                          (change)="setInputFieldStatus('Half')"
                          value="{{ consultantWorkHour / 2 }}"
                          class="whitespace-nowrap"
                          style="color: rgba(0, 0, 0, 0.85)"
                        >
                          <span
                            [appDynamicStyles]="{
                              'font-size': { zoomout: '14px', zoomin: '12px' }
                            }"
                            >Half Day</span
                          >
                        </mat-radio-button>
                        <mat-radio-button
                          (change)="setInputFieldStatus('Full')"
                          value="{{ consultantWorkHour }}"
                          class="whitespace-nowrap"
                          style="color: rgba(0, 0, 0, 0.85)"
                        >
                          <span
                            [appDynamicStyles]="{
                              'font-size': { zoomout: '14px', zoomin: '12px' }
                            }"
                            >Full Day</span
                          >
                        </mat-radio-button>
                      </mat-radio-group>
                    </div>
                  </div>
                </div>
                <ng-select
                  [(ngModel)]="selectedProjectTask"
                  [items]="projectTaskList"
                  [searchFn]="searchDropDownProjectTask"
                  [virtualScroll]="true"
                  bindLabel="Display_String"
                  formControlName="Task_Name"
                  placeholder="Under which task ?"
                  appendTo="body"
                  dropdownPosition="bottom"
                  [appDynamicStyles]="{
                    'font-size': { zoomout: '14px', zoomin: '12px' }
                  }"
                >
                </ng-select>
              </div>
            </div>
            <div class="tb:px-4">
              <mat-divider class="h-full" [vertical]="true"></mat-divider>
            </div>
            <div class="w-full tb:w-2/4">
              <div
                class="flex flex-col"
                [appDynamicStyles]="{ gap: { zoomout: '12px', zoomin: '8px' } }"
              >
                <div class="relative">
                  <ng-select
                    [(ngModel)]="selectedConsultantRate"
                    [items]="consultantProjectRate"
                    [virtualScroll]="true"
                    bindLabel="Display_Rate"
                    formControlName="Rate"
                    placeholder="At what rate ?"
                    appendTo="body"
                    dropdownPosition="bottom"
                    [appDynamicStyles]="{
                      'font-size': { zoomout: '14px', zoomin: '12px' }
                    }"
                  >
                  </ng-select>
                </div>

                <div class="relative flex flex-col justify-items-center">
                  <label
                    [appDynamicStyles]="{
                      'font-size': { zoomout: '14px', zoomin: '12px' }
                    }"
                    >On which date ?</label
                  >
                  <div
                    class="flex flex-row justify-between items-center border-b border-gray-400 w-full"
                  >
                    <input
                      matInput
                      [formControlName]="'TDate'"
                      [matDatepicker]="picker"
                      (dateChange)="changeTDate()"
                      class="dialogInput"
                      [appDynamicStyles]="{
                        'font-size': { zoomout: '14px', zoomin: '12px' }
                      }"
                      readonly
                      (click)="picker.open()"
                    />
                    <mat-datepicker-toggle
                      matIconSuffix
                      [for]="picker"
                      style="position: absolute; left: 90%"
                    ></mat-datepicker-toggle>
                    <mat-datepicker #picker></mat-datepicker>
                  </div>
                </div>
                <mat-slide-toggle
                  (change)="invoiceOnSameDate = !invoiceOnSameDate"
                  [checked]="invoiceOnSameDate"
                  style="color: rgba(0, 0, 0, 0.85)"
                >
                  <span
                    [appDynamicStyles]="{
                      'font-size': { zoomout: '14px', zoomin: '12px' }
                    }"
                    >Invoice on same date</span
                  >
                </mat-slide-toggle>
                <div
                  *ngIf="!invoiceOnSameDate"
                  class="relative flex flex-col justify-items-center"
                >
                  <label
                    [appDynamicStyles]="{
                      'font-size': { zoomout: '14px', zoomin: '12px' }
                    }"
                    >Invoice on ?</label
                  >
                  <div
                    class="flex flex-row justify-between items-center border-b border-gray-400 w-full"
                  >
                    <input
                      matInput
                      [formControlName]="'Invoice_Date'"
                      [matDatepicker]="picker"
                      (dateChange)="changeInvoiceDate()"
                      class="dialogInput w-72"
                      [appDynamicStyles]="{
                        'font-size': { zoomout: '14px', zoomin: '12px' }
                      }"
                      readonly
                      (click)="picker.open()"
                    />
                    <mat-datepicker-toggle
                      matIconSuffix
                      [for]="picker"
                      style="position: absolute; left: 90%"
                    ></mat-datepicker-toggle>
                    <mat-datepicker #picker></mat-datepicker>
                  </div>
                </div>
                <div
                  class="relative"
                  [appDynamicStyles]="{
                    'padding-top': { zoomout: '0px', zoomin: '4px' }
                  }"
                >
                  <mat-label
                    style="color: rgba(0, 0, 0, 0.85); padding-top: 5%"
                    [appDynamicStyles]="{
                      'font-size': { zoomout: '14px', zoomin: '12px' }
                    }"
                    >Is entry chargeable?</mat-label
                  >
                  <mat-radio-group
                    (change)="isChargeable = !isChargeable"
                    formControlName="Charge"
                  >
                    <mat-radio-button
                      [checked]="isChargeable"
                      [disabled]="
                        selectedProject
                          ? selectedProject.Charge.toLowerCase() === 'no'
                          : false
                      "
                      value="Yes"
                      [ngStyle]="
                        selectedProject?.Charge.toLowerCase() === 'no'
                          ? {
                              color: 'rgba(0,0,0,0.65)'
                            }
                          : {
                              color: 'rgba(0,0,0,0.85)'
                            }
                      "
                      ><mat-label
                        [appDynamicStyles]="{
                          'font-size': { zoomout: '14px', zoomin: '12px' }
                        }"
                        >Yes</mat-label
                      >
                    </mat-radio-button>
                    <mat-radio-button
                      [checked]="!isChargeable"
                      value="No"
                      [appDynamicStyles]="{
                        'font-size': { zoomout: '14px', zoomin: '12px' }
                      }"
                      style="color: rgba(0, 0, 0, 0.85)"
                      ><mat-label
                        [appDynamicStyles]="{
                          'font-size': { zoomout: '14px', zoomin: '12px' }
                        }"
                        >No</mat-label
                      >
                    </mat-radio-button>
                  </mat-radio-group>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          *ngIf="
            selectedProject && selectedProject.Charge.toLowerCase() === 'yes'
          "
          class="mb-3 px-1"
          style="background-color: #f8e9be"
        >
          <span
            class="font-semibold"
            [appDynamicStyles]="{
              'font-size': { zoomout: '14px', zoomin: '12px' }
            }"
            *ngIf="
              selectedConsultantRate && selectedConsultantRate.Is_Converted_Rate
            "
          >
            No Project rates found. Your base rate has been converted to project
            billing currency. {{ selectedProject.Manager }} (PM) will be
            notified about this entry.
          </span>
        </div>
        <div>
          <div
            class="flex flex-row gap-3 place-content-stretch justify-around mb-1 mt-2"
          >
            <button
              mat-raised-button
              [mat-dialog-close]="true"
              class="font entry_button--design p-2 button-popup-css"
              [appDynamicStyles]="{
                'font-size': { zoomout: '16px', zoomin: '14px' }
              }"
            >
              Cancel
            </button>

            <button
              (click)="save()"
              [disabled]="!entryForm.valid"
              mat-raised-button
              class="font entry_button--design p-2 button-popup-css enabled:cursor-pointer disabled:opacity-50 cursor-not-allowed"
              style="color: #6ac738"
              [appDynamicStyles]="{
                'font-size': { zoomout: '16px', zoomin: '14px' }
              }"
            >
              Apply
            </button>
            <button
              *ngIf="!isEntryCreateMode"
              (click)="cloneData()"
              [mat-dialog-close]="true"
              [disabled]="!entryForm.valid"
              mat-raised-button
              class="font entry_button--design p-2 button-popup-css enabled:cursor-pointer disabled:opacity-50 cursor-not-allowed"
              style="color: #ffcd4d"
              [appDynamicStyles]="{
                'font-size': { zoomout: '16px', zoomin: '14px' }
              }"
            >
              Clone
            </button>
          </div>
        </div>
      </form>
    </div>
  </mat-dialog-content>

  <!-- Dialog Box Content - Expense Entry -->
  <mat-dialog-content
    class="entry_dialog--dimensions dialog-content"
    [appDynamicStyles]="{ padding: { zoomout: '8px', zoomin: '4px' } }"
  >
    <div *ngIf="this.dialogData.entryPageType == 'Expense'">
      <form [formGroup]="entryForm" #entryNgForm="ngForm">
        <div
          class="flex flex-col"
          [appDynamicStyles]="{ gap: { zoomout: '5px', zoomin: '0px' } }"
        >
          <div>
            <ng-select
              (change)="onChangeSelectedProject($event)"
              [(ngModel)]="selectedProject"
              [items]="projectList"
              [searchFn]="searchFunctionDropdown"
              [virtualScroll]="true"
              bindLabel="Display_String"
              formControlName="Project"
              groupBy="IsPlannedorFrequent"
              placeholder="On which Project ?"
              appendTo="body"
              dropdownPosition="bottom"
              [appDynamicStyles]="{
                'font-size': { zoomout: '14px', zoomin: '12px' }
              }"
            >
            </ng-select>
          </div>
          <div
            class="flex flex-wrap tb:flex-nowrap tb:flex-row"
            [appDynamicStyles]="{ gap: { zoomout: '12px', zoomin: '8px' } }"
          >
            <div class="w-full tb:w-2/4">
              <div
                class="flex flex-col"
                [appDynamicStyles]="{ gap: { zoomout: '12px', zoomin: '8px' } }"
              >
                <ng-select
                  [(ngModel)]="selectedProjectTask"
                  [items]="projectTaskList"
                  [searchFn]="searchDropDownProjectTask"
                  [virtualScroll]="true"
                  bindLabel="Display_String"
                  formControlName="Task_Name"
                  placeholder="Under which task ?"
                  appendTo="body"
                  dropdownPosition="bottom"
                  [appDynamicStyles]="{
                    'font-size': { zoomout: '14px', zoomin: '12px' }
                  }"
                >
                </ng-select>
                <ng-select
                  [(ngModel)]="selectedExpenseType"
                  [items]="expenseTypeList"
                  [searchFn]="searchDropDownType"
                  (change)="onChangeSelectedType($event)"
                  [virtualScroll]="true"
                  bindLabel="Type"
                  formControlName="ExpenseType"
                  placeholder="Type"
                  appendTo="body"
                  dropdownPosition="bottom"
                  [appDynamicStyles]="{
                    'font-size': { zoomout: '14px', zoomin: '12px' }
                  }"
                >
                </ng-select>
                <ng-select
                  [(ngModel)]="selectedExpenseDescType"
                  [items]="expenseTypeDescList"
                  [searchFn]="searchDropDownTypeDesc"
                  [virtualScroll]="true"
                  bindLabel="description"
                  formControlName="Description"
                  placeholder="Description"
                  appendTo="body"
                  dropdownPosition="bottom"
                  [appDynamicStyles]="{
                    'font-size': { zoomout: '14px', zoomin: '12px' }
                  }"
                >
                </ng-select>

                <div
                  class="flex flex-row"
                  *ngIf="
                    selectedExpenseDescType &&
                    selectedExpenseDescType.description === 'Mileage'
                  "
                  style="height: 30px"
                >
                  <div class="relative w-full border-b border-gray-400 flex-1">
                    <input
                      [appDynamicStyles]="{
                        'font-size': { zoomout: '14px', zoomin: '12px' }
                      }"
                      id="Mileage"
                      matInput
                      [formControlName]="'Mileage'"
                      type="number"
                      class="dialogInput w-full"
                      required
                    />
                    <span
                      class="floating-label absolute left-0 pointer-events-none"
                      [appDynamicStyles]="{
                        'font-size': { zoomout: '14px', zoomin: '12px' }
                      }"
                      >Mileage
                    </span>
                  </div>
                  <span
                    [appDynamicStyles]="{
                      'font-size': { zoomout: '14px', zoomin: '12px' }
                    }"
                    *ngIf="selectedProject && selectedProject.MileageRate > 0"
                    >@ {{ projectCurrency }}
                    {{ selectedProject.MileageRate }}</span
                  >
                </div>
                <div
                  class="relative w-full border-b border-gray-400"
                  style="padding-top: 1.3em; height: 48.5px"
                >
                  <input
                    id="Details"
                    matInput
                    [appDynamicStyles]="{
                      'font-size': { zoomout: '14px', zoomin: '12px' }
                    }"
                    [formControlName]="'Details'"
                    type="text"
                    class="dialogInput w-full"
                    required
                  />
                  <span
                    class="floating-label absolute left-0 pointer-events-none"
                    [appDynamicStyles]="{
                      'font-size': { zoomout: '14px', zoomin: '12px' }
                    }"
                    >Details
                  </span>
                </div>
              </div>
            </div>
            <div class="tb:px-4">
              <mat-divider class="h-full" [vertical]="true"></mat-divider>
            </div>
            <div class="w-full tb:w-2/4">
              <div class="flex flex-col gap-2">
                <div class="relative">
                  <ng-select
                    [(ngModel)]="selectedPaymentMethod"
                    [items]="paymentMethodList"
                    [virtualScroll]="true"
                    bindLabel="paymentMethod"
                    formControlName="PaymentMethod"
                    placeholder="Payment Method"
                    appendTo="body"
                    dropdownPosition="bottom"
                    [appDynamicStyles]="{
                      'font-size': { zoomout: '14px', zoomin: '12px' }
                    }"
                  >
                  </ng-select>
                </div>
                <div class="flex flex-row space-x-2">
                  <div class="basis-1/2 p-2">
                    <ng-select
                      [(ngModel)]="selectedCurrency"
                      [items]="currencyList"
                      [virtualScroll]="true"
                      bindLabel="currency_symbol_short"
                      formControlName="Currency_symbol_short"
                      placeholder="Currency"
                      appendTo="body"
                      dropdownPosition="bottom"
                      [appDynamicStyles]="{
                        'font-size': { zoomout: '14px', zoomin: '12px' }
                      }"
                    >
                    </ng-select>
                  </div>
                  <div class="relative basis-1/2 pb-4" style="padding-top: 2px">
                    <label
                      [appDynamicStyles]="{
                        'font-size': { zoomout: '14px', zoomin: '12px' }
                      }"
                      >Amount</label
                    >
                    <input
                      matInput
                      id="AmountSpent"
                      [formControlName]="'AmountSpent'"
                      matInput
                      min="0"
                      step="any"
                      type="number"
                      class="dialogInput disabled:opacity-50 border-b border-gray-400 w-full"
                      [appDynamicStyles]="{
                        'font-size': { zoomout: '14px', zoomin: '12px' }
                      }"
                    />
                  </div>
                </div>
                <!-- Vat fiedlds -->
                <div class="flex flex-col" *ngIf="showVatFields">
                  <!-- VAT row -->
                  <div class="flex flex-row items-center">
                    <span
                      class="floating-label pointer-events-none mr-2"
                      [appdynamicstyles]="{
                        'font-size': { zoomout: '14px', zoomin: '12px' }
                      }"
                    >
                      Tax Included
                    </span>
                    <mat-slide-toggle
                      [formControlName]="'isVatApplicable'"
                      (change)="onVatCheckboxChange($event)"
                    >
                    </mat-slide-toggle>

                    <!-- VAT Percentage input - appears inline when toggle is on -->
                    <div
                      class="flex items-center ml-4"
                      *ngIf="entryForm.get('isVatApplicable').value"
                    >
                      <span
                        class="floating-label pointer-events-none mr-2"
                        [appdynamicstyles]="{
                          'font-size': { zoomout: '14px', zoomin: '12px' }
                        }"
                      >
                        Tax value
                      </span>
                      <input
                        type="number"
                        [formControlName]="'Tax_Amount'"
                        class="w-16 border-b border-gray-400 bg-transparent outline-none text-right"
                        min="0"
                        [appdynamicstyles]="{
                          'font-size': { zoomout: '14px', zoomin: '12px' }
                        }"
                      />
                    </div>
                  </div>
                </div>

                <div class="relative flex flex-col justify-items-center">
                  <label
                    [appDynamicStyles]="{
                      'font-size': { zoomout: '14px', zoomin: '12px' }
                    }"
                    >On which date ?</label
                  >
                  <div
                    class="flex flex-row justify-between items-center border-b border-gray-400 w-full"
                  >
                    <input
                      matInput
                      [formControlName]="'TDate'"
                      [matDatepicker]="picker"
                      (dateChange)="changeTDate()"
                      class="dialogInput"
                      [appDynamicStyles]="{
                        'font-size': { zoomout: '14px', zoomin: '12px' }
                      }"
                      readonly
                      (click)="picker.open()"
                    />
                    <mat-datepicker-toggle
                      matIconSuffix
                      [for]="picker"
                      style="position: absolute; left: 90%"
                    ></mat-datepicker-toggle>
                    <mat-datepicker #picker></mat-datepicker>
                  </div>
                </div>
                <mat-slide-toggle
                  (change)="invoiceOnSameDate = !invoiceOnSameDate"
                  [checked]="invoiceOnSameDate"
                  style="color: rgba(0, 0, 0, 0.85)"
                  ><span
                    [appDynamicStyles]="{
                      'font-size': { zoomout: '14px', zoomin: '12px' }
                    }"
                  >
                    Invoice on same date
                  </span>
                </mat-slide-toggle>
                <div
                  *ngIf="!invoiceOnSameDate"
                  class="relative flex flex-col justify-items-center"
                >
                  <label
                    [appDynamicStyles]="{
                      'font-size': { zoomout: '14px', zoomin: '12px' }
                    }"
                    >Invoice on ?</label
                  >
                  <div
                    class="flex flex-row justify-between items-center border-b border-gray-400 w-full"
                  >
                    <input
                      matInput
                      [formControlName]="'Invoice_date'"
                      [matDatepicker]="picker"
                      (dateChange)="changeInvoiceDate()"
                      class="dialogInput w-72"
                      readonly
                      (click)="picker.open()"
                      [appDynamicStyles]="{
                        'font-size': { zoomout: '14px', zoomin: '12px' }
                      }"
                    />
                    <mat-datepicker-toggle
                      matIconSuffix
                      [for]="picker"
                      style="position: absolute; left: 90%"
                    ></mat-datepicker-toggle>
                    <mat-datepicker #picker></mat-datepicker>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- Rate Conversion Disclaimer Text -->
        <div
          *ngIf="
            selectedProject && selectedProject.Charge.toLowerCase() === 'yes'
          "
          class="mb-3 px-1"
          style="background-color: #f8e9be"
        >
          <span
            class="font-semibold"
            [appDynamicStyles]="{
              'font-size': { zoomout: '14px', zoomin: '12px' }
            }"
            *ngIf="
              selectedConsultantRate && selectedConsultantRate.Is_Converted_Rate
            "
          >
            No Project rates found. Your base rate has been converted to project
            billing currency. {{ selectedProject.Manager }} (PM) will be
            notified about this entry.
          </span>
        </div>
        <div>
          <div
            class="flex flex-row gap-3 place-content-stretch justify-around mb-1 mt-2"
          >
            <button
              mat-raised-button
              [mat-dialog-close]="true"
              class="font entry_button--design p-2 button-popup-css"
              [appDynamicStyles]="{
                'font-size': { zoomout: '16px', zoomin: '14px' }
              }"
            >
              Cancel
            </button>

            <button
              (click)="save()"
              [disabled]="!entryForm.valid"
              mat-raised-button
              class="font entry_button--design p-2 button-popup-css enabled:cursor-pointer disabled:opacity-50 cursor-not-allowed"
              style="color: #6ac738"
              [appDynamicStyles]="{
                'font-size': { zoomout: '16px', zoomin: '14px' }
              }"
            >
              Apply
            </button>
            <button
              *ngIf="!isEntryCreateMode && hideCloneButton"
              (click)="cloneData()"
              [mat-dialog-close]="true"
              [disabled]="!entryForm.valid"
              mat-raised-button
              class="font entry_button--design p-2 button-popup-css enabled:cursor-pointer disabled:opacity-50 cursor-not-allowed"
              style="color: #ffcd4d"
              [appDynamicStyles]="{
                'font-size': { zoomout: '16px', zoomin: '14px' }
              }"
            >
              Clone
            </button>
          </div>
        </div>
      </form>
    </div>
  </mat-dialog-content>
</div>
