import { CurrencyService } from './../common/services/currency.service';
// finance-expense.service.ts
import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { ConsultantModel } from '../common/models/consultant.model';
import { ConsultantDetailsService } from '../common/services/consultant-details.service';
import { DateUtility } from '../common/utilities/date.utility';
import { URLPaths } from '../common/utilities/urlPaths.config';
import { ErrorHandlerService } from '../common/services/error-handler.service';
import { commonFunctions } from '../common/classes/common.class';
import { ApiheaderService } from '../common/services/apiheader.service';
import { ExpenseEntryModel } from '../common/models/ExpenseEntry.model';
import { TitleCasePipe } from '@angular/common';
import { CurrencyModel } from '../common/models/currency.model';

interface ExpenseFilters {
  country?: string;
  consultants?: ConsultantModel[];
  dateRange?: {
    start: string | Date;
    end: string | Date;
  };
}

@Injectable({
  providedIn: 'root'
})
export class FinanceExpenseService {
  
  public expenseFinanceEntryIsUnsavedChangesPresent: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  
  constructor(
    private http: HttpClient,
    private consultantService: ConsultantDetailsService,
    private errorHandlerService: ErrorHandlerService,
    private titleCasePipe: TitleCasePipe,
    private currencyService : CurrencyService
  ) {}

  getConsultants(): Observable<ConsultantModel[]> {
    return this.consultantService.getConsultantDetails();
  }
  getCurrencies():Observable<any[]>{
    return this.currencyService.getCurrencyDetails();

  }
  getExpenseData(filters: {
    startDate: string,
    endDate: string,
    selectedConsultants?: ConsultantModel[],
    selectedCurrency?: CurrencyModel[]
}): Observable<ExpenseEntryModel[]> {
    let params = new HttpParams();

    // Add required parameters
    params = params.append('startdate', filters.startDate);
    params = params.append('enddate', filters.endDate);

    // Handle employee filtering
    if (filters.selectedConsultants?.length > 0) {
        const formattedEmployee = filters.selectedConsultants
            .map(c => c.Employee)
            .map(value => `'${value}'`)
            .join(',');
        params = params.append('employee', formattedEmployee);
    }

    return this.http.get<ExpenseEntryModel[]>(URLPaths.URL_EXPENSE_ENTRY, {
        headers: ApiheaderService.getHeaders(),
        params: params
    }).pipe(
        map((values: ExpenseEntryModel[]) => {
            // console.log('Initial data from API:', values);

            let filteredValues = [...values];

            if (filters.selectedCurrency?.length > 0) {
                const selectedCurrencies = filters.selectedCurrency.map(c => c.currency_code);
                // console.log('Selected currencies:', selectedCurrencies);

                filteredValues = filteredValues.filter(expense => {
                    // Map the API response Currency_From to our model's Currency_From
                    const expenseCurrency = (expense as any).Currency_From || expense.Currency_From;
                    const matches = selectedCurrencies.includes(expenseCurrency);
                    // console.log(`Checking expense ${expense.ExpenseID}: Currency=${expenseCurrency}, Matches=${matches}`);
                    return matches;
                });
            }

            // console.log('After currency filtering:', filteredValues);

            return filteredValues.map(value => ({
                ...value,
                Employee: this.titleCasePipe.transform(value.Employee),
                Client: commonFunctions.convertCase(value.Client, 1),
                Project: commonFunctions.convertCase(value.Project, 1)
            })) as ExpenseEntryModel[];
        }),
        catchError((error: any): Observable<ExpenseEntryModel[]> => {
            // console.error('API Error:', error);
            return new Observable<ExpenseEntryModel[]>(subscriber => {
                subscriber.next([]);
                subscriber.complete();
            });
        })
    );
}

saveExpenseEntries(expenseEntries: ExpenseEntryModel[]): Observable<any> {
  return this.http.post(
    URLPaths.URL_EXPENSE_ENTRY,
    { 'save_expense_entries': expenseEntries },
    { headers: ApiheaderService.getHeaders() }
  ).pipe(
    catchError(this.errorHandlerService.handleError.bind(this))
  );
}

  // deleteExpenseEntries(expenseIds: number[]): Observable<any> {
  //   const options = {
  //     headers: ApiheaderService.getHeaders(),
  //     body: { expenseIds }
  //   };

  //   return this.http.delete(URLPaths.URL_EXPENSE_ENTRY, options).pipe(
  //     catchError(this.errorHandlerService.handleError.bind(this))
  //   );
  // }

  getExpenseTypes(): Observable<any[]> {
    return this.http.get(URLPaths.URL_EXPENSE_TYPE, {
      headers: ApiheaderService.getHeaders()
    }).pipe(
      map(res => res as any[]),
      catchError(this.errorHandlerService.handleError.bind(this))
    );
  }

  getExpensePaymentMethods(): Observable<any[]> {
    return this.http.get(URLPaths.URL_EXPENSE_PAYMENT_METHOD, {
      headers: ApiheaderService.getHeaders()
    }).pipe(
      map(res => res as any[]),
      catchError(this.errorHandlerService.handleError.bind(this))
    );
  }

  setExpenseFinanceEntryIsUnsavedChangesPresent(isUnsavedChangesPresent) {
    this.expenseFinanceEntryIsUnsavedChangesPresent.next(isUnsavedChangesPresent);
    console.log(this.expenseFinanceEntryIsUnsavedChangesPresent);
  }

}
