import { URLPaths } from "../mips/common/utilities/urlPaths.config";
import { Constants } from "../mips/common/utilities/constants.config";
import { BehaviorSubject, Subscription } from "rxjs";
import { NavigationEnd, Router } from "@angular/router";
import { Store } from "@ngrx/store";
import { getCurrentUser } from 'app/core/store/currentUser/user.selectors';
import { getFinance } from "../store/isFinanceStore/finance.selector";

export class navItems {
  private static store: Store;
  private static isIndiaUser = new BehaviorSubject<boolean>(false);
  static isFinance: any;

  public static initializeStore(store: Store) {
    this.store = store;
    // Set up the subscription when store is initialized
    this.store.select(getCurrentUser).subscribe(user => {
      this.isIndiaUser.next(user?.Country_Name === 'IN');
    });
    this.store.select(getFinance).subscribe(data => {
      this.isFinance =  data;
      console.log("nav item init : ",this.isFinance)
    });
  }

  static navItemBlueprint: any[] = [
    {
      id: 'personal',
      title: Constants.NAV_GROUP_PERSONAL,
      type: 'group',
      link: '',
      children: [
        {
          id: 'time',
          title: Constants.NAV_TIME_ENTRY_ACTUALS,
          type: 'basic',
          icon: 'access_time',
          link: URLPaths.ROUTE_TIME_ENTRY_ACTUALS
          // children: [
          //     {
          //         id   : 'timeEntry',
          //         title: Constants.NAV_TIME_ENTRY_ACTUALS,
          //         type : 'basic',
          //         link : URLPaths.ROUTE_TIME_ENTRY_ACTUALS
          //     }
          // ]
        },
        {
          id: 'expense',
          title: Constants.NAV_EXPENSE_ENTRY,
          type: 'basic',
          icon: 'attach_money',
          link: URLPaths.ROUTE_EXPENSE_ENTRY_ACTUALS
          // children: [
          //     {
          //         id   : 'expenseEntry',
          //         title: Constants.NAV_EXPENSE_ENTRY,
          //         type : 'basic',
          //         link : URLPaths.ROUTE_EXPENSE_ENTRY
          //     }
          // ]
        },
        {
          id: 'flag',
          title: Constants.NAV_FLAG,
          type: 'basic',
          icon: 'flag',
          link: URLPaths.ROUTE_FLAG_ACTUALS
        },
      ],
    },
    {
      id: 'analytics',
      title: Constants.NAV_GROUP_ANALYTICS,
      type: 'group',
      link: '',
      children: [
        {
          id: 'timeDicer',
          title: Constants.NAV_TIME_DICER,
          type: 'basic',
          icon: 'pie_chart',
          link: URLPaths.ROUTE_TIME_DICER_ACTUALS
        },
        {
          id:'finance-expense',
          title:'Expense',
          type:'basic',
          icon:'pie_chart',
          link: URLPaths.ROUTE_FINANCE_EXPENSE,
          // hidden: () => {
          //   let isHidden = true;
          //   console.log("hheck : ",this.isFinance.finance)
          //   return !this.isFinance.finance;
          // }
        }
        // {
        //   id: 'dummy',
        //   title: Constants.NAV_DUMYY,
        //   type: 'basic',
        //   icon: 'pie_chart',
        //   link: URLPaths.ROUTE_DUMMY
        // }
      ]
    },
    // {
    //   id: 'planning',
    //   title: Constants.NAV_GROUP_PLANNING,
    //   type: 'collapsable',
    //   link: '',
    //   children: [
    //     {
    //       id: 'timeEntryForPlanning',
    //       title: Constants.NAV_PLANNING_TIME_ENTRY,
    //       type: 'basic',
    //       icon: 'calendar_today',
    //       link: URLPaths.ROUTE_PLANNING + URLPaths.ROUTE_PLANNING_TIME_ENTRY
    //     },
    //   ]
    // },
    {
      id: 'reports',
      title: Constants.NAV_GROUP_REPORTS,
      type: 'collapsable',
      link: '',
      children: [
        {
          id: 'companySummary',
          title: Constants.NAV_REPORT_COMPANY_SUMMARY,
          type: 'basic',
          icon: 'insert_chart',
          link: URLPaths.ROUTE_REPORT_COMPANY_SUMMARY

        },
        {
          id: 'projectDetailReport',
          title: Constants.NAV_REPORT_PROJECT_DETAIL,
          type: 'basic',
          icon: 'insert_chart',
          link: URLPaths.ROUTE_REPORT_PROJECT_DETAIL

        },
        {
          id: 'consultantAvailability',
          title: Constants.NAV_REPORT_CONSULTANT_AVAILABILITY,
          type: 'basic',
          icon: 'insert_chart',
          link: URLPaths.ROUTE_REPORT_CONSULTANT_AVAILABILITY

        },
        {
          id: 'monthlyReport',
          title: Constants.NAV_REPORT_MONTHLY_REPORT,
          type: 'basic',
          icon: 'insert_chart',
          link: URLPaths.ROUTE_REPORT_MONTHLY_REPORT

        },
        {
          id: 'consultantDetailReport',
          title: Constants.NAV_REPORT_CONSULTANT_DETAIL,
          type: 'basic',
          icon: 'insert_chart',
          link: URLPaths.ROUTE_REPORT_CONSULTANT_DETAIL_REPORT

        },
        {
          id: 'consultantAvailabilityChart',
          title: Constants.NAV_REPORT_CONSULTANT_AVAILABILITY_CHART,
          type: 'basic',
          icon: 'insert_chart',
          link: URLPaths.ROUTE_REPORT_CONSULTANT_AVAILABILITY_REPORT

        },
        {
          id: 'leaveDetails',
          title: Constants.NAV_REPORT_LEAVE_DETAILS,
          type: 'basic',
          icon: 'insert_chart',
          link: URLPaths.ROUTE_REPORT_LEAVE_DETAILS
        },
      ],
    },

    {
      id: 'projectDetails',
      title: Constants.NAV_PROJECT_DETAILS,
      type: 'collapsable',
      link: '',
      children : [
        // {
        //   id: 'viewProjectDetails',
        //   title: Constants.NAV_PROJECT_DETAILS_VIEW,
        //   type: 'basic',
        //   icon: 'remove_red_eye',
        //   link: URLPaths.ROUTE_PROJECT_DETAILS + URLPaths.ROUTE_PROJECT_DETAILS_VIEW
        // },
        // {
        //   id: 'addProject',
        //   title: Constants.NAV_PROJECT_DETAILS_ADD,
        //   type: 'basic',
        //   icon: 'app_registration',
        //   link: URLPaths.ROUTE_PROJECT_DETAILS + URLPaths.ROUTE_PROJECT_DETAILS_ADD
        // },
        {
          id: 'editProjectDetails',
          title: Constants.NAV_PROJECT_DETAILS_EDIT,
          type: 'basic',
          icon: 'mode_edit',
          link: URLPaths.ROUTE_PROJECT_DETAILS + URLPaths.ROUTE_PROJECT_DETAILS_ADD,
        },
            {
              id: 'addClient',
              title: Constants.NAV_ADD_CLIENT,
              type: 'basic',
              icon: 'add',
              link: URLPaths.ROUTE_ADD_CLIENT
            },
      ]
    },
    // {
    //   id: 'projectDetails',
    //   title: Constants.NAV_PROJECT_DETAILS,
    //   type: 'group',
    //   link: '',
    //   children : [
    //     // {
    //     //   id: 'viewProjectDetails',
    //     //   title: Constants.NAV_PROJECT_DETAILS_VIEW,
    //     //   type: 'basic',
    //     //   icon: 'remove_red_eye',
    //     //   link: URLPaths.ROUTE_PROJECT_DETAILS + URLPaths.ROUTE_PROJECT_DETAILS_VIEW
    //     // },
    //     // {
    //     //   id: 'addProject',
    //     //   title: Constants.NAV_PROJECT_DETAILS_ADD,
    //     //   type: 'basic',
    //     //   icon: 'app_registration',
    //     //   link: URLPaths.ROUTE_PROJECT_DETAILS + URLPaths.ROUTE_PROJECT_DETAILS_ADD
    //     // },

    //     {
    //       id: 'client',
    //       title: 'Client',
    //       type: 'collapsable',
    //       link: '',
    //       children : [
    //         {
    //           id: 'addClient',
    //           title: Constants.NAV_ADD_CLIENT,
    //           type: 'basic',
    //           icon: 'add',
    //           link: URLPaths.ROUTE_ADD_CLIENT
    //         },
    //       ]
    //     },
    //   ]
    // },
    {
      id: 'usefulLinks',
      title: Constants.NAV_USEFUL_LINKS,
      type: 'collapsable',
      link: '',
      children: [
        {
          id: 'mip1',
          title: "MIPS 1.0",
          type: 'basic',
          icon: 'link',
          link: 'https://mips.thorogood.com/MIPSMain.aspx',
          externalLink: true,
          target: '_blank'
        },
        {
          id: 'wfhRequisition',
          title: Constants.NAV_WFH_REQUISITION_FORM,
          type: 'basic',
          icon: 'link',
          link: 'https://forms.office.com/Pages/ResponsePage.aspx?id=gVMX5Xa-HE246JyebXWXLvRZy6C5kVZEraZek1yoBQ1UMlkxQkU2QzJGRUxVVUQ0SDZBQUFCNURFSS4u',
          externalLink: true,
          target: '_blank'
        },
        {
          id: 'wfhReport',
          title: Constants.NAV_WFH_REPORT,
          type: 'basic',
          icon: 'link',
          link: 'https://app.powerbi.com/groups/28617dba-e38a-433f-870b-dddc9079f639/reports/5dc9e778-9695-4f8c-894e-7550ded55c3e?ctid=e5175381-be76-4d1c-b8e8-9c9e6d75972e&pbi_source=linkShare',
          externalLink: true,
          target: '_blank'
        },
        {
          id: 'PMDashboard',
          title: Constants.NAV_PM_DASHBOARD,
          type: 'basic',
          icon: 'link',
          link: 'https://app.powerbi.com/links/tUe27tn3XY?ctid=e5175381-be76-4d1c-b8e8-9c9e6d75972e&pbi_source=linkShare',
          externalLink: true,
          target: '_blank'
        },
        {
          id: 'LMDashboard',
          title: Constants.NAV_LM_DASHBOARD,
          type: 'basic',
          icon: 'link',
          link: 'https://app.powerbi.com/groups/me/reports/254a80f3-4fbc-4535-977f-a0284f327069/ReportSectiona2d477698bd1cbb9e634?ctid=e5175381-be76-4d1c-b8e8-9c9e6d75972e&experience=power-bi',
          externalLink: true,
          target: '_blank'
        },
        {
          id: 'intranetHome',
          title: Constants.NAV_INTRANET_HOME,
          type: 'basic',
          icon: 'link',
          link: 'https://thorogoodassociates.sharepoint.com/SitePages/Thorogood%20Intranet.aspx',
          externalLink: true,
          target: '_blank'
        },
        {
          id: 'indiaworkplaceReport',
          title: "India Workplace Report",
          type: 'basic',
          icon: 'link',
          link: 'https://app.powerbi.com/groups/28617dba-e38a-433f-870b-dddc9079f639/reports/ec91fb2d-2e89-4522-9738-a5b7098f02fc/ReportSection?experience=power-bi',
          externalLink: true,
          target: '_blank',
          hidden: () => {
            let isHidden = true;
            navItems.isIndiaUser.subscribe(isIndia => {
              isHidden =!isIndia;
            });
            console.log(isHidden)
            return isHidden;
          }
        },
      ]
    },


    // {
    //   id: 'intranet',
    //   title: Constants.NAV_GROUP_INTRANET,
    //   type: 'group',
    //   link: '',
    //   children: [
    //     {
    //       id: 'home',
    //       title: Constants.NAV_INTRANET_HOME,
    //       type: 'basic',
    //       icon: 'pie_chart',
    //       link: URLPaths.ROUTE_INTRANET_PAGE
    //     }
    //   ]
    // },
  ];

  static navItemBlueprintMobile: any[]=[
    {
      id: 'personal',
      title: Constants.NAV_GROUP_PERSONAL,
      type: 'group',
      link: '',
      children: [
        {
          id: 'time',
          title: Constants.NAV_TIME_ENTRY_ACTUALS,
          type: 'basic',
          icon: 'access_time',
          link: URLPaths.ROUTE_TIME_ENTRY_ACTUALS,
        },
        {
          id: 'expense',
          title: Constants.NAV_EXPENSE_ENTRY,
          type: 'basic',
          icon: 'attach_money',
          link: URLPaths.ROUTE_EXPENSE_ENTRY_ACTUALS,
        },
        {
          id: 'flag',
          title: Constants.NAV_FLAG,
          type: 'basic',
          icon: 'flag',
          link: URLPaths.ROUTE_FLAG_ACTUALS,
        },
      ],
    },
    {
      id: 'usefulLinks',
      title: Constants.NAV_USEFUL_LINKS,
      type: 'collapsable',
      link: '',
      children: [
        {
          id: 'mip1',
          title: "MIPS 1.0",
          type: 'basic',
          icon: 'link',
          link: 'https://mips.thorogood.com/MIPSMain.aspx',
          externalLink: true,
          target: '_blank'
        },
        {
          id: 'wfhRequisition',
          title: Constants.NAV_WFH_REQUISITION_FORM,
          type: 'basic',
          icon: 'link',
          link: 'https://forms.office.com/Pages/ResponsePage.aspx?id=gVMX5Xa-HE246JyebXWXLvRZy6C5kVZEraZek1yoBQ1UMlkxQkU2QzJGRUxVVUQ0SDZBQUFCNURFSS4u',
          externalLink: true,
          target: '_blank'
        },
        {
          id: 'wfhReport',
          title: Constants.NAV_WFH_REPORT,
          type: 'basic',
          icon: 'link',
          link: 'https://app.powerbi.com/groups/28617dba-e38a-433f-870b-dddc9079f639/reports/5dc9e778-9695-4f8c-894e-7550ded55c3e?ctid=e5175381-be76-4d1c-b8e8-9c9e6d75972e&pbi_source=linkShare',
          externalLink: true,
          target: '_blank'
        },
        {
          id: 'PMDashboard',
          title: Constants.NAV_PM_DASHBOARD,
          type: 'basic',
          icon: 'link',
          link: 'https://app.powerbi.com/links/tUe27tn3XY?ctid=e5175381-be76-4d1c-b8e8-9c9e6d75972e&pbi_source=linkShare',
          externalLink: true,
          target: '_blank'
        },
        {
          id: 'LMDashboard',
          title: Constants.NAV_LM_DASHBOARD,
          type: 'basic',
          icon: 'link',
          link: 'https://app.powerbi.com/groups/me/reports/254a80f3-4fbc-4535-977f-a0284f327069/ReportSectiona2d477698bd1cbb9e634?ctid=e5175381-be76-4d1c-b8e8-9c9e6d75972e&experience=power-bi',
          externalLink: true,
          target: '_blank'
        },
        {
          id: 'intranetHome',
          title: Constants.NAV_INTRANET_HOME,
          type: 'basic',
          icon: 'link',
          link: 'https://thorogoodassociates.sharepoint.com/SitePages/Thorogood%20Intranet.aspx',
          externalLink: true,
          target: '_blank'
        },
        {
          id: 'indiaworkplaceReport',
          title: "India Workplace Report",
          type: 'basic',
          icon: 'link',
          link: 'https://app.powerbi.com/groups/28617dba-e38a-433f-870b-dddc9079f639/reports/ec91fb2d-2e89-4522-9738-a5b7098f02fc/ReportSection?experience=power-bi',
          externalLink: true,
          target: '_blank',
          hidden: () => {
            let isHidden = true;
            navItems.isIndiaUser.subscribe(isIndia => {
              isHidden =!isIndia;
            });
            console.log(isHidden)
            return isHidden;
          }
        },
      ]
    },
  ];


}
